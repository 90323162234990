<script>
import pluggable from '@maxsystems/ui/mixins/pluggable'
import DealerInfo from '@maxsystems/ui/patterns/DealerInfo'

import ActionButtonGroup from './components/ActionButtonGroup'
import CertificationCallout from './components/CertificationCallout'
import LegalDisclaimer from './components/LegalDisclaimer'
import MarketValue from './components/MarketValue'
import MarketValueTable from './components/MarketValue/MarketValueTable'
import PhotoGallery from './components/PhotoGallery'
import PremiumEquipment from './components/PremiumEquipment'
import ServiceUpgrades from './components/ServiceUpgrades'
import Vehicle404 from './components/Vehicle404'
import VehicleAttributes from './components/VehicleAttributes'
import VehicleDescription from './components/VehicleDescription'
import VehicleEquipment from './components/VehicleEquipment'
import VehicleHighlights from './components/VehicleHighlights'
import VehicleHistory from './components/VehicleHistory'
import VehicleSummary from './components/VehicleSummary'
import { dataSource, loadVehicle } from './data-source'

export default {
  name: 'VDP',
  components: {
    ActionButtonGroup,
    CertificationCallout,
    DealerInfo,
    LegalDisclaimer,
    MarketValue,
    MarketValueTable,
    PhotoGallery,
    PremiumEquipment,
    ServiceUpgrades,
    VehicleAttributes,
    VehicleDescription,
    VehicleEquipment,
    VehicleHighlights,
    VehicleHistory,
    VehicleSummary,
    Vehicle404
  },
  mixins: [
    pluggable('vdp')
  ],
  provide: { dataSource },
  props: {
    // Optional prop to receive dealer phone number from the path-store instance
    dealerPhone: {
      type: String,
      default: null
    },
    disableClientLoad: {
      type: Boolean,
      default: false
    },
    inventory: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    dealerId: () => dataSource.dealer.id,
    inventoryId: () => dataSource.inventory.id,
    hasLoadedMarketListing: () => Object.keys(dataSource.marketListings).length > 0,
    highlights: () => dataSource.inventory.highlights,
    loading: vm =>
      dataSource.actions.getVehicle
        .isPending(({ inventoryId }) => inventoryId === vm.inventory),
    showMarketListingsPrintVersion: vm =>
      dataSource.isMarketValuePanelOpen &&
      vm.hasLoadedMarketListing,
    vin: () => dataSource.inventory.vehicle?.vin,
    ymmt: () => dataSource.vehicle?.ymmt
  },
  watch: {
    inventory: {
      handler (newValue) {
        loadVehicle(newValue)
      },
      immediate: true
    }
  },
  async destroyed () {
    // clear vehicle data when navigating away from VDP
    // prevents old vehicle data from showing when loading VDP again
    loadVehicle(false)
  }
}
</script>

<template>
  <VContainer
    v-if="!loading"
    class="container--vdp"
    fluid
  >
    <VLayout
      v-if="vin"
      justify-space-between
      wrap
    >
      <VFlex
        v-if="$vuetify.breakpoint.mdAndUp"
        class="md6 vdp--left-container"
      >
        <PhotoGallery class="mb-6" />
        <VehicleAttributes class="my-6" />
        <VehicleHighlights
          :highlights="highlights"
        />
        <VehicleEquipment class="my-6" />
        <VehicleHistory class="my-6" />
        <ServiceUpgrades class="my-6" />
        <VehicleDescription class="my-6" />
      </VFlex>
      <VFlex
        v-if="$vuetify.breakpoint.mdAndUp"
        class="md6 vdp--right-container"
      >
        <VehicleSummary />
        <ActionButtonGroup
          :inventory="inventory"
        />
        <MarketValue />
        <PremiumEquipment class="my-6" />
        <CertificationCallout class="my-6" />
        <DealerInfo
          class="my-6"
          :dealer-phone="dealerPhone"
          :dealer-id="dealerId"
        />
      </VFlex>
      <MarketValueTable
        v-if="showMarketListingsPrintVersion"
        class="d-none d-print-block"
        :ymmt="ymmt"
        :inventory-id="inventoryId"
        expanded
      />
      <VFlex
        v-if="$vuetify.breakpoint.smAndDown"
        class="xs12 sm12"
      >
        <PhotoGallery class="mb-6" />
        <VehicleSummary />
        <ActionButtonGroup
          :inventory="inventory"
        />
        <MarketValue />
        <VehicleAttributes class="my-6" />
        <PremiumEquipment class="my-6" />
        <VehicleHighlights
          :highlights="highlights"
        />
        <VehicleEquipment class="my-6" />
        <VehicleHistory class="my-6" />
        <ServiceUpgrades class="my-6" />
        <CertificationCallout class="my-6" />
        <DealerInfo
          class="my-6"
          :dealer-phone="dealerPhone"
          :dealer-id="dealerId"
        />
        <VehicleDescription class="my-6" />
      </VFlex>
    </VLayout>
    <slot
      v-else
      name="404"
    >
      <Vehicle404 />
    </slot>
    <LegalDisclaimer />
  </VContainer>
</template>

<style lang="scss">
.container--vdp {
  .vdp--left-container {
    padding-right: 16px;
  }

  .vdp--right-container {
    padding-left: 16px;
  }

  @include breakpoint('xs-only') {
    & .gallery__thumbs {
      margin-top: -16px;
    }

    & .gallery__thumbs,
    & .vdp__market-value__average {
      margin-left: -16px;
      margin-right: -16px;
    }

    & .vdp__market-value__average {
      border-radius: 0;
    }
  }

  .v-btn {
    text-transform: none;
  }
}

@media print {
  .container--vdp {
    padding: 12px 0;
  }

  .dealer-action-button {
    display: none;
  }
}

@media print and (orientation: portrait) {
  .vdp__market-value__comparison {
    grid-template-columns: unset;
  }

  .vdp--left-container {
    padding-right: 8px !important;
  }

  .vdp--right-container {
    padding-left: 8px !important;
  }

  .vdp--left-container,
  .vdp--right-container {
    width: 50%;
  }
}
</style>

import fetch from '@maxsystems/ui/api'

import dealerQuery from '../queries/dealer.gql'
import Home from './Home'
import SRP from './SRP'
import VDP from './VDP'
import Buy from './VDP/Buy'
import { CustomerBoard } from '~/plugins/board'

export default [
  { path: '/', component: Home },
  { path: '/board', component: CustomerBoard, name: 'board' },
  { path: '/board/:opportunity', component: CustomerBoard },
  {
    path: '/dealer/:buid/:pathMatch(.*)?',
    component: SRP,
    async beforeEnter (to, from, next) {
      const { data: { dealer } } = await fetch(dealerQuery, {
        dealerId: to.params.buid
      })

      to.meta.logo = dealer?.logo?.url
      next()
    }
  },
  { path: '/search(.*)?', component: SRP, name: 'srp' },
  { path: '/vehicle/:inventory', component: VDP, name: 'vdp', props: true },
  { path: '/vehicle/:inventory/buy', component: Buy, name: 'vdp-buy', props: true },
  {
    path: '/vehicle/:inventory/embed',
    component: VDP,
    name: 'vdp-embed',
    props: true,
    meta: { layout: 'embed' }
  }
]

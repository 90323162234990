<script>
import { mapFlags } from '@maxsystems/ui/utils/features'

import CopyLink from './CopyLink'
import mixin from './mixin'
import QRCode from './QRCode'
import SendLink from './SendLink'

export default {
  components: {
    CopyLink,
    QRCode,
    SendLink
  },
  mixins: [mixin],
  computed: {
    ...mapFlags({ shareQr: true, shareCrm: false })
  }
}
</script>

<template>
  <VCard class="board__share--dialog">
    <VAppBar
      flat
      color="primary"
      dark
    >
      <h3>Share</h3>
      <VBtn
        text
        icon
        right
        absolute
        color="light"
        @click="$emit('close')"
      >
        <VIcon>$close</VIcon>
      </VBtn>
    </VAppBar>
    <section class="pt-4">
      <p>Copy the URL to Share</p>
      <CopyLink
        :hostname="hostname"
        class="board__share--dialog__link my-4"
      />
    </section>
    <section
      v-if="shareQr"
      class="py-3"
    >
      <p>Or, scan the QR code below</p>
      <QRCode
        :hostname="hostname"
      />
    </section>
    <section
      v-if="shareCrm"
      class="py-3"
    >
      <p class="mb-3">
        Or, share link via email or text
      </p>
      <SendLink />
    </section>
  </VCard>
</template>

<style lang="scss">
.board__share--dialog {
  & h3 {
    margin: 0 auto !important;
  }

  &__link {
    display: flex;

    & .v-text-field .v-input__slot {
      min-height: 34px;
    }

    & .v-btn {
      margin: 0;
      width: 100%;

      @include breakpoint('sm-and-up') {
        margin: 0 0 0 16px;
        width: auto;
      }
    }
  }

  & .v-toolbar__content .v-btn {
    top: 8px;
  }

  section {
    padding-left: spacer(3);
    padding-right: spacer(3);

    @include breakpoint('sm-and-up') {
      padding-left: spacer(5);
      padding-right: spacer(5);
    }
  }

  img {
    display: flex;
    margin: 0 auto;
  }
}
</style>

import Vue from 'vue'

import { withQuery } from '@maxsystems/ui/api'

import marketListingsQuery from './queries/market-listings.gql'
import getVehicle from './queries/vehicle.gql'
/**
 * @public
 * Information here is provided to all child components and plugins on the VDP.
 */
export const dataSource = Vue.observable({
  inventory: {},
  isMarketValuePanelOpen: false,
  marketListings: {},
  actions: {
    getVehicle: withQuery(getVehicle),
    getMarketListings: withQuery(marketListingsQuery)
  },

  get dealer () {
    return this.inventory?.dealer || {}
  },
  get vehicle () {
    return this.inventory?.vehicle || {}
  },

  get photos () {
    const placeholder = 'https://placehold.co/600x400/D8D8D8/A0A0A0?text=PHOTO+COMING+SOON&font=Montserrat'
    return this.inventory.photos?.edges?.map(edge => edge.node) || [{ url: placeholder, thumb: placeholder }]
  },

  async fetchMarketListing (inventoryId) {
    const { data: { inventory: { vehicle: { ymmt: { marketListings } } } } } = await this.actions.getMarketListings.fetch({ inventoryId })

    this.marketListings = marketListings
  }
})

/**
 * @private
 * Retrieves vehicle data from GraphQL.
 * @param {String|Number} inventoryId
 */
export async function loadVehicle (inventoryId) {
  dataSource.inventory = {}
  dataSource.marketListings = {}
  dataSource.isMarketValuePanelOpen = false
  if (!inventoryId) return

  const { data: { inventory } } = await dataSource.actions.getVehicle.fetch({ inventoryId })
  dataSource.inventory = inventory ?? {}
}

<script>
import { mapState } from 'vuex'

import { number } from '@maxsystems/ui/vue/filters'

export default {
  name: 'SRPPagination',
  filters: {
    number
  },
  props: {
    pageSizeAdjust: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    pageSize: 20,
    pageSizeOptions: [10, 20, 50, 100]
  }),
  computed: {
    ...mapState({
      pagination: state => state.search.pagination,
      pageNumber: state => state.search.pagination.page,
      resultCount: state => state.search.resultCount
    }),
    start: vm => (vm.pageNumber * vm.pageSize) + 1,
    end: vm => Math.min(vm.start + vm.pageSize - 1, vm.resultCount),
    count () {
      return Math.min(this.resultCount, 1000)
    },
    isDesktop: vm => vm.$vuetify.breakpoint.smAndUp
  },
  methods: {
    setPage (p) {
      if (window) window.scrollTo(0, 0)
      const page = Number(p)
      this.$store.dispatch('search/search', {
        hitsPerPage: this.pageSize,
        page
      })
    }
  }
}
</script>

<template>
  <section class="srp__pagination">
    <VLayout
      v-if="pageSizeAdjust && resultCount > 1"
      wrap
      align-center
    >
      <VFlex xs6>
        <VSelect
          v-model="pageSize"
          :items="pageSizeOptions"
          label="Results Per Page"
          @change="setPage(0)"
        />
      </VFlex>
    </VLayout>
    <VLayout
      v-if="resultCount > 1"
      wrap
      align-center
    >
      <VFlex xs4>
        <VBtn
          class="srp__pagination__btn--first ml-0"
          title="First Page"
          color="primary"
          :disabled="pageNumber <= 0"
          depressed
          icon
          :ripple="false"
          @click="setPage(0)"
        >
          <VIcon dark>
            $first
          </VIcon>
        </VBtn>
        <VBtn
          class="srp__pagination__btn--previous pl-2"
          title="Previous Page"
          color="primary"
          :disabled="pageNumber <= 0"
          depressed
          :ripple="false"
          @click="setPage(pageNumber - 1)"
        >
          <VIcon dark>
            $prev
          </VIcon>
          {{ isDesktop ? 'Prev' : '' }}
        </VBtn>
      </VFlex>
      <VFlex
        xs4
        class="srp__pagination__results text-center text-caption"
      >
        {{ start | number }} - {{ end | number }} of {{ count | number }} {{ isDesktop ? 'Matches' : '' }}
      </VFlex>
      <VFlex
        xs4
        class="text-right"
      >
        <VBtn
          class="srp__pagination__btn--next pr-2"
          title="Next Page"
          color="primary"
          :disabled="pageNumber >= (pagination.pageTotal - 1)"
          depressed
          :ripple="false"
          @click="setPage(pageNumber + 1)"
        >
          {{ isDesktop ? 'Next' : '' }}
          <VIcon dark>
            $next
          </VIcon>
        </VBtn>
        <VBtn
          class="srp__pagination__btn--last mr-0"
          title="Last Page"
          color="primary"
          :disabled="pageNumber >= (pagination.pageTotal - 1)"
          depressed
          icon
          :ripple="false"
          @click="setPage(pagination.pageTotal - 1)"
        >
          <VIcon dark>
            $last
          </VIcon>
        </VBtn>
      </VFlex>
    </VLayout>
  </section>
</template>

<style lang="scss">
.srp__pagination {
  & button.v-btn,
  & button.v-btn::before {
    text-transform: none;
  }

  @include breakpoint('xs-only') {
    button.v-btn {
      min-width: 0;
      padding: 0 !important;
      width: 36px !important;
    }
  }
}
</style>

<script>
import { Icon } from '@maxsystems/ui/elements'

import NavigationLinks from './NavigationLinks'
import ShareButton from './ShareButton'

export default {
  name: 'LeftNavigation',
  components: {
    Icon,
    NavigationLinks,
    ShareButton
  },
  inject: ['plugins'],
  props: {
    active: {
      type: String,
      default: '/'
    }
  },
  async mounted () {
    await this.$nextTick()
    if (!this.$refs.activeLink) return
    this.scrollIntoView(this.$refs.activeLink)
  },
  methods: {
    goTo (path, el) {
      this.scrollIntoView(el)
      location.hash = path || '/'
    },
    scrollIntoView (el) {
      if (!el || !el.scrollIntoView) return
      el.scrollIntoView({
        behavior: 'smooth', block: 'nearest', inline: 'center'
      })
    }
  }
}
</script>

<template>
  <div class="white">
    <NavigationLinks
      v-slot="{ icon, path, subtitle, title }"
      class="board__navigation__links"
    >
      <div
        :ref="path === active ? 'activeLink' : null"
        :class="['board__navigation__link', path === active && 'board__navigation__link--active']"
        @click="goTo(path, $event.target)"
      >
        <component
          :is="typeof icon === 'object' ? 'Icon' : 'img'"
          v-if="icon"
          :src="icon"
          width="32"
          class="hidden-xs-only primary--text"
        />
        <div class="board__navigation__link-text">
          <div class="board__navigation__link-title text-body-1">
            {{ title }}
          </div>
          <span class="text-caption grey--text text--darken-3 hidden-xs-only">
            {{ subtitle }}
          </span>
        </div>
        <VIcon class="hidden-xs-only">
          $next
        </VIcon>
      </div>
    </NavigationLinks>
    <div class="text-center mt-2 mb-5 hidden-xs-only">
      <ShareButton />
    </div>
  </div>
</template>

<style lang="scss">
.board__navigation__link {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  scroll-snap-align: center;

  @include breakpoint('xs-only') {
    color: var(--v-primary-base);

    &:first-of-type {
      padding-left: 20px;
    }

    &:nth-last-of-type(2) {
      padding-right: 20px;
    }
  }

  @include breakpoint('sm-and-up') {
    align-items: center;
    border-left: 5px solid transparent;
    height: 100px;
    padding: 0;
    padding-left: 27px;
  }

  svg {
    min-width: 32px;
  }

  &-text {
    align-self: center;
    color: var(--v-dark-lighten2);

    @include breakpoint('xs-only') {
      white-space: nowrap;
    }

    @include breakpoint('sm-and-up') {
      margin: 0 8px 0 20px;
    }

    p {
      font-size: 13.3px;
    }
  }

  &--active {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);

    @include breakpoint('xs-only') {
      color: var(--v-primary-base);
      position: relative;

      &::before {
        border-bottom: solid 2px var(--v-primary-base);
        content: '';
        display: block;
        height: 100%;
        left: 10px;
        position: absolute;
        top: 0;
        width: calc(100% - 20px);
      }

      &:first-of-type {
        &::before {
          left: 20px;
        }
      }

      &:first-of-type,
      &:nth-last-of-type(2) {
        &::before {
          width: calc(100% - 30px);
        }
      }
    }

    .v-icon {
      opacity: 0;
    }

    .board__navigation__link-title {
      color: var(--v-primary-base);

      @include breakpoint('xs-only') {
        font-weight: 700;
      }
    }
  }

  .board__navigation__link-title {
    @include breakpoint('sm-and-up') {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .v-icon {
    margin: 0 12px 0 auto;
    width: 24px;
  }

  &s {
    display: flex;
    min-height: 55px;
    -ms-overflow-style: none;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    width: 100%;
    z-index: 2;

    &::-webkit-scrollbar {
      height: 0;
    }

    @include breakpoint('sm-and-up') {
      display: block;
      flex-direction: row;
      min-height: initial;
      width: 320px;
    }
  }
}
</style>

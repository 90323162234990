<script>

import GhostCar from '@maxsystems/ui/patterns/icons/GhostCar'

export default {
  name: 'VDPVehicle404',
  components: {
    GhostCar
  }
}
</script>

<template>
  <VContainer
    fill-height
  >
    <VFlex class="text-center">
      <GhostCar width="200" />

      <div class="text-h5 mt-6 mb-4">
        This vehicle is no longer available
      </div>
    </VFlex>
  </VContainer>
</template>

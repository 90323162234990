import { mapState } from 'vuex'

/**
 * This mixin should be used on every Layout and ensures that as soon as a real
 * visitor hits the site, their Opportunity record is retrieved or created.
 */
export default {
  computed: {
    ...mapState('instance', { instanceId: state => state.id }),
    ...mapState('opportunity', {
      opportunityId: state => state.data.id,
      platform: state => state.platform
    })
  },
  watch: {
    opportunityId (opportunityId) {
      window.dataLayer.push({ opportunityId })
      this.$debug('opportunity ' + opportunityId)
    }
  },
  /**
   * This is only called on the client side, which means it is now safe to
   * bootstrap the consumer Opportunity. This means we also cut down on the
   * number of junk opportunities from spiders or other SSR-only requests.
   */
  beforeMount () {
    if (!this.instanceId) return

    let opportunityId
    if (this.$route.params.opportunity) {
      // If an Opportunity ID is passed as a parameter to the route, decode it
      // and load the Opportunity before showing the Board.
      opportunityId = atob(this.$route.params.opportunity)
    }

    this.$store.dispatch('opportunity/get', opportunityId).then(() => {
      if (!opportunityId) return
      this.$router.push({ name: 'board' })
    })
  },
  mounted () {
    this.$debug(`${this.platform} resolved to ${this.instanceId}`)
  }
}

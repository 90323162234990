import Vuex from 'vuex'

import { store as opportunity } from '@maxsystems/opportunity'
import { instance } from '@maxsystems/ui/vue/store'

import search from '../routes/SRP/store'

export const store = new Vuex.Store({
  modules: {
    instance,
    opportunity,
    search
  }
})

export default Vuex

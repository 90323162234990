<script>
import FilterCount from './FilterCount'
import Sort from './Sort'

export default {
  components: {
    Sort,
    FilterCount
  },
  methods: {
    triggerVisibility () {
      this.$emit('input', true)
    }
  }
}
</script>

<template>
  <section
    class="srp__filters--mobile hidden-md-and-up"
  >
    <VLayout>
      <VFlex
        xs6
      >
        <VBtn
          block
          text
          class="my-0 btn__rectangle"
          @click.stop="triggerVisibility"
        >
          <VIcon class="mr-2">
            $tune
          </VIcon>
          Filters
          <FilterCount
            facet="all"
            class="ml-2"
          />
        </VBtn>
      </VFlex>
      <VFlex
        xs6
      >
        <Sort />
      </VFlex>
    </VLayout>
  </section>
</template>

<style lang="scss">
.srp__filters--mobile {
  .v-btn.btn__rectangle {
    background-color: var(--v-light-lighten1);
    border: 1px solid #DBDBDB;
    border-radius: 0;
    border-top: 0;
    color: #4F595C;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    text-transform: none !important;
  }
}
</style>

<script>
import hierarchical from '../mixins/hierarchical'
import SelectListRow from './SelectListRow'

export default {
  components: {
    SelectListRow
  },
  mixins: [hierarchical]
}
</script>

<template>
  <VLayout
    wrap
  >
    <VFlex
      v-for="category in sorted"
      :key="values.available[category].name"
      xs12
      class="hierarchical-select__parent"
    >
      <SelectListRow
        :key="values.available[category].name"
        :label="values.available[category].name"
        :count="values.available[category].count"
        :value="values.available[category].selected"
        @click="toggleAll(values.available[category])"
      />
      <SelectListRow
        v-for="item in values.available[category].items"
        :key="item.name"
        v-model="value"
        subcategory
        :count="item.count"
        :icon="item.icon"
        :label="item.label"
        :value="item.name"
      />
    </VFlex>
  </VLayout>
</template>

<style lang="scss">
.hierarchical-select__parent {
  &-label {
    padding: 20px 0;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'

import { currency } from '@maxsystems/ui/vue/filters'

export default {
  filters: {
    currency
  },
  computed: {
    ...mapGetters('opportunity/payments', ['maxPrice'])
  }
}
</script>

<template>
  <div class="payment-calculator__tile-header">
    <h4 class="text-h6 font-weight-bold">
      Vehicle Price
    </h4>
    <h5 class="text-h6 font-weight-bold mr-6">
      {{ maxPrice | currency }}
    </h5>
  </div>
</template>

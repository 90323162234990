<script>
import { Icon, Surface } from '@maxsystems/ui/elements'

import * as logos from './logos'

export default {
  name: 'VehicleHistory',
  components: {
    Surface,
    Icon
  },
  inject: ['dataSource'],
  data: () => ({
    logos
  }),
  computed: {
    history: vm => {
      const { history } = vm.dataSource.inventory
      if (!history?.length) return
      if (history.length === 1) return history[0]
      return history.find(history => history.type === 'CARFAX')
    }
  }
}
</script>

<template>
  <div v-if="history">
    <Surface
      class="vehicle-history mb-6"
    >
      <div class="primary white--text vehicle-history__header px-6 py-4">
        <div
          aria-role="img"
          :aria-label="history.type"
        >
          <Icon
            :src="logos[history.type] || null"
            height="25"
          />
        </div>
      </div>
      <div class="px-6 py-2">
        <VLayout
          v-for="(text, i) in history.report"
          :key="i"
          align-center
          class="text-caption grey--text text--darken-3 py-2"
        >
          <VIcon
            color="success"
            width="23"
            class="d-block mr-2"
          >
            $success
          </VIcon>
          {{ text.title }}
        </VLayout>
      </div>
      <VLayout
        v-if="history.type === 'CARFAX'"
        class="px-6 pb-2"
      >
        <VBtn
          class="vehicle-history__button font-weight-bold d-print-none"
          color="primary"
          block
          text
          :ripple="false"
          large
          target="_blank"
          :href="history.url"
        >
          View FREE CARFAX Report
        </VBtn>
      </VLayout>
    </Surface>
  </div>
</template>

<style lang="scss">
.vehicle-history {
  &__header {
    line-height: 0;
  }

  &__button {
    font-size: 18px;
    text-transform: none;
  }
}
</style>

import mixin from './mixin'

export default {
  name: 'QRCode',
  mixins: [mixin],
  props: {
    height: {
      type: [String, Number],
      default: 300
    },
    width: {
      type: [String, Number],
      default: 300
    }
  },
  computed: {
    imageUrl () {
      const url = new URL(`/api/share/${this.encoded}.svg`, 'https://cdn.maxapps.io')
      url.search = `host=${this.host}&to=board`
      return url.toString()
    }
  },
  render (h) {
    return h('img', {
      attrs: {
        src: this.imageUrl,
        height: this.height,
        width: this.width
      }
    })
  }
}

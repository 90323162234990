<script>
import { Icon } from '@maxsystems/ui/elements'
import { getEquipmentIcon } from '@maxsystems/ui/icons/equipment'

export default {
  name: 'PopularEquipment',
  components: {
    Icon
  },
  props: {
    popularEquipment: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getEquipmentIcon
  }
}
</script>

<template>
  <VLayout
    v-bind="$attrs"
    class="surface--vehicle__popular-equipment text-body-2 grey--text text--darken-2"
    wrap
  >
    <VFlex
      v-for="(option, index) in popularEquipment"
      :key="index"
      class="xs6 sm6 popular-equipment__item"
    >
      <Icon
        width="24"
        :src="getEquipmentIcon(option)"
      />
      <label>{{ option }}</label>
    </VFlex>
  </VLayout>
</template>

<style lang="scss">
.surface--vehicle__popular-equipment {

  @include breakpoint('xs-only') {
    :nth-child(even) svg{
      padding-left:5px
    }
    :nth-child(odd) label{
      padding-right:5px
    }
  }
  &__expand {
    margin-left: 34px
  }
  & .popular-equipment__item {
    display: flex;
    align-items: center;
    margin: 2px 0;
    & svg {
      margin-right: 10px;
    }
    & label {
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }
}

</style>

import { mapState } from 'vuex'

import icon from './icon.svg'

export default {
  data: () => ({
    icon,
    title: 'Favorites'
  }),
  computed: {
    subtitle: vm => [
      vm.saved,
      vm.saved === 1 ? 'vehicle' : 'vehicles',
      'saved'
    ].join(' '),
    ...mapState('opportunity', {
      saved: state => (state.data.savedInventory && state.data.savedInventory.edges.length) || 0
    })
  }
}

<script>
import { mapState } from 'vuex'

import { Surface } from '@maxsystems/ui/elements'
import { date } from '@maxsystems/ui/vue/filters'

import EmptyState from '../../components/EmptyState'
import icon from './icon.svg'
import getAppointments from './queries/getAppointments.gql'

export default {
  name: 'BoardAppointments',
  components: {
    EmptyState,
    Surface
  },
  filters: {
    date,
    ymmt (input) {
      if (!input.ymmt) return
      const { year, make, model, trim } = input.ymmt
      return [year, make, model, trim].join(' ')
    }
  },
  data: () => ({ icon }),
  computed: mapState('opportunity', {
    appointments: state => (state.data.appointments || []).filter(Boolean)
  }),
  async mounted () {
    const opportunity = await this.$store.dispatch('opportunity/query', getAppointments)
    if (!opportunity) return
    this.$store.dispatch('opportunity/update', opportunity)
  }
}
</script>

<template>
  <section class="customer-board__content--appointments">
    <slot name="title" />
    <EmptyState
      v-if="!appointments.length"
      :icon="icon"
      title="No Appointments"
      description="Please select a vehicle to test drive from your favorites list or at the vehicle's detail page"
    />
    <span
      v-for="appointment in appointments"
      :key="appointment.id"
    >
      <h4 class="mt-4 mb-2"><VIcon class="mx-2">$event</VIcon>{{ appointment.time.start | date('MMMM D, YYYY [at] h:mm A') }}</h4>
      <Surface class="surface--appointment">
        <div
          v-if="appointment.dealer.logo"
          class="px-6 surface--appointment__logo"
        >
          <img
            :src="appointment.dealer.logo.url"
            width="140"
            alt="Dealer logo"
          >
        </div>
        <div class="pa-6">
          <h2 class="mb-1">{{ appointment.dealer.name }}</h2>
          <p class="text-body-1">
            {{ appointment.dealer.address.street }}<br>
            {{ appointment.dealer.address.locality }}, {{ appointment.dealer.address.region }} {{ appointment.dealer.address.postalCode }}
          </p>
          <p
            v-if="appointment.inventory.edges[0]"
            class="mt-4 text-body-1"
          >
            {{ appointment.inventory.edges[0].node.vehicle | ymmt }}
          </p>
        </div>
        <div class="pa-6 surface--appointment__action">
          <VBtn
            v-if="false"
            class="ma-0 text-capitalize"
            color="primary"
            depressed
            large
          >
            View Appointment
          </VBtn>
        </div>
      </Surface>
    </span>
  </section>
</template>

<style lang="scss">
.customer-board__content--appointments {
  .v-icon {
    color: #484848;
  }
}

.surface--appointment {
  display: flex;
  flex-direction: column;

  @include breakpoint('sm-and-up') {
    flex-direction: row;
  }

  &__action {
    align-items: center;
    display: flex;

    @include breakpoint('sm-and-up') {
      margin-left: auto;
    }

    & .v-btn {
      width: 100%;

      @include breakpoint('sm-and-up') {
        width: auto;
      }
    }
  }

  &__logo {
    align-items: center;
    border-bottom: 1px solid var(--surface-shadow);
    display: flex;
    justify-content: center;

    @include breakpoint('sm-and-up') {
      border-bottom: none;
      border-right: 1px solid var(--surface-shadow);
    }
  }
}
</style>

<script>
import { Surface } from '@maxsystems/ui/elements'
import { number, currency } from '@maxsystems/ui/vue/filters'

import MarketValueChart from './MarketValueChart'
import MarketValueTable from './MarketValueTable'

export default {
  name: 'MarketValue',
  filters: {
    number,
    currency
  },
  components: {
    MarketValueChart,
    MarketValueTable,
    Surface
  },
  inject: ['dataSource'],
  computed: {
    comparisons () {
      return this.dataSource.inventory.marketValue?.edges
        ?.filter(({ node }) => node.provider !== 'JD_POWER')
        .map(({ node }) => node)
        .sort((a, b) => b.value.amount - a.value.amount) || []
    },
    price: vm => vm.dataSource.inventory.price.showroomExportPrice?.amount,
    marketListings: vm => vm.dataSource.vehicle?.ymmt?.marketListings || {},
    showMarketListings () {
      return this.marketListings.totalCount &&
        this.dataSource.inventory.condition === 'USED'
    },
    showMarketValue: vm => {
      const marketAverage = vm.comparisons.filter(node => node.provider === 'MARKET')
      const hasValidPrice = marketAverage.length ? vm.price < marketAverage[0].value.amount : true
      return vm.comparisons.length && vm.price && hasValidPrice
    }
  }
}
</script>

<template>
  <section
    v-if="showMarketValue || showMarketListings"
    class="vdp__market-value my-6"
  >
    <Surface>
      <MarketValueChart
        v-if="showMarketValue"
        :comparisons="comparisons"
        :price="price"
      />
      <MarketValueTable
        v-if="showMarketListings"
        class="d-print-none"
        :ymmt="dataSource.vehicle.ymmt"
        :inventory-id="dataSource.inventory.id"
      />
    </Surface>
    <div
      v-if="showMarketValue"
      class="vdp__market-value__comparison my-6"
    >
      <Surface
        v-for="(comparison, index) in comparisons"
        :key="comparison.id"
        class="surface--market-value"
      >
        <div class="surface--market-value__logo pa-2">
          <img
            :alt="comparison.name"
            :src="'https://cdn.maxapps.io/logos/proofpoints/' + comparison.id + '.png'"
            width="50"
          >
        </div>
        <div class="surface--market-value__details pa-4 text-body-1">
          <h4 class="font-weight-bold mb-2">
            You Save <sup class="text-body-2">$</sup><span class="text-h6 font-weight-bold">{{ Math.round(comparison.value.amount - price) | number }}</span>
          </h4>
          <span class="grey--text text--darken-2">
            vs {{ comparison.name }}<br>
            <span
              v-if="index > 1"
              class="font-weight-bold"
            >{{ comparison.value.amount | currency }}</span>
          </span>
        </div>
      </Surface>
    </div>
  </section>
</template>

<style lang="scss">
.vdp__market-value {
  &__comparison {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
    grid-template-rows: auto;

    @include breakpoint('xs-only') {
      display: block;
    }
  }

  &-chart + &-table {
    margin-top: -10px;
  }
}

.surface--market-value {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 9rem;

  @include breakpoint('xs-only') {
    margin: 1rem 0;
  }

  &__logo {
    align-items: center;
    align-self: stretch;
    background: var(--v-grey-lighten3);
    display: flex;
  }

  &__details {
    & sup {
      top: -0.25em;
    }
  }
}
</style>

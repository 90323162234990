<script>
import { InputNumeric, Slider } from '@maxsystems/ui/elements'

import numeric from '../mixins/numeric'
import { rounded } from '../utils/filters'

export default {
  components: {
    InputNumeric,
    Slider
  },
  filters: {
    rounded
  },
  mixins: [numeric],
  props: {
    filter: {
      type: String,
      default: 'number',
      enum: ['currency', 'number']
    },
    min: {
      type: Number,
      default: 0
    },
    sliderSteps: {
      type: Number,
      default: 1000
    }
  },
  data: () => ({
    sliderLocked: false
  }),
  computed: {
    prefix () {
      return this.filter === 'currency' ? '$' : ''
    },
    input () {
      const [min, max] = this.slider
      return { min, max }
    },
    range: vm => ({
      min: Math.floor(vm.values.min),
      max: Math.ceil(vm.values.max / vm.sliderSteps) * vm.sliderSteps
    }),
    slider: {
      get () {
        const min = this.values.selected.min || this.range.min
        const max = this.values.selected.max || this.range.max
        return [min, max]
      },
      set ([min, max]) {
        if (this.sliderLocked) return

        this.minValue = min
        this.maxValue = max
      }
    }
  },
  methods: {
    async focus ($event) {
      this.sliderLocked = true
      await this.$nextTick()
      $event.target.select()
    },
    update (newValue, target) {
      this[target] = newValue
    }
  }
}
</script>

<template>
  <VLayout
    wrap
  >
    <VFlex xs12>
      <VLayout
        wrap
        class="input-container"
      >
        <VFlex xs5>
          <InputNumeric
            :value="input.min"
            :prefix="prefix"
            :min="range.min"
            :max="range.max"
            label="From"
            outlined
            single-line
            @focus="focus"
            @blur="sliderLocked = false"
            @update="update($event, 'minValue')"
          />
        </VFlex>
        <VFlex class="xs2 text-center">
          <VIcon class="py-7">
            $minus
          </VIcon>
        </VFlex>
        <VFlex xs5>
          <InputNumeric
            :value="input.max"
            :prefix="prefix"
            :min="range.min"
            :max="range.max"
            label="To"
            outlined
            single-line
            @focus="focus"
            @blur="sliderLocked = false"
            @update="update($event, 'maxValue')"
          />
        </VFlex>
        <VFlex
          xs12
        >
          <Slider
            v-model="slider"
            v-bind="$attrs"
            :min="range.min"
            :max="range.max"
            :step="sliderSteps"
          />
        </VFlex>
      </VLayout>
    </VFlex>
  </VLayout>
</template>

<style scoped>
  .input-container >>> .v-text-field__prefix {
    margin-top: 4px;
  }
</style>

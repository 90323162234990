/**
 * Returns the value of a specific cookie.
 * @param {string} key - The name of the cookie.
 * @returns {string} The value of the cookie.
 */
function getCookie (key) {
  return new Map(
    document.cookie.split(';')
      .map(cookie => cookie.split('=').map(str => str.trim()))
  ).get(key)
}

/**
 * Sets a new cookie or updates an existing one.
 * @param {string} key - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {string} [expires] - The expiration date of the cookie in GMTString format.
 */
function setCookie (key, value, expires) {
  document.cookie = expires
    ? `${key}=${value}; expires=${expires}`
    : `${key}=${value}`
}

export default {
  /**
   * @property {Function} get - Returns the value of a specific cookie.
   * @property {Function} set - Sets a new cookie or updates an existing one.
   */
  get: getCookie,
  set: setCookie
}

import component from './Content'
import nav from './Navigation'

export {
  component,
  nav
}

export default {
  component,
  nav,
  path: '/',
  title: 'My Favorites'
}

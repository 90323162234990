export default {
  props: {
    facet: {
      type: String,
      required: true
    },
    sort: {
      type: String,
      default: null
    }
  },
  computed: {
    value: {
      get () {
        return this.values.selected
      },
      set (value) {
        this.setFacet(value.filter(Boolean))
      }
    },
    /**
     * All possible values to render in the UI, the first level of items will
     * contain only the parent or top level items. Children are available on the
     * items property along with hints about the visual treatment of the parent.
     * @return {Object[]}
     */
    values: vm => vm.$store.getters['search/hierarchicalFacet'](vm.facet),
    sorted () {
      let values = Object.keys(this.values.available)
      if (this.sort === 'alpha') {
        values = values.sort((a, b) => a.localeCompare(b))
      }
      return values
    }
  },
  methods: {
    /**
     * Update the facet value and immediately trigger a search. This also locks
     * the facets coming back so that the rendered options don't drop off as
     * the user is changing them.
     * @param {Array} values
     */
    setFacet (values) {
      const categoryNames = Object.keys(this.values.available)

      const [lvl0, lvl1] = values.reduce((result, value) => {
        const level = Number(!categoryNames.includes(value))
        result[level].push(value)
        return result
      }, [[], []])

      this.$store.commit('search/replaceFilter', [`${this.facet}.lvl1`, lvl1, { orWith: `${this.facet}.lvl0` }])
      this.$store.commit('search/replaceFilter', [`${this.facet}.lvl0`, lvl0])
      this.$store.dispatch('search/search')
    },
    /**
     * Toggle all children in the specified parent category on or off.
     * @param {Object} parent
     */
    toggleAll (parent) {
      this.value = parent.selected
        ? this.value.filter(i => !i.includes(parent.name))
        : this.value.concat(
          // Toggle any matching children if present, otherwise just toggle the selected value.
          parent.items.length
            ? parent.items.map(i => i.name).filter(i => !this.value.includes(i))
            : parent.name
        )
    }
  }
}

import { ColorWell } from '@maxsystems/ui/elements'

import { SelectListSorted } from '../patterns'

export default {
  config: {
    title: 'Exterior Color',
    weight: 50,
    facet: 'exteriorColor.lvl0',
    require: {
      // we don't want to show this filter until the future version of this
      // facet with only lvl0 and lvl1 lands. Meanwhile, we continue to support
      // old version with lvl0 to lvl2 through ExteriorColorWithLvl2.vue
      custom: vm => !vm.$store.state.search.staticFacets['exteriorColor.lvl2']
    }
  },
  render (h) {
    return h(SelectListSorted, {
      props: { facet: this.$options.config.facet },
      scopedSlots: { icon: props => h(ColorWell, { props: { color: props.label } }) }
    })
  }
}

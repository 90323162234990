import component from './Content'
import nav from './Navigation'

export {
  component,
  nav
}

export default {
  component,
  nav,
  path: '/appointments',
  title: 'My Appointments',
  weight: 50
}

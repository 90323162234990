import FavoriteAction from './FavoriteAction'
import TestDriveAction from './TestDriveAction'

export default {
  name: 'ActionButtonGroup',
  buttons: [
    FavoriteAction,
    TestDriveAction
  ]
}

import { mapFlags } from '@maxsystems/ui/utils/features'

import icon from './icon.svg'

export default {
  computed: {
    ...mapFlags({
      visible: { flag: 'appointments' }
    })
  },
  data: () => ({
    icon,
    title: 'Appointments',
    subtitle: 'Manage your appointments'
  })
}

<script>
import { parse } from 'fecha'
import { VTextFieldMasked } from 'v-text-field-masked'

import fetch from '@maxsystems/ui/api'
import VehicleImg from '@maxsystems/ui/patterns/VehicleImg.vue'
import { date, number } from '@maxsystems/ui/vue/filters'
import { email, phone, required } from '@maxsystems/ui/vue/validation'

import query from '../queries/vehicle.gql'

export default {
  name: 'ReservationDialogForm',
  components: {
    VehicleImg,
    VTextFieldMasked
  },
  filters: {
    date,
    number
  },
  props: {
    inventory: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    datePicker: false,
    dealer: {},
    form: {
      date: null,
      time: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null
    },
    formError: false,
    formValid: true,
    loading: false,
    rules: {
      required: [required],
      email: [email],
      phone: [phone]
    },
    vehicle: {}
  }),
  computed: {
    timeOptions () {
      const options = []
      for (let hour = 8; hour < 19; ++hour) {
        const meridiem = (hour < 12) ? 'AM' : 'PM'
        // hour option
        options.push(`${((hour + 11) % 12 + 1)}:00 ${meridiem}`)
        if (hour === 18) continue // prevent 6:30 PM option
        // half hour option
        options.push(`${((hour + 11) % 12 + 1)}:30 ${meridiem}`)
      }
      return options
    }
  },
  async created () {
    this.setDefaultAppointment()

    try {
      const { data: response, errors } = await fetch(query, { inventoryId: this.inventory })
      if (response?.inventory) {
        this.vehicle = response.inventory
        this.dealer = response.inventory.dealer
      } else {
        console.log(errors)
      }
    } catch (e) {
      console.log(e)
    }

    const { firstName, lastName, phone } = this.$store.state.opportunity.data.consumer?.profile || {}
    this.form.firstName = firstName
    this.form.lastName = lastName
    this.form.phone = phone
    this.form.email = this.$store.state.opportunity.data.consumer?.email
  },
  methods: {
    /**
     * Set the default appointment time to at least 24 hours from now.
     * @param {Date} now - The date to offset for the default appointment.
     */
    setDefaultAppointment (now = new Date()) {
      // set date to next day
      now.setDate(now.getDate() + 1)
      // make sure this is within the 8-6PM range
      now.setHours(Math.min(Math.max(now.getHours(), 8), 18))
      // set default time rounded to nearest half hour
      now.setMinutes(now.getHours() === 18 ? 0 : Math.round(now.getMinutes() / 30) * 30)

      this.form.date = date(now, 'YYYY-MM-DD')
      this.form.time = date(now, 'h:mm A')
    },
    async submit () {
      if (!this.$refs.form.validate()) return

      this.loading = true
      const {
        dealer: { id: dealer },
        form: {
          firstName,
          lastName,
          email,
          phone
        }
      } = this

      // eslint-disable-next-line keyword-spacing
      const start = parse(`${this.form.date} ${this.form.time}`, 'YYYY-MM-DD h:mm A')
      const end = new Date(start)
      end.setMinutes(end.getMinutes() + 30)

      try {
        await this.$store.dispatch('opportunity/board/addFavorite', this.inventory)
        const { data: result } = await this.$store.dispatch('opportunity/mutate', {
          mutation: `mutation bookAppointment($input: AppointmentActionInput!, $context: OpportunityContextInput!) {
            bookAppointment(input: $input, context: $context) {
              id
            }
          }`,
          input: {
            inventory: [this.inventory],
            start: start.toISOString(),
            end: end.toISOString(),
            dealer,
            firstName,
            lastName,
            email,
            phone
          }
        })
        if (!result) {
          this.formError = true
          return
        }
        // Emit and event to the board Event Bus whenever an appointment is scheduled
        this.loading = false
        this.$emit('content-change', 'success')
        const results = {
          date: start,
          firstName,
          lastName,
          email,
          phone
        }
        this.$board.$emit('appointment-scheduled', results)
        this.$emit('set-form', results)
      } catch (err) {
        console.error(err)
        this.formError = true
        this.loading = false
      }
    }
  }
}
</script>

<template>
  <VCard class="rsvp-dialog__form">
    <VCardText class="px-6 pt-12 pb-0">
      <h1 class="black--text font-size-xl">
        Make an Appointment
      </h1>
      <VLayout wrap>
        <VFlex
          xs12
          md5
        >
          <VLayout
            wrap
            class="pa-0"
          >
            <VFlex
              v-if="dealer"
              xs12
            >
              <VLayout
                wrap
                class="pa-0"
              >
                <VFlex
                  v-if="dealer.logo && dealer.logo.url"
                  xs12
                  md4
                  class="rsvp-dialog__logo"
                >
                  <img
                    :alt="dealer.name"
                    :src="dealer.logo.url"
                    class="rsvp-dialog__logo__img"
                  >
                </VFlex>
                <VFlex
                  xs12
                  md8
                >
                  <div class="text-subtitle-1">
                    {{ dealer.name }}
                  </div>
                  <div
                    v-if="dealer.address"
                  >
                    <span>
                      {{ dealer.address.street }}
                    </span>
                    <br>
                    {{ dealer.address.locality }} {{ dealer.address.region }} {{ dealer.address.postalCode }}
                  </div>
                </VFlex>
              </VLayout>
            </VFlex>
            <VFlex
              xs12
            >
              <div class="pt-6 pb-4 text-subtitle-1">
                Test Drive
              </div>
              <VCard>
                <VLayout>
                  <VFlex
                    xs4
                  >
                    <VehicleImg
                      color="primary"
                      contain
                      :src="vehicle.photos && vehicle.photos.primary.url"
                      height="110"
                      :aspect-ratio="1.18"
                    />
                  </VFlex>
                  <VFlex
                    xs8
                  >
                    <VCardTitle
                      v-if="vehicle.vehicle"
                      primary-title
                    >
                      <div>
                        <div class="text-subtitle-1 font-weight-bold">
                          {{ vehicle.vehicle.ymmt.year }} {{ vehicle.vehicle.ymmt.make }} {{ vehicle.vehicle.ymmt.model }}
                        </div>
                        <span class="text-body-1">
                          {{ vehicle.vehicle.ymmt.trim }}
                          <span class="text-body-2 rsvp-dialog__vehicle-mileage">{{ vehicle.vehicle.odometer.value | number }} miles</span>
                        </span>
                      </div>
                    </VCardTitle>
                  </VFlex>
                </VLayout>
              </VCard>
            </VFlex>
          </VLayout>
        </VFlex>
        <VFlex
          xs12
          md7
          :class="{'pl-4': $vuetify.breakpoint.mdAndUp }"
        >
          <VForm
            ref="form"
            v-model="formValid"
            lazy-validation
            @keyup.native.enter="submit"
          >
            <VAlert
              :value="formError"
              type="error"
            >
              Something went wrong trying to book appointment. Please try again later.
            </VAlert>
            <div class="pb-4 text-subtitle-1">
              Preferred Date and Time
            </div>
            <VLayout
              wrap
            >
              <VFlex
                xs12
                md6
                :class="{'pr-2': $vuetify.breakpoint.mdAndUp }"
              >
                <VMenu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template #activator="{ on }">
                    <VTextField
                      :value="form.date | date('dddd, MMMM D', 'YYYY-MM-DD')"
                      label="Date"
                      readonly
                      outlined
                      :rules="rules.required"
                      v-on="on"
                    />
                  </template>
                  <VDatePicker
                    v-model="form.date"
                    :min="Date.now() | date('YYYY-MM-DD')"
                    @change="datePicker = false"
                  />
                </VMenu>
              </VFlex>
              <VFlex
                xs12
                md6
                :class="{'pl-2': $vuetify.breakpoint.mdAndUp }"
              >
                <VSelect
                  v-model="form.time"
                  :items="timeOptions"
                  label="Time"
                  outlined
                />
              </VFlex>
            </VLayout>
            <div class="pt-6 pb-4 text-subtitle-1">
              Your Contact Details
            </div>
            <VLayout
              wrap
            >
              <VFlex
                xs12
                md6
                :class="{'pr-2': $vuetify.breakpoint.mdAndUp }"
              >
                <VTextField
                  v-model="form.firstName"
                  placeholder="First Name"
                  outlined
                  single-line
                  :rules="rules.required"
                  validate-on-blur
                />
              </VFlex>
              <VFlex
                xs12
                md6
                :class="{'pl-2': $vuetify.breakpoint.mdAndUp }"
              >
                <VTextField
                  v-model="form.lastName"
                  placeholder="Last Name"
                  outlined
                  single-line
                  class="py-0"
                  :rules="rules.required"
                  validate-on-blur
                />
              </VFlex>
              <VFlex
                xs12
              >
                <VTextField
                  v-model="form.email"
                  placeholder="Email"
                  outlined
                  single-line
                  :rules="rules.email"
                  validate-on-blur
                />
              </VFlex>
              <VFlex>
                <VTextFieldMasked
                  v-model="form.phone"
                  placeholder="Phone (optional)"
                  outlined
                  single-line
                  :rules="form.phone ? rules.phone : []"
                  mask="phone"
                  validate-on-blur
                />
              </VFlex>
            </VLayout>
          </VForm>
        </VFlex>
      </VLayout>
    </VCardText>
    <VCardActions class="px-6 pb-6">
      <VBtn
        class="text-capitalize text-body-1 font-weight-regular"
        depressed
        large
        :ripple="false"
        @click="$emit('close-dialog')"
      >
        Cancel
      </VBtn>
      <VBtn
        class="text-capitalize text-body-1 font-weight-regular"
        :color="formError ? 'error' : 'primary'"
        :disabled="!formValid || loading"
        :loading="loading"
        large
        :ripple="false"
        depressed
        @click="submit"
      >
        Set Appointment
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<style lang="scss">
  .rsvp-dialog__form {
    .v-card__text {
      h1 {
        @include typography('h5');

        margin-bottom: spacer(5);

        @include breakpoint('md-and-up') {
          @include typography('h4');

          margin-bottom: spacer(6);
        }
      }

      .layout.wrap > .flex {
        @include breakpoint('md-and-up') {
          padding-right: spacer(4);
        }
      }
    }

    .v-card__actions {
      .v-btn:first-child {
        margin-left: auto;
      }
    }
  }

  .rsvp-dialog__logo {
    @include breakpoint('md-and-up') {
      text-align: center;
    }

    .rsvp-dialog__logo__img {
      max-width: 130px;

      @include breakpoint('md-and-up') {
        max-width: 100%;
        padding: spacer(1) spacer(2);
      }
    }
  }

  .rsvp-dialog__vehicle-mileage {
    color: #919191;
  }
</style>


<script>
import VehicleHighlight from '@maxsystems/ui/patterns/VehicleHighlight'

export default {
  name: 'VehicleHighlights',
  components: { VehicleHighlight },
  props: {
    highlights: { type: Array, default: () => [] }
  }
}
</script>

<template>
  <section
    v-if="highlights.length"
    class="d-flex vehicle-highlights-wrapper"
  >
    <h2 class="text-h4">
      Highlights
    </h2>
    <VehicleHighlight
      v-for="highlight in highlights"
      :key="highlight"
      :highlight="highlight"
      class="mt-4"
    />
  </section>
</template>

<style lang="scss">
.vehicle-highlights-wrapper {
  flex-direction: column;
}
</style>

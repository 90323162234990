<script>
import { mapState } from 'vuex'

import { number } from '@maxsystems/ui/vue/filters'

import transformHighlights from '../utils/highlights'
import Pagination from './Pagination.vue'
import Sort from './Sort.vue'
import VehicleCard from '~/components/VehicleCard'

export default {
  name: 'SRPResults',
  components: {
    Pagination,
    VehicleCard,
    Sort
  },
  filters: {
    number
  },
  props: {
    to: {
      default: null,
      type: String
    }
  },
  computed: {
    ...mapState({
      query: state => state.search.query,
      resultCount: state => state.search.resultCount,
      facetLevel: state => state.search.staticFacets['exteriorColor.lvl2'] ? 1 : 0,
      results (state) {
        return state.search.results.map(vehicle => ({
          condition: vehicle.condition?.lvl0.toUpperCase(),
          certified: !!vehicle.condition?.lvl1?.includes('Certified'),
          color: {
            generic: vehicle.exteriorColor?.[`lvl${this.facetLevel}`]?.split(' > ').pop(),
            name: vehicle.exteriorColor?.[`lvl${this.facetLevel + 1}`]?.split(' > ').pop()
          },
          interiorColor: {
            generic: vehicle.interiorColor?.lvl2?.split(' > ').pop(),
            name: vehicle.interiorColor?.lvl2?.split(' > ').pop()
          },
          dealerName: vehicle.dealerName,
          distance: vehicle._rankingInfo?.geoDistance,
          drivetrain: vehicle.drivetrain,
          engine: vehicle.engine,
          inventory: vehicle.objectID,
          name: vehicle.yearmodel,
          make: vehicle.makeModelTrim?.lvl0,
          marketAverage: vehicle.marketAverage,
          mileage: vehicle.mileage,
          model: vehicle.makeModelTrim?.lvl1?.split(' > ').pop(),
          msrp: vehicle.msrp,
          photo: vehicle.photo,
          price: vehicle.price,
          savings: vehicle.savings,
          stock: vehicle.stock,
          summary: vehicle.description,
          transmission: vehicle.transmission?.lvl0,
          trim: vehicle.makeModelTrim?.lvl2?.split(' > ').pop(),
          upgrade: vehicle.upgradesValue,
          vin: vehicle.vin,
          year: vehicle.year,
          popularEquipment: vehicle.popularEquipment,
          highlights: transformHighlights(vehicle.highlights)
        }))
      },
      loadedResults () {
        const { $loading: loading, pagination: { hitsPerPage } } = this.$store.state.search
        if (!loading) return this.results
        return Array(hitsPerPage).fill({ loading })
      }
    })
  }
}
</script>

<template>
  <section class="results__wrapper">
    <VLayout
      wrap
      d-flex
    >
      <VFlex
        sm9
        xs7
        style="align-self: center;"
        class="subheading pl-2"
        data-test="results-count"
      >
        {{ resultCount | number }} matches
        <span v-if="query">
          for <b>"{{ query }}"</b>
        </span>
      </VFlex>
      <VFlex
        class="hidden-sm-and-down"
        sm3
        xs5
      >
        <Sort />
      </VFlex>
    </VLayout>
    <section>
      <VehicleCard
        v-for="vehicle in loadedResults"
        :key="vehicle.inventory"
        :to="to && { name: to, params: { inventory: vehicle.inventory } }"
        :distance="Math.floor(vehicle.distance / 1609.344)"
        v-bind="vehicle"
        class="my-4"
      />
    </section>
    <Pagination />
  </section>
</template>

<style lang="scss">
.results {
  &__wrapper {
    margin: spacer(4);
  }
}
</style>

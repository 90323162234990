import { SelectListSorted } from '../patterns'

export default {
  config: {
    title: 'Drivetrain',
    weight: 60,
    facet: 'drivetrain'
  },
  render (h) {
    return h(SelectListSorted, { props: { facet: this.$options.config.facet } })
  }
}

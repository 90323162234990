<script>
import { ConfirmDialog } from '@maxsystems/ui/patterns'

export default {
  props: {
    inventory: {
      type: [Number, String],
      required: true
    },
    fab: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confirmation: false,
      isHovering: false,
      color: 'grey',
      favoriteColor: 'favorite'
    }
  },
  computed: {
    canFavorite () {
      return this.$store && this.$store.state.opportunity && this.$store.getters['opportunity/modules'].has('board') && !this.$store.state.opportunity.viewOnly
    },
    isFavorite () {
      return this.$store.getters['opportunity/board/isFavorite'](this.inventory)
    },
    iconColor () {
      if (this.fab) {
        const notHoveringColor = !this.isFavorite ? this.color : this.favoriteColor
        return this.isHovering ? this.favoriteColor : notHoveringColor
      }
      return this.isFavorite ? 'white' : 'primary'
    },
    icon () {
      if (this.fab) {
        return this.isHovering && !this.isFavorite ? '$favoriteOutline' : '$favorite'
      }
      return '$favorite'
    }
  },
  methods: {
    async toggleFavorite () {
      if (this.isFavorite && !await ConfirmDialog.open({
        title: 'Remove this car from your favorites?',
        confirm: 'Remove'
      })) return
      this.$store.dispatch('opportunity/board/toggleFavorite', this.inventory)
    }
  }
}
</script>

<template>
  <VBtn
    v-if="canFavorite"
    v-bind="$attrs"
    :ripple="false"
    :v-ripple="false"
    :color="!fab && isFavorite ? 'primary': ''"
    :class="[
      `v-btn--favorite${fab ? '-circle': ''}`,
      {'primary--text': !fab && !isFavorite}
    ]"
    :fab="fab"
    depressed
    :outlined="!fab && !isFavorite"
    :elevation="fab ? isHovering ? 5 : 1 : 0"
    @click.prevent="toggleFavorite"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <VIcon
      :size="fab ? '18' : '16'"
      :class="{'mr-2' : !fab}"
      :color="iconColor"
    >
      {{ icon }}
    </VIcon>
    <span v-if="!fab">
      Favorite
    </span>
  </VBtn>
</template>

<style lang="scss">
.v-btn--favorite {
  &-circle {
    height: 30px !important;
    width: 30px !important;
  }
}
</style>

<script>
import mixin from './mixin'

export default {
  name: 'CopyLink',
  mixins: [mixin],
  computed: {
    shareLink () {
      return `https://${this.host}/board/${this.encoded}`
    }
  },
  methods: {
    copy () {
      if (this.$device.isMobile && navigator.share) {
        navigator.share({
          title: 'My Favorite Cars',
          url: this.shareLink
        }).catch(console.log)
        return
      }

      const input = this.$el.querySelector('input')
      input.select()
      document.execCommand('copy')
    }
  }
}
</script>

<template>
  <div>
    <VTextField
      class="hidden-sm-and-down"
      hide-details
      outlined
      readonly
      single-line
      :value="shareLink"
      @click="copy"
    />
    <VBtn
      class="text-capitalize text-body-1 font-weight-regular"
      color="primary"
      depressed
      @click="copy"
    >
      Copy
    </VBtn>
  </div>
</template>

import fetch from 'cross-fetch'
import Vue from 'vue'

import request from '@maxsystems/ui/api'
import { register } from '@maxsystems/ui/mixins/pluggable'

import getInstance from '../queries/instance.gql'
import PaymentCalculator from './payments'
import CustomerBoard from '~/plugins/board'
import ActionButtonGroup from '~/plugins/board/plugins/ActionButtonGroup'
import { getHost } from '~/utils/host.js'

/**
 * Higher-order function to defer mounting of the Vue app until the Instance has
 * been determined and hydrated into the Vuex store.
 * @param {Vue} localVue - The Vue instance which will be mounted.
 */
export async function withInstance (localVue) {
  const host = getHost()

  const { data: { instance } } = await request(getInstance, { host })
  instance.config = {
    logo: instance.logo?.url,
    ...instance.config || {}
  }

  localVue.$store.commit('instance/setInstance', instance)
  localVue.$store.commit('opportunity/setContext', {
    instance: instance.id,
    platform: host
  })

  await Promise.all([
    applyTheme,
    getLocation
  ].map(func => func.call(localVue)))

  if (localVue.$store.getters['instance/feature']('board')) {
    Vue.use(CustomerBoard, { store: localVue.$store })
    register('vdp', ActionButtonGroup)
  }

  if (localVue.$store.getters['instance/feature']('payments')) {
    Vue.use(PaymentCalculator, { store: localVue.$store })
  }

  localVue.$mount('#app')
}

export async function applyTheme () {
  const theme = this.$store.state.instance.config?.theme || 'default'

  try {
    await import(`../themes/${theme}/index.js`)
  } catch (err) {
    console.warn('A custom theme was requested that does not exist:', theme)
    await import('../themes/default/index.js')
  }
}

export async function getLocation () {
  try {
    const { city, zip, lat, lng } = await (await fetch('/api/locate')).json()

    this.$store.commit('search/setLocationFilter', ['latlng', [lat, lng].join(',')])
    this.$store.commit('search/setMeta', {
      plugin: 'location',
      value: { city, zip }
    })
  } catch (err) {}
}

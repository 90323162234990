import {
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VContainer
} from 'vuetify/lib'

import FilterCount from './FilterCount'

export default {
  name: 'RefinementMenu',
  inject: ['plugins'],
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    /**
     * Active plugins are the only filters that will display.
     * @return {Array}
     */
    activePlugins: vm =>
      vm.plugins.slice()
        .sort((a, b) => ((a.config.weight || 0) - (b.config.weight || 0)))
        .filter(vm.checkRequirements)
  },
  methods: {
    checkRequirements ({ config: { require: requires } }) {
      if (typeof requires === 'undefined') return true
      if (typeof requires === 'boolean') return requires

      const pass = []

      // If the plugin requires one or more Opportunity modules, show the
      // plugin only if all the modules exist. `require.opportunity` is an
      // array of strings with the module namespace, ex: ['payments']
      if (requires.opportunity) {
        pass.push(requires.opportunity.every(s => this.$store.getters['opportunity/modules'].has(s)))
      }

      // Plugins can require base filter values. The current filter values are
      // passed to the provided method to keep this flexible and allow for both
      // positive and negative checks.
      if (requires.baseFilters) {
        const { baseFilters } = this.$store.state.search
        pass.push(Object.keys(requires.baseFilters).every(filter => requires.baseFilters[filter](baseFilters[filter] || {}, baseFilters)))
      }

      if (requires.filters) {
        pass.push(Object.keys(requires.filters).every(filter => requires.filters[filter](this.$store.getters['search/filter'](filter))))
      }

      // If the plugin requires one or more available facet value
      if (requires.multipleFacetsAvailable) {
        const facets = this.$store.state.search.staticFacets[requires.multipleFacetsAvailable]
        pass.push(facets && Object.keys(facets).length > 1)
      }

      // If the plugin requires specific logic
      if (requires.custom) {
        pass.push(requires.custom(this))
      }

      return pass.every(Boolean)
    }
  },
  render (h) {
    return h(VExpansionPanels, {
      class: 'br-0',
      props: {
        accordion: true,
        multiple: true,
        value: this.value
      }
    }, this.activePlugins.map(plugin => h(VExpansionPanel, {
      class: [
        'srp__filter',
        plugin.config.facet && 'srp__filter--' + plugin.config.facet.replace(/\./g, '-')
      ],
      props: {
        readonly: plugin.config.collapsible === false
      }
    }, [
      h(VExpansionPanelHeader, {
        class: 'text-body-1 font-weight-bold py-0',
        props: {
          plugin
        }
      }, [
        typeof plugin.config.title === 'function' ? plugin.config.title.call(this, this) : plugin.config.title,
        h(FilterCount, {
          class: 'ml-4',
          props: { facet: plugin.config.facet }
        })
      ]),
      h(VExpansionPanelContent, [
        h(VContainer, { class: 'container--fluid pa-0' },
          [h(plugin, { props: this.$props })]
        )]
      )
    ]
    )))
  }
}

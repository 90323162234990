import { register } from '@maxsystems/ui/mixins/pluggable'

import Make from './Make'
import Model from './Model'
import Trim from './Trim'
import Year from './Year'

register('srp', Year)
register('srp', Make)
register('srp', Model)
register('srp', Trim)

export {
  Year,
  Make,
  Model,
  Trim
}

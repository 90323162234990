import { RangeSlider } from '../../patterns'

export default {
  name: 'ShopByPrice',
  components: {
    RangeSlider
  },
  title: 'Vehicle Price',
  inject: ['meta'],
  onSelected () {
    this.meta = { ...this.meta, shopByPayment: false }
  },
  computed: {
    active () {
      return !this.meta.shopByPayment
    }
  },
  render (h) {
    return h('RangeSlider', {
      props: {
        facet: 'price',
        filter: 'currency',
        hideDetails: true
      }
    })
  }
}

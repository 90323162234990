<script>
import debounce from 'lodash.debounce'
import { mapState } from 'vuex'

import { algolia } from '../routes/SRP/api'

export const index = algolia.client.initIndex('contentful')

export default {
  data: () => ({
    searchTerm: '',
    isLoading: false,
    results: []
  }),
  computed: {
    ...mapState('instance', ['config']),
    attach () {
      return this.$el || ''
    }
  },
  methods: {
    async search () {
      const { lat, lng } = this.$store.state.instance.visitor.location

      const { hits } = await index.search(this.searchTerm, {
        attributesToRetrieve: ['name', 'url'],
        aroundLatLng: (lat && lng) && [lat, lng].join(', '),
        hitsPerPage: 7,
        filters: (this.config.srp || []).map(id => `objectID:${id}`).join(' OR ')
      })

      this.results = hits.map(({ name, url, objectID: id, _highlightResult }) => ({
        id,
        name,
        highlighted: _highlightResult?.name.value || name,
        url: `https://${url}`
      }))
    },
    onSearchInput: debounce(async function (term) {
      this.searchTerm = (term?.length ? term : '').trim()
      if (!this.searchTerm) {
        if (this.results?.length) this.results = []
        return
      }

      this.isLoading = true
      await this.search()
      this.isLoading = false
    }, 350),
    onChange (selected) {
      this.$emit('selected', selected)
    }
  }
}
</script>

<template>
  <div class="dealer-autocomplete">
    <VAutocomplete
      :append-icon="null"
      :attach="attach"
      :hide-no-data="searchTerm.length < 2"
      :items="results"
      :loading="isLoading"
      :no-data-text="isLoading ? 'Loading' : 'We could not find matching Dealerships'"
      clearable
      color="#000"
      dense
      eager
      flat
      hide-details
      item-text="name"
      no-filter
      placeholder="Dealership Name"
      prepend-inner-icon="$search"
      return-object
      solo
      @update:search-input="onSearchInput"
      @change="onChange"
    >
      <template
        slot="item"
        slot-scope="{ item }"
      >
        <!-- eslint-disable-next-line -->
        <span v-html="item.highlighted" />
      </template>
    </VAutocomplete>
  </div>
</template>

<style lang="scss">
.dealer-autocomplete {
  position: relative;

  & em {
    font-style: normal;
    font-weight: 700;
  }
}
</style>

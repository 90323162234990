<script>
import { VTextFieldMasked } from 'v-text-field-masked'

import geo from '@maxsystems/ui/api/geo'
import { Surface, Slider } from '@maxsystems/ui/elements'

export default {
  name: 'SRPDistance',
  components: {
    Surface,
    Slider,
    VTextFieldMasked
  },
  props: {
    placeholder: {
      default: '',
      type: String
    }
  },
  data: () => ({
    options: [0, 10, 25, 50, 100, 250, 500, 1000, 2000, 3000, 4000],
    inputs: {
      city: '',
      zip: ''
    },
    error: null
  }),
  computed: {
    distance: {
      get () {
        return this.options.indexOf(this.$store.getters['search/locationFiltersDistance'])
      },
      set (value) {
        this.$store.commit('search/setLocationFilter', ['distance', Number(this.options[value])])
        this.$store.dispatch('search/search')
      }
    },
    errorMessage () {
      if (this.error === 'ZERO_RESULTS') {
        return `Zip code ${this.zip} is not valid.`
      }
      return ''
    },
    radius () {
      return this.options[this.distance] || 'Any'
    },
    zip: {
      get () {
        return this.inputs.zip ||
          this.$store.state.search.meta.location?.zip ||
          this.placeholder
      },
      set (value) {
        if (value.length < 5) return
        this.inputs.zip = value
        this.setLatLng(value)
      }
    }
  },
  methods: {
    async setLatLng (zip) {
      const result = await geo.zipToGeo(zip)

      if (!result || result.error) {
        this.error = result.error
        return
      }

      this.inputs.city = result.city
      this.$store.commit('search/setLocationFilter', ['latlng', result.latlng])
      this.$store.dispatch('search/search')
      this.error = null

      // Save the location meta to a store that does not pass to Algolia
      this.$store.commit('search/setMeta', {
        plugin: 'location',
        value: this.inputs
      })
    }
  }
}
</script>

<template>
  <section class="srp__distance">
    <VTextFieldMasked
      ref="autofocus"
      v-model="zip"
      :error="Boolean(error)"
      append-icon="$expand"
      hide-details
      mask="#####"
      outlined
      prefix="Near"
      prepend-inner-icon="$location"
      single-line
      @focus="$event.target.select()"
    />
    <Surface
      class="srp__distance__popover"
      elevation="3"
    >
      <div
        v-if="!error"
        class="px-2 pb-2"
      >
        <Slider
          v-model="distance"
          :max="options.length - 1"
          always-dirty
          class="px-2 mt-0"
          hide-details
          thumb-size="small"
          min="0"
          ticks="always"
        />
        <p class="text-caption text-center">
          Results within
          <span class="font-weight-bold primary--text">
            {{ String(radius).replace('000', 'K') }}
          </span>
          miles
        </p>
      </div>
      <div
        v-if="error"
        class="px-2 pb-2 pt-2"
      >
        <p class="error--text text-caption text-center">
          {{ errorMessage }}
        </p>
      </div>
    </Surface>
  </section>
</template>

<style lang="scss">
.srp__distance {
  margin-left: -1px;
  max-width: 12rem;
  position: relative;

  &__popover {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: none;
    left: 0;
    margin-left: 1px;
    position: absolute;
    right: 0;
    z-index: 2;
  }

  &__popover:hover,
  & .v-text-field:focus-within + &__popover {
    display: block;
  }

  & .v-text-field--outlined .v-input__slot {
    font-size: 14px;

    & .v-input__prepend-inner .v-icon {
      width: 16px;
    }

    &:focus-within {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      & .v-input__append-inner .v-icon {
        transform: rotate(-180deg);
      }
    }

    & .v-text-field__prefix {
      line-height: 20px;
      margin: 0;
      margin-right: 6px;
      max-height: none;
      padding: 12px 0;
    }

    & .v-input__append-inner {
      margin: 10px 0 0 10px;
      padding-right: 0;
    }
  }
}
</style>

import SelectListRow from './SelectListRow'

export default {
  name: 'SelectListSorted',
  props: {
    facet: {
      type: String,
      required: true
    },
    order: {
      type: String,
      enum: ['alphabetical', 'popularity'],
      default: 'alphabetical'
    },
    operator: {
      type: String,
      enum: ['AND', 'OR'],
      default: 'OR'
    },
    // if true, remove all values and select one new value at a time
    radio: {
      type: Boolean,
      default: false
    },
    // if true, use staticFacets to prevent list options from updating
    static: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    facets () {
      const staticFacets = this.$store.state.search.staticFacets[this.facet]
      return this.static
        ? Object.keys(staticFacets).map(key => ({ name: key, count: staticFacets[key] }))
        : this.$store.state.search.facets[this.facet] || []
    },
    values () {
      const values = this.facets.slice()

      switch (this.order) {
        case 'popularity':
          values.sort((a, b) => b.count - a.count)
          break
        case 'alphabetical':
          values.sort((a, b) => a.name.localeCompare(b.name))
      }

      return values
    },
    value: {
      get () {
        return this.$store.getters['search/filter'](this.facet) || []
      },
      set (value) {
        this.$store.commit('search/replaceFilter', [this.facet, value, { operator: this.operator }])
        this.$store.dispatch('search/search')
      }
    }
  },
  methods: {
    label (option) {
      let { name } = option
      if (this.facet.includes('.lvl')) {
        name = name.split(' > ').pop()
      }
      return name
    },
    onChange (value) {
      this.value = value
    }
  },
  render (h) {
    return h('section', this.values.map(option =>
      h(SelectListRow, {
        key: `index-${option.name.replace(/\s/g, '')}`,
        on: {
          change: this.onChange
        },
        props: {
          modelArray: this.value,
          value: option.name,
          count: option.count,
          label: this.label(option),
          radio: this.radio
        },
        scopedSlots: {
          ...this.$vnode.data.scopedSlots
        }
      })
    ))
  }
}

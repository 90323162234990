<script>
import { SelectListSorted } from '../../patterns'

export default {
  name: 'YMMTMake',
  config: {
    title: 'Make',
    weight: 10,
    facet: 'makeModelTrim.lvl0'
  },
  components: {
    SelectListSorted
  },
  methods: {
    logo (oem) {
      oem = oem.replace(/[\W-]/g, '').toLowerCase()
      return `https://cdn.maxapps.io/logos/oem/${oem}@2x.png`
    }
  }
}
</script>

<template>
  <SelectListSorted
    :facet="$options.config.facet"
    order="alphabetical"
  >
    <template #icon="{ value }">
      <img
        :src="logo(value)"
        :alt="value"
        width="24"
      >
    </template>
  </SelectListSorted>
</template>

<style lang="scss">
.srp__filter--makeModelTrim-lvl0 {
  .select-list-row img {
    height: 40px;
    width: 40px;
  }
}
</style>

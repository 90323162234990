<script>
import { currency } from '@maxsystems/ui/vue/filters'

export default {
  filters: {
    currency
  },
  inject: ['dataSource'],
  computed: {
    isNew: vm => vm.dataSource.inventory.condition === 'NEW',
    monthlyPayment () {
      const value = this.$store.getters['opportunity/payments/monthlyPayment'](this.price)
      return value ? this.$options.filters.currency(value) : ''
    },
    msrp: vm => vm.dataSource.inventory.price?.msrp?.amount,
    price: vm => vm.dataSource.inventory.price?.showroomExportPrice?.amount,
    showPayments () {
      return this.price && this.$store.getters['opportunity/modules']?.has('payments')
    },
    showMSRP: vm => vm.isNew && vm.msrp > vm.price,
    ymmt: vm => vm.dataSource.vehicle.ymmt || {}
  },
  methods: {
    showPaymentCalculator () {
      if (!this.showPayments) return
      this.$router.push({ name: 'board', hash: '#/payments' })
    }
  }
}
</script>

<template>
  <VLayout wrap>
    <VFlex class="vehicle-summary__ymmt xs12 sm8">
      <h1 class="font-weight-medium">
        <span v-if="isNew">NEW</span>
        {{ ymmt.year }} {{ ymmt.make }} {{ ymmt.model }}
      </h1>
      <h4 class="text-h6 font-weight-light">
        {{ ymmt.trim }}
      </h4>
    </VFlex>
    <VFlex
      :class="['vehicle-summary__pricing xs12 sm4', { 'vehicle-summary__pricing--payments': showPayments }]"
      @click="showPaymentCalculator"
    >
      <VLayout wrap>
        <VFlex class="xs6 sm12">
          <h2 class="font-weight-bold">
            <span v-if="price > 1">{{ price | currency }}</span>
            <span v-else>Price Not Available</span>
          </h2>
          <div
            v-if="showMSRP"
            class="text-body-2 grey--text text--darken-2"
          >
            MSRP:
            <del class="text-body-1 ml-1">
              {{ msrp | currency }}
            </del>
          </div>
          <h5
            v-if="showPayments"
            class="font-weight-bold text-body-1"
          >
            {{ monthlyPayment }}
            <span
              v-if="monthlyPayment"
              class="text-caption font-weight-regular"
            >
              / month <br
                class="hidden-sm-and-up"
              >
              <span class="hidden-sm-and-up">Est. Finance Payment</span>
              <span class="hidden-sm-and-down">est</span>
            </span>
            <span
              v-else
              class="text-caption font-weight-regular"
            >
              Low to No Monthly Payment
              <VIcon size="16">
                $info
              </VIcon>
            </span>
          </h5>
        </VFlex>
      </VLayout>
    </VFlex>
  </VLayout>
</template>

<style lang="scss">
.vehicle-summary {
  &__ymmt {
    h1 {
      @include typography('h6');

      @include breakpoint('sm-and-up') {
        @include typography('h4');
      }
    }
  }

  &__pricing {
    margin-top: 16px;

    @include breakpoint('sm-and-up') {
      margin-top: 0;
      text-align: right;
    }

    & h2 {
      line-height: 40px;
    }

    & h5 {
      line-height: 18px;
      text-align: right;

      @include breakpoint('sm-and-up') {
        line-height: 23px;
      }
    }

    & sup {
      top: -0.15em;
    }

    &--payments {
      cursor: pointer;
    }
  }
}
</style>

<script>
import { mapFlags } from '@maxsystems/ui/utils/features'

export default {
  name: 'TestDriveAction',
  computed: {
    ...mapFlags(['appointments'])

  }
}
</script>

<template>
  <ReservationDialog
    v-if="appointments"
    v-bind="$attrs"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import { InputNumeric, Slider } from '@maxsystems/ui/elements'

export default {
  name: 'CurrencySlider',
  components: {
    InputNumeric,
    Slider
  },
  props: {
    assumption: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max: {
      type: [Number, String],
      default: 30000
    },
    min: {
      type: [Number, String],
      default: 0
    },
    step: {
      type: [Number, String],
      default: 500
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      sliderLocked: false,
      input: 0
    }
  },
  computed: {
    slider: {
      get () {
        return this.input
      },
      set (value) {
        if (this.sliderLocked) return
        this.input = value
        this.update(value)
      }
    },
    ...mapGetters('opportunity/payments', ['assumptions'])
  },
  created () {
    this.input = this.$store.getters['opportunity/payments/assumptions'][this.assumption]
  },
  methods: {
    focus ($event) {
      this.sliderLocked = true
      this.$nextTick(() => $event.target.select())
    },
    update (newValue) {
      this.$store.dispatch('opportunity/payments/updateAssumptions', {
        [this.assumption]: newValue
      })
    }
  }
}
</script>

<template>
  <VLayout
    class="currency-slider"
    wrap
  >
    <VFlex
      d-flex
      justify-space-between
    >
      <h4 class="text-subtitle-1">
        {{ title }}
      </h4>
      <InputNumeric
        v-model="input"
        prefix="$"
        :min="min"
        :max="max"
        outlined
        single-line
        :disabled="disabled"
        @focus="focus"
        @blur="sliderLocked = false"
        @update="update($event)"
      />
    </VFlex>
    <VFlex xs12>
      <Slider
        v-model="slider"
        :max="max"
        :min="min"
        :step="step"
        :disabled="disabled"
      />
    </VFlex>
  </VLayout>
</template>

<style lang="scss">
  .currency-slider {
    .v-text-field {
      max-width: 35%;
    }
  }
</style>

<style scoped>
  .currency-slider >>> .v-text-field__prefix {
    margin-top: 4px;
  }
</style>

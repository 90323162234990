<script>
import { number, currency } from '@maxsystems/ui/vue/filters'

import { dataSource } from '../../data-source'

export default {
  name: 'MarketValueTable',
  filters: {
    number,
    currency
  },
  inject: ['dataSource'],
  props: {
    ymmt: {
      type: Object,
      required: true
    },
    inventoryId: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cars: vm => vm.marketListings?.edges?.map(e => ({
      trim: e.node.vehicle.ymmt.trim,
      color: e.node.vehicle.color.generic,
      odometer: e.node.vehicle.odometer.value,
      isCertified: e.node.certified.isCertified,
      price: e.node.price.listPrice.amount,
      savings: e.savings.amount
    })) || [],
    loadingRows () {
      return Math.min(20, this.ymmt.marketListings.totalCount)
    },
    marketListings: vm => vm.dataSource.marketListings
  },
  methods: {
    async getMarketListings () {
      if (this.cars.length > 0) return

      this.dataSource.fetchMarketListing(this.inventoryId)
    },
    togglePanelStatus (value) {
      dataSource.isMarketValuePanelOpen = value === 0
    }
  }
}
</script>

<template>
  <VExpansionPanels
    :value="expanded ? 0 : undefined"
    class="vdp__market-value-table"
    @change="togglePanelStatus"
  >
    <VExpansionPanel @change="getMarketListings">
      <VExpansionPanelHeader class="font-family-header font-weight-bold px-4">
        Similar {{ ymmt.year }} {{ ymmt.make }} {{ ymmt.model }} within 250 miles
      </VExpansionPanelHeader>
      <VExpansionPanelContent>
        <VSimpleTable
          fixed-header
          :loading="cars.length === 0"
          hide-default-footer
        >
          <template #default>
            <thead>
              <tr>
                <th
                  class="text-left"
                  scope="col"
                >
                  Trim
                </th>
                <th
                  class="text-left"
                  scope="col"
                >
                  Color
                </th>
                <th
                  class="text-left"
                  scope="col"
                >
                  Mileage
                </th>
                <th
                  class="text-center"
                  scope="col"
                >
                  Certified
                </th>
                <th
                  class="text-right"
                  scope="col"
                >
                  Price
                </th>
                <th
                  class="text-right"
                  scope="col"
                >
                  You Save
                </th>
              </tr>
            </thead>
            <tbody v-if="cars.length === 0">
              <tr>
                <td
                  colspan="6"
                  class="pa-0"
                >
                  <VSkeletonLoader
                    :type="`table-row-divider@${loadingRows - 1}, table-row`"
                  />
                </td>
              </tr>
            </tbody>
            <tbody
              v-else
              class="pb-1"
            >
              <tr
                v-for="(car, i) in cars"
                :key="i + 'listing'"
              >
                <td>{{ car.trim }}</td>
                <td>{{ car.color }}</td>
                <td>{{ car.odometer | number }}</td>
                <td class="text-center">
                  <VIcon
                    :color="car.isCertified ? 'success' : 'grey lighten-2'"
                    small
                  >
                    {{ car.isCertified ? '$success' : '$cancel' }}
                  </VIcon>
                </td>
                <td class="text-right">
                  {{ car.price | currency }}
                </td>
                <td class="success--text font-weight-bold text-right">
                  {{ car.savings | currency }}
                </td>
              </tr>
            </tbody>
          </template>
        </VSimpleTable>
      </VExpansionPanelContent>
    </VExpansionPanel>
  </VExpansionPanels>
</template>

<style lang="scss">
  .vdp__market-value-table {
    &.d-print-block {
      break-before: page;

      .v-data-table {
        &__wrapper {
          max-height: unset;
        }
      }
    }

    .v-expansion-panel-content__wrap {
      padding: 0;
    }

    .v-data-table {
      &__wrapper {
        max-height: 265px;
      }
    }

    .v-skeleton-loader__bone {
      &.v-skeleton-loader__divider {
        height: 1px;
      }

      &.v-skeleton-loader__table-cell {
        flex-grow: 1;
        padding: 16px;
        width: auto;
      }
    }
  }

  @media print {
    .vdp__market-value-table {
      .v-expansion-panel-header__icon {
        display: none;
      }
    }
  }
</style>

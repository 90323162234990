<script>
import BtnFavorite from '~/components/BtnFavorite'

export default {
  name: 'FavoriteAction',
  components: { BtnFavorite }
}
</script>

<template>
  <BtnFavorite
    v-bind="$attrs"
    :ripple="false"
    block
    class="v-btn--favorite"
  />
</template>

import Vue from 'vue'

import fetch from '@maxsystems/ui/api'

import DealerConfigQuery from '../queries/dealer-config.gql'

/**
 * Dealer configs by id
 */
export const DealerConfigs = Vue.observable({})

/**
 *
 * Takes a dealer `buid` and returns the requested dealer config
 *
 * @param {String|Number} id - dealer buid
 * @returns {Object} config - the requested config
 */
export const fetchDealerConfig = async id => {
  const { data } = await fetch(DealerConfigQuery, { id })
  return data?.dealer?.config
}

/**
 * Takes a dealer `buid` and fetches the relevant config
 *
 * if an attempt to fetch it hasn't already been made
 *
 * @param {String|Number} id - dealer buid
 */
export const fetchConfig = async id => {
  // cached?
  if (DealerConfigs[id]) return

  // fetch config and store it as observable
  DealerConfigs[id] = Vue.observable(await fetchDealerConfig(id))
}

/**
 * Takes a dealer `buid` and checks if it has Omni capabilities
 *
 * @param {String|Number} id - dealer buid
 * @returns {Boolean} dealerHasOmni - if dealer has Omni capabilities
 */
export const hasOmni = id => Boolean(DealerConfigs?.[id]?.creditiq)

import Vue from 'vue'

export const LOCAL_STORAGE_PATH = 'com.max.path.search.dealers'

const store = Vue.observable({ dealers: [] })

export const addRecentSearch = function addRecentSearch (dealer) {
  if (this.dealers.find(({ id }) => id === dealer.id)) return
  const { id, name, url } = dealer
  this.dealers = [{ id, name, url }, ...this.dealers].slice(0, 5)
  localStorage.setItem(LOCAL_STORAGE_PATH, JSON.stringify(this.dealers))
}.bind(store)

export default {
  computed: {
    recentSearches: () => store.dealers.filter(({ url }) => url)
  },
  beforeMount () {
    store.dealers = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PATH) || '[]')
  },
  render () {
    if (!this.recentSearches.length) return
    return this.$scopedSlots.default({ recentSearches: this.recentSearches })
  }
}

<script>
export default {
  name: 'SRPSort',
  data: () => ({
    sortOptions: [
      { text: 'Relevance', value: '' },
      { text: 'Lowest Price', value: 'priceAsc' }
    ]
  }),
  computed: {
    value: {
      get () {
        return this.$store.state.search.index.replace(process.env.ALGOLIA_INVENTORY, '').split('_').pop()
      },
      set ({ value }) {
        this.$store.commit('search/setIndex', value)
        this.$store.dispatch('search/search')
      }
    }
  }
}
</script>

<template>
  <VSelect
    v-model="value"
    :items="sortOptions"
    :return-object="true"
    class="srp__sort font-weight-medium"
    item-text="text"
    prepend-inner-icon="$sortVariant"
  />
</template>

<style lang="scss">
.v-select.srp__sort {
  & .v-input__slot {
    background: var(--v-grey-lighten3);
    border-radius: 4px;
    padding: 8px 12px;

    &::before,
    &::after {
      display: none;
    }
  }

  & .v-select__selection {
    color: var(--v-grey-darken3);
    font-size: 14px;
    margin: 0;
  }

  & .v-input__prepend-inner {
    padding-right: 8px;
  }

  @include breakpoint('sm-and-down') {
    & .v-input__slot {
      background-color: var(--v-light-lighten1);
      border: 1px solid #DBDBDB;
      border-radius: 0;
      border-top: 0;
      color: #4F595C;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      text-transform: none !important;

      &::before,
      &::after {
        display: none;
      }
    }

    & .v-input__prepend-inner {
      margin-top: 0;
      padding-right: 8px;
    }

    &.v-text-field {
      margin: 0;
      padding-top: 0;
    }
  }
}
</style>

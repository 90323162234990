<script>
import { mapGetters } from 'vuex'

import { currency } from '@maxsystems/ui/vue/filters'

export default {
  filters: {
    currency
  },
  computed: {
    showButton () {
      return this.$router && !this.$store.state.opportunity.viewOnly
    },
    href () {
      const path = this.$router.resolve({ name: 'srp', params: { 0: '' } }).href
      return path + `/under-${this.assumptions.monthlyPayment}-mo`
    },
    ...mapGetters('opportunity/payments', ['assumptions'])
  }
}
</script>

<template>
  <div class="text-center">
    <p class="text-body-1 mb-1">
      Ready to see which cars you can afford?
    </p>
    <VBtn
      v-if="showButton"
      class="payment-calculator__btn-shop br-1 text-body-1 font-weight-bold"
      color="primary"
      depressed
      :to="href"
    >
      Shop Cars Under {{ assumptions.monthlyPayment | currency }}/mo
    </VBtn>
  </div>
</template>

<style lang="scss">
.payment-calculator__btn-shop {
  min-height: 46px;
  text-transform: none;
}
</style>

<script>
import { ColorWell } from '@maxsystems/ui/elements'
import { drivetrain, bodyType, transmission } from '@maxsystems/ui/vue/filters'

export default {
  filters: {
    bodyType,
    drivetrain,
    transmission
  },
  components: {
    ColorWell
  },
  inject: ['dataSource'],
  computed: {
    attributes: vm => ([
      { name: 'Miles', value: `${vm.odometer} Miles` },
      { name: 'BodyType', value: vm.$options.filters.bodyType(vm.bodyType) },
      { name: 'Engine', value: vm.engine.replace('Cylinder', 'Cyl').trim() },
      { name: 'Transmission', value: vm.$options.filters.transmission(vm.transmission) },
      { name: 'Drivetrain', value: vm.$options.filters.drivetrain(vm.drivetrain) },
      { name: 'Fuel Economy', value: vm.fuelEconomy },
      { name: 'Vin', value: `VIN: ${vm.vin}` },
      { name: 'Stock', value: `Stock #: ${vm.stockNumber}` }
    ]),
    bodyType: vm => vm.dataSource.vehicle.ymmt?.bodyType,
    drivetrain: vm => vm.dataSource.vehicle.ymmt?.drivetrain,
    engine: vm => vm.dataSource.vehicle.ymmt?.engine?.type,
    exterior: vm => vm.dataSource.vehicle.color || {},
    fuelEconomy () {
      const city = this.dataSource.vehicle?.ymmt.fuelEconomy?.city?.value
      const highway = this.dataSource.vehicle?.ymmt.fuelEconomy?.highway?.value
      return [
        city && `${city} mpg City`,
        highway && `${highway} mpg Highway`
      ].filter(Boolean).join(' / ')
    },
    interior: vm => vm.dataSource.vehicle.interior?.color || {},
    odometer: vm => (vm.dataSource.vehicle.odometer?.value || 0).toLocaleString(),
    stockNumber: vm => vm.dataSource.inventory.stockNumber,
    transmission: vm => vm.dataSource.vehicle.ymmt?.transmission,
    vin: vm => vm.dataSource.vehicle.vin
  }
}
</script>

<template>
  <VLayout
    class="vdp__vehicle-attrs text-body-1 grey--text text--darken-3"
    wrap
  >
    <VFlex
      class="xs12 md6 my-1"
      :class="{'vdp__vehicle-attrs__color': !(exterior.description || exterior.generic)}"
    >
      <template v-if="exterior.name || exterior.generic">
        <ColorWell
          v-if="exterior.description || exterior.generic"
          :color="exterior.description || exterior.generic"
          :size="14"
          class="mx-1"
        />{{ exterior.name || exterior.generic }} Exterior
      </template>
    </VFlex>
    <VFlex
      class="xs12 md6 my-1"
      :class="{'vdp__vehicle-attrs__color': !(interior.description || interior.generic)}"
    >
      <template v-if="interior.name || interior.generic">
        <ColorWell
          v-if="interior.description || interior.generic"
          :color="interior.description || interior.generic"
          :size="14"
          class="mx-1"
        />{{ interior.name || interior.generic }} Interior
      </template>
    </VFlex>
    <template v-for="attr in attributes">
      <VFlex
        v-if="attr.value && attr.value != ' '"
        :key="attr.name.replace(/ /g, '')"
        class="vdp__vehicle-attrs__attr xs12 md6 my-1"
      >
        <span>&bull;</span> {{ attr.value }}
      </VFlex>
    </template>
  </VLayout>
</template>

<style lang="scss">
.vdp__vehicle-attrs {
  &__attr {
    align-items: center;
    display: flex;
    line-height: 24px;

    & span {
      color: #000;
      display: inline-block;
      font-size: 26px;
      line-height: 13px;
      margin: 1px 4px;
      text-align: center;
      width: 13px;
    }
  }

  &__color {
    padding-left: 21px;
  }
}
</style>

<script>
import { extent } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { area, curveCardinal, line } from 'd3-shape'
import ResizeObserver from 'resize-observer-polyfill'

import { currency } from '@maxsystems/ui/vue/filters'

export default {
  name: 'MarketValueChart',
  filters: {
    currency
  },
  props: {
    price: {
      type: Number,
      required: true
    },
    comparisons: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    $recalc: null,
    // Percent by which to crop the sides. It tends to be flat uninteresting graph at the ends.
    crop: 0.15,
    // These are uniformly distributed values from the cumulative distribution function
    // (S-curve) of the standard normal probability distribution (bell curve).
    // Used to draw both the line itself and the price points.
    dataTable: [
      [],
      [],
      [0.159, 0.841],
      [0.0668, 0.5, 0.9332],
      [0.04, 0.27, 0.73, 0.96],
      [0.02, 0.16, 0.5, 0.84, 0.98],
      [0.02, 0.1, 0.33, 0.66, 0.9, 0.98],
      [0.01, 0.07, 0.23, 0.5, 0.77, 0.93, 0.99]
    ],
    height: 240,
    width: 0,
    // Line will take up 1 padding unit.
    // 1 unit of padding on top and bottom places the line in the middle of the chart.
    // These numbers use the golden ratio to place the chart closer to the bottom.
    paddingBottom: 0.646,
    paddingTop: 1.354
  }),
  computed: {
    area () {
      return this.createArea(this.linePoints)
    },
    cdfPoints () {
      return [this.paddingBottom, ...this.dataTable[this.dataTable.length - 1].map(x => x + this.paddingBottom), this.paddingBottom + 1]
    },
    line () {
      return this.createLine(this.linePoints)
    },
    linePoints () {
      return this.cdfPoints.map((d, i) => ({
        x: Math.floor(this.scaled.x(i)),
        y: Math.ceil(this.scaled.y(d)),
        max: this.height
      }))
    },
    priceData () {
      return [
        {
          name: 'Your Price',
          value: {
            amount: this.price
          }
        },
        ...(this.comparisons?.slice(0, 2).reverse() || [])
      ]
    },
    pricePoints () {
      const cdfPoints = this.dataTable[this.priceData.length].map(x => x + this.paddingBottom)
      const points = cdfPoints.map((d, i) => ({
        x: Math.floor(this.scaled.x((i + 1) * (this.dataTable[this.dataTable.length - 1].length + 1) / (this.priceData.length + 1))),
        y: Math.ceil(this.scaled.y(d)),
        max: this.height,
        name: this.fixLongStrings(this.priceData[i].name),
        value: this.priceData[i].value.amount
      }))

      return points
    },
    scaled () {
      const scale = {
        x: scaleLinear().range([0, this.width * (1 + (this.crop * 2))]),
        y: scaleLinear().range([this.height, 0])
      }

      scale.x.domain(extent(this.cdfPoints, (d, i) => i))
      scale.y.domain([0, this.paddingBottom + 1 + this.paddingTop])

      return scale
    }
  },
  mounted () {
    // ResizeObserver is currently only supported in Chrome so a ponyfill is
    // being used to ensure cross-browser compatibility. This is more performant
    // than a window resize listener since it will only fire when the breakpoint
    // causes the element to change size.
    this.$recalc = new ResizeObserver(entries => {
      this.width = Math.ceil(entries[0].contentRect.width)
    })

    this.$recalc.observe(this.$el)
  },
  beforeDestroy () {
    this.$recalc.unobserve(this.$el)
  },
  methods: {
    createArea: area().curve(curveCardinal).x(d => d.x).y0(d => d.max).y1(d => d.y),
    createLine: line().curve(curveCardinal).x(d => d.x).y(d => d.y),
    findMiddleWhitespace (value) {
      const middle = Math.floor(value.length / 2)
      if (/\s/.test(value[middle])) {
        return middle
      }

      for (let i = 0; middle - i >= 0; i++) {
        if (/\s/.test(value[middle + i])) {
          return middle + i
        }

        if (/\s/.test(value[middle - i])) {
          return middle - i
        }
      }
    },
    fixLongStrings (value) {
      if (value.length >= 15) {
        const whitespaceIndex = this.findMiddleWhitespace(value)
        return [value.substr(0, whitespaceIndex), value.substr(whitespaceIndex + 1)]
      }

      return [value]
    }
  }
}
</script>

<template>
  <div class="vdp__market-value-chart">
    <svg
      :height="height"
      width="100%"
      :viewBox="`${Math.floor(width * crop)} 0 ${Math.floor(width)} ${height}`"
    >
      <g>
        <line
          v-for="(point, index) in pricePoints"
          :key="'dashed-line_' + index"
          :x1="point.x"
          :y1="point.y - 27"
          :x2="point.x"
          :y2="point.y"
          stroke-opacity="0.75"
          stroke="var(--v-info-base)"
          stroke-dasharray="2, 2, 2, 2"
          stroke-linecap="square"
          stroke-width="1"
        />
        <path
          fill="url(#vdp__market-value-chart__gradient)"
          :d="area"
        />
        <path
          fill="none"
          stroke="var(--v-info-base)"
          stroke-width="3"
          :d="line"
        />
        <rect
          v-for="(point, index) in pricePoints"
          :key="'point_' + index"
          :x="point.x - 10"
          :y="point.y - 10"
          width="20"
          height="20"
          rx="10"
          :fill="index === 0 ? 'var(--v-info-base)' : '#fff'"
          :stroke="index === 0 ? '#fff' : 'var(--v-info-base)'"
          stroke-width="2"
        />
        <rect
          :x="pricePoints[0].x - 50"
          :y="pricePoints[0].y - 100"
          :height="65"
          width="100"
          rx="2.5"
          fill="var(--v-primary-base)"
          :style="`filter: url(#vdp__market-value-chart__drop-shadow);`"
        />
        <polygon
          :points="`${pricePoints[0].x - 15} ${pricePoints[0].y - 36}, ${pricePoints[0].x + 15} ${pricePoints[0].y - 36}, ${pricePoints[0].x} ${pricePoints[0].y - 28}`"
          fill="var(--v-primary-base)"
          :style="`filter: url(#vdp__market-value-chart__drop-shadow);`"
        />
        <text
          v-for="(point, index) in pricePoints"
          :key="'tooltip-text_' + index"
          :fill="index === 0 ? '#fff' : 'var(--v-grey-darken2)'"
          text-anchor="middle"
        >
          <tspan
            v-for="(text, lineIndex) in point.name"
            :key="'tooltip-text-line_' + lineIndex"
            :x="point.x"
            :y="point.y - 58 - (index === 0 ? 20 : 0) - (18 * (point.name.length - (lineIndex + 1)))"
            class="text-body-2 font-weight-medium"
            style="font-size: 13px;"
          >{{ text }}</tspan>
          <tspan
            :x="point.x"
            :y="point.y - 33 - (index === 0 ? 18 : 0)"
            class="text-body-1 font-weight-bold"
            :fill="index === 0 ? '#fff' : '#000'"
            :style="{ 'font-size': index === 0 ? '20px' : '16px' }"
          >{{ point.value | currency }}</tspan>
        </text>
      </g>
      <defs>
        <linearGradient
          id="vdp__market-value-chart__gradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop
            offset="0%"
            stop-color="var(--v-info-lighten2)"
          />
          <stop
            offset="98%"
            stop-color="#fff"
          />
        </linearGradient>
        <filter
          id="vdp__market-value-chart__drop-shadow"
          y="-200%"
          height="400%"
        >
          <feGaussianBlur
            in="SourceAlpha"
            stdDeviation="3"
          />
          <feOffset
            dx="2"
            dy="2"
            result="offsetblur"
          />
          <feComponentTransfer>
            <feFuncA
              type="linear"
              slope="0.2"
            />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
    </svg>
  </div>
</template>

<style lang="scss">
.vdp__market-value-chart {
  font-size: 0;
}
</style>

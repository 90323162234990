<script>
import ColorWell from '@maxsystems/ui/elements/ColorWell'
import SEO from '@maxsystems/ui/elements/SEO'
import Surface from '@maxsystems/ui/elements/Surface'
import { VehicleImg } from '@maxsystems/ui/patterns'
import VehicleHighlight from '@maxsystems/ui/patterns/VehicleHighlight'
import { enums as highlights } from '@maxsystems/ui/patterns/VehicleHighlight/Highlights'
import { number, currency } from '@maxsystems/ui/vue/filters'

import BtnFavorite from '../BtnFavorite'
import PopularEquipment from './PopularEquipment'
import SavingsBanner from './SavingsBanner'

export default {
  name: 'VehicleCard',
  filters: {
    currency,
    number
  },
  components: {
    BtnFavorite,
    ColorWell,
    PopularEquipment,
    SavingsBanner,
    SEO,
    Surface,
    VehicleHighlight,
    VehicleImg
  },
  props: {
    certified: {
      type: Boolean,
      default: false
    },
    color: {
      type: Object,
      default: () => ({
        description: '',
        generic: '',
        name: ''
      })
    },
    interiorColor: {
      type: Object,
      default: () => ({
        description: '',
        generic: '',
        name: ''
      })
    },
    condition: {
      type: String,
      default: ''
    },
    dealerName: {
      type: String,
      default: ''
    },
    distance: {
      type: [String, Number],
      default: 0
    },
    drivetrain: {
      type: String,
      default: null
    },
    engine: {
      type: String,
      default: null
    },
    inventory: {
      type: [Number, String],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    make: {
      type: String,
      default: null
    },
    marketAverage: {
      type: Number,
      default: 0
    },
    mileage: {
      type: [Number, String],
      default: 0
    },
    model: {
      type: String,
      default: null
    },
    msrp: {
      type: [Number, String],
      default: 0
    },
    photo: {
      type: String,
      default: null
    },
    price: {
      type: [Number, String],
      default: 0
    },
    savings: {
      type: Array,
      default: () => []
    },
    stock: {
      type: String,
      default: null
    },
    summary: {
      type: String,
      default: null
    },
    transmission: {
      type: String,
      default: null
    },
    trim: {
      type: String,
      default: null
    },
    upgrade: {
      type: Number,
      default: 0
    },
    vin: {
      type: String,
      default: null
    },
    year: {
      type: [Number, String],
      default: null
    },
    popularEquipment: {
      type: Array,
      default: () => []
    },
    highlights: {
      type: Array,
      default: () => [],
      validators: value => highlights.includes(value)
    }
  },
  computed: {
    showPayments: vm => vm.$store?.getters['opportunity/modules'].has('payments'),
    firstHighlight: vm => vm.highlights?.[0],
    hasLowMiles () {
      const currentYear = new Date().getFullYear()
      const vehicleYear = parseInt(this.year)
      const avgMilesPerYear = 12000

      return ((currentYear - vehicleYear) * avgMilesPerYear) > parseInt(this.mileage)
    },
    monthlyPayment () {
      if (!this.price || !this.showPayments) return
      const value = this.$store.getters['opportunity/payments/monthlyPayment'](this.price)
      return value ? this.$options.filters.currency(value) : ''
    },
    paymentSubtext: vm => vm.price > 0 && vm.monthlyPayment ? '/ mo est.' : 'Low to No Monthly Payment',
    schema () {
      return {
        '@context': 'https://schema.org/',
        '@type': 'Vehicle',
        brand: {
          '@type': 'Organization',
          name: this.make
        },
        color: this.color.name || this.color.generic,
        vehicleInteriorColor: this.interiorColor.name || this.interiorColor.generic,
        description: this.summary,
        driveWheelConfiguration: this.drivetrain,
        image: this.photo,
        mileageFromOdometer: {
          '@type': 'QuantitativeValue',
          unitCode: 'SMI',
          value: this.mileage
        },
        modelDate: this.year,
        name: [
          this.year,
          this.make,
          this.model,
          this.trim
        ].join(' '),
        offers: {
          '@type': 'Offer',
          availability: 'https://schema.org/InStock',
          itemCondition: 'https://schema.org/' + (this.condition === 'New' ? 'NewCondition' : 'UsedCondition'),
          priceCurrency: 'USD',
          price: this.price
        },
        vehicleEngine: {
          '@type': 'EngineSpecification',
          name: this.engine
        },
        vehicleTransmission: this.transmission,
        vehicleIdentificationNumber: this.vin
      }
    },
    showMSRP () {
      return this.condition === 'NEW' && this.msrp > this.price
    }
  }
}
</script>

<template>
  <Surface
    class="surface--vehicle"
    :aria-busy="loading"
    v-bind="$attrs"
    :ripple="false"
  >
    <SEO :schema="schema" />
    <VLayout
      class="surface--vehicle__header px-6 py-4"
    >
      <VFlex
        class="surface--vehicle__title"
        align-center
      >
        <VFlex
          align-baseline
        >
          <VSkeletonLoader
            :loading="loading"
            tile
            type="heading"
          >
            <h2 class="surface--vehicle__title__ymm">
              <span v-if="condition === 'NEW'">NEW</span>
              {{ year }} {{ make }} {{ model }}
            </h2>
            <h4
              v-if="trim"
              class="surface--vehicle__title__trim grey--text text--darken-2"
            >
              {{ trim }}
            </h4>
            <h6 class="surface--vehicle__title__mileage grey--text text--darken-2 text-body-2">
              {{ mileage | number }} mi
            </h6>
          </VSkeletonLoader>
        </VFlex>
      </VFlex>
      <VFlex
        class="surface--vehicle__actions shrink"
        text-right
      >
        <slot name="actions" />
      </VFlex>
    </VLayout>
    <VLayout
      wrap
    >
      <VFlex
        xs12
        md4
        class="surface--vehicle__photo-container"
      >
        <VSkeletonLoader
          :loading="loading"
          tile
          type="image"
        >
          <VehicleHighlight
            v-if="firstHighlight"
            class="surface--vehicle__highlight-badge"
            :highlight="firstHighlight"
            badge
          />
          <VehicleImg
            contain
            aspect-ratio="1.33"
            color="primary"
            :src="photo"
          />
          <BtnFavorite
            :inventory="inventory"
            :fab="true"
            absolute
            class="surface--vehicle__fav-button"
          />
        </VSkeletonLoader>
      </VFlex>
      <VFlex
        v-if="!loading"
        class="surface--vehicle__pricing"
        xs12
        md8
        column
      >
        <VLayout
          wrap
        >
          <VFlex
            v-if="savings && savings.length"
            :class="{'py-3': $vuetify.breakpoint.mdAndUp}"
          >
            <SavingsBanner :savings="savings" />
          </VFlex>
          <VFlex
            :class="{'py-0': $vuetify.breakpoint.smAndDown, 'py-4': !savings || savings.length < 1, 'py-3': $vuetify.breakpoint.mdAndUp}"
            class="pl-4 pr-6 surface--vehicle__pricing__price"
          >
            <h4 class="font-weight-bold">
              <template v-if="Math.round(price)">
                <span class="text-body-1 font-weight-bold">Price: $</span>{{ Math.round(price) | number }}
              </template>
              <template v-else>
                <span class="text-body-1 font-weight-bold">Price:</span> Not Available
              </template>
            </h4>
            <div
              v-if="showMSRP"
              class="text-body-2 grey--text text--darken-2"
            >
              MSRP:
              <del class="ml-1">
                {{ msrp | currency }}
              </del>
            </div>
            <div
              v-if="showPayments"
              class="text-body-2 grey--text text--darken-2"
            >
              {{ monthlyPayment }} {{ paymentSubtext }}
            </div>
          </VFlex>
          <VFlex
            v-if="upgrade > 0"
            class="xs12 pa-2 badge lighten-4 text-center"
          >
            <h6 class="text-body-2 badge--text text--darken-3 font-weight-bold">
              Includes  {{ upgrade | currency }} in Premium Upgrades
            </h6>
          </VFlex>
        </VLayout>
        <PopularEquipment
          :popular-equipment="popularEquipment"
          class="pl-4 pr-6 my-4"
        />
      </VFlex>
    </VLayout>
    <VLayout
      :column="$vuetify.breakpoint.smAndDown"
      :class="{'light': $vuetify.breakpoint.mdAndUp }"
    >
      <VFlex
        :class="{'pl-3 pr-6': $vuetify.breakpoint.smAndDown, 'pl-4 pr-4': $vuetify.breakpoint.mdAndUp}"
        class="surface--vehicle__location xs12 md4  py-4 text-body-2 grey--text text--darken-2"
      >
        <VSkeletonLoader
          :loading="loading"
          tile
          type="text"
        >
          <div v-if="color.name || color.generic">
            <ColorWell
              :color="color.description || color.generic"
              :size="12"
            />
            <span class="ml-1 mr-2">Exterior: {{ color.name || color.generic }}</span>
          </div>
          <div v-if="interiorColor.name || interiorColor.generic">
            <ColorWell
              v-if="interiorColor.description || interiorColor.generic"
              :color="interiorColor.description || interiorColor.generic"
              :size="12"
            />
            <span
              class="ml-1 mr-2"
              :class="{'ml-5': !(interiorColor.description || interiorColor.generic)}"
            >Interior: {{ interiorColor.name || interiorColor.generic }}</span>
          </div>
          <div>
            <VIcon small>
              $location
            </VIcon>
            <span
              v-if="dealerName"
              class="ml-1 mr-2"
            >{{ dealerName }}</span>
            <br>
            <span
              v-if="distance"
              :class="{'ml-4': $vuetify.breakpoint.smAndDown, 'ml-1': $vuetify.breakpoint.mdAndUp}"
            >
              {{ distance | number }} miles away
            </span>
          </div>
        </VSkeletonLoader>
      </VFlex>
      <VFlex
        class="surface--vehicle__summary md8 pl-4 pr-6 my-4 text-caption grey--text text--darken-2"
      >
        <VSkeletonLoader
          :loading="loading"
          tile
          type="paragraph"
        >
          <div class="surface--vehicle__summary__ad-text hidden-sm-and-down mb-3">
            {{ summary }}
          </div>
          <p>Stock #{{ stock }}</p>
        </VSkeletonLoader>
      </VFlex>
    </VLayout>
  </Surface>
</template>

<style lang="scss">
.surface--vehicle {
  &__header {
    border-bottom: 1px solid var(--surface-shadow);
  }
  &__title {
    &__ymm,
    &__trim,
    &__mileage {
      display: inline-block
    }

    &__ymm {
      @include typography('subtitle-1');
      font-weight: 700;
      margin-right: spacer(2);

      @include breakpoint('sm-and-up') {
        @include typography('h5');
        font-weight: 500;
        margin-right: spacer(4);
      }
    }

    &__trim {
      @include typography('subtitle-1');
      margin-right: spacer(2);

      @include breakpoint('sm-and-up') {
        @include typography('h6');
        margin-right: spacer(4);
      }
    }
  }
  &__photo-container {
    position: relative;
    align-self: flex-start
  }
  &__highlight-badge.vehicle-highlight {
    top: 10px;
    z-index: 1;
    position: absolute;
  }
  &__fav-button {
    bottom: -3px;
    right: 10px;
    @include breakpoint('md-and-up') {
      bottom: -13px;
    }
  }
  &__pricing {
    &__price {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__location {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    & .color-well {
      margin: 0 2px 0 2px;
      position: relative;
      top: -1px;
    }
  }
  &__summary {
    p {
      margin: 0;
    }
    &__ad-text {
      max-height: 60px;
      overflow: hidden;
    }
  }

  & sup {
    top: -0.3em;
    margin-right: 2px;
  }

  @include breakpoint('md-and-up') {
    &__title {
      display: flex;
      justify-content: left;
    }
  }

}
</style>

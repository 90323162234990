<script>
import { Icon } from '@maxsystems/ui/elements'
import steeringWheel from '@maxsystems/ui/icons/steering-wheel.svg'
import escapable from '@maxsystems/ui/mixins/escapable'

import ReservationForm from './DialogContent/Form'
import Success from './DialogContent/Success'

export default {
  components: {
    Icon,
    ReservationForm,
    Success
  },
  mixins: [
    escapable
  ],
  props: {
    inventory: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    show: false,
    dialogContent: 'form',
    form: {
      date: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null
    },
    icons: {
      steeringWheel
    }
  }),
  methods: {
    close () {
      this.show = false
    },
    onContentChange: function (value) {
      this.dialogContent = value
    },
    onEscPressed () {
      if (this.show) {
        this.show = false
      }
    },
    onSetForm: function (value) {
      this.form = value
    }
  }
}
</script>

<template>
  <VDialog
    v-model="show"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="1180"
    no-click-animation
    persistent
    transition="dialog-bottom-transition"
  >
    <template #activator="{ on }">
      <VBtn
        :ripple="false"
        block
        class="v-btn--favorite text-capitalize"
        color="primary"
        outlined
        @click.prevent="on.click"
      >
        <Icon
          class="mr-2"
          width="16"
          :src="icons.steeringWheel"
        />
        Test Drive
      </VBtn>
    </template>
    <ReservationForm
      v-if="dialogContent === 'form'"
      :inventory="inventory"
      @content-change="onContentChange"
      @set-form="onSetForm"
      @close-dialog="close"
    />
    <Success
      v-if="dialogContent === 'success'"
      v-bind="form"
      @content-change="onContentChange"
      @close-dialog="close"
    />
  </VDialog>
</template>

<script>
export default {
  inject: ['dataSource'],
  data: () => ({
    isFullscreen: false,
    photoIndex: 0,
    hasEscEvent: false
  }),
  computed: {
    bulletSlideOffset: function () {
      if (this.photoIndex > 4) {
        return `-${(this.photoIndex - 2) * 9}px`
      }
      return '0px'
    },
    leftBulletOffsetStyle: function () {
      if (this.photoIndex > 4) {
        return 'left: -8px'
      }
      return 'left: 0; opacity: 0'
    },
    rightBulletOffsetStyle: function () {
      if (this.photoIndex < this.dataSource.photos.length - 3) {
        return 'right: -8px'
      }
      return 'right: 0; opacity: 0'
    },
    currentPhoto: ({ dataSource: { photos }, photoIndex }) => photos[photoIndex] || {}
  },
  mounted () {
    window.addEventListener('keyup', this.onEscPressed)
  },
  destroyed () {
    window.removeEventListener('keyup', this.onEscPressed)
  },
  methods: {
    onEscPressed (event) {
      if (event.keyCode === 27 && this.isFullscreen) {
        this.isFullscreen = false
      }
    },
    displayMainPhoto (index) {
      // Photos cannot be selected while in mobile view.
      if (this.$vuetify.breakpoint.name === 'xs') return
      this.photoIndex = index
      this.updateScroll()
    },
    nextPhoto () {
      if (this.photoIndex === this.dataSource.photos.length - 1) return this.displayMainPhoto(0)
      this.displayMainPhoto(this.photoIndex + 1)
    },
    prevPhoto () {
      if (!this.photoIndex) return this.displayMainPhoto(this.dataSource.photos.length - 1)
      this.displayMainPhoto(this.photoIndex - 1)
    },
    toggleFullscreen () {
      this.isFullscreen = !this.isFullscreen
      this.$nextTick(() => this.updateScroll())
    },
    /**
     * Make sure that the active thumbnail is scrolled to view when the main
     * photo changes. This allows for the thumbnails to be scrolled independently
     * but prevents losing track of where you are if you click Prev or Next.
     */
    updateScroll () {
      if (!this.photoIndex) {
        this.$refs.thumbnails.scrollLeft = 0
        return
      }

      const selector = `.gallery__thumbs__thumbnail:nth-child(${this.photoIndex + 1})`
      const thumb = this.$refs.thumbnails.querySelector(selector)
      const offset = Math.floor(thumb.getBoundingClientRect().width + 8) * 2
      const left = thumb.offsetLeft - this.$refs.thumbnails.offsetLeft

      if (left > offset || this.$refs.thumbnails.scrollLeft < left - offset) {
        this.$refs.thumbnails.scrollLeft = left - offset
      }
    },
    onThumbnailScroll (event) {
      // When the user scrolls the photo (in mobile break point), update photoIndex accordingly
      if (this.$vuetify.breakpoint.name === 'xs') {
        this.photoIndex = parseInt(event.srcElement.scrollLeft / event.srcElement.clientWidth)
      }
    }
  }
}
</script>

<template>
  <section
    :key="_uid + isFullscreen"
    :class="['gallery', isFullscreen && 'gallery--fullscreen']"
  >
    <div class="gallery__wrapper">
      <div
        v-if="dataSource.photos.length > 1 && !isFullscreen"
        class="gallery__switch hidden-sm-and-up"
      >
        <button>
          <span class="switch__label">
            {{ photoIndex + 1 }} / {{ dataSource.photos.length }}
          </span>
        </button>
      </div>
      <VBtn
        v-if="isFullscreen"
        absolute
        icon
        dark
        class="gallery--fullscreen__close"
        @click="toggleFullscreen"
      >
        <VIcon>$close</VIcon>
      </VBtn>
      <VImg
        :key="currentPhoto.url"
        :aspect-ratio="1.408"
        :contain="isFullscreen"
        :src="currentPhoto.url"
        :lazy-src="currentPhoto.lazy"
        alt="Vehicle Photo"
        class="gallery__main"
        tabindex="0"
        @click="toggleFullscreen"
      >
        <template #placeholder>
          <VLayout
            fill-height
            align-center
            justify-center
            ma-0
          >
            <VProgressCircular
              indeterminate
              width="2"
              color="grey lighten-5"
            />
          </VLayout>
        </template>
        <div
          v-if="dataSource.photos.length > 1 && !isFullscreen"
          class="gallery__switch hidden-xs-only"
        >
          <button>
            <VIcon small>
              $photoLibrary
            </VIcon>
            <span class="switch__label">
              {{ photoIndex + 1 }} / {{ dataSource.photos.length }}
            </span>
          </button>
        </div>
        <button
          v-if="dataSource.photos.length > 1"
          class="gallery__nav--prev hidden-xs-only d-print-none"
          @click.stop="prevPhoto"
        >
          <VIcon color="white">
            $prev
          </VIcon>
        </button>
        <button
          v-if="dataSource.photos.length > 1"
          class="gallery__nav--next hidden-xs-only d-print-none"
          @click.stop="nextPhoto"
        >
          <VIcon color="white">
            $next
          </VIcon>
        </button>
      </VImg>
      <div
        ref="thumbnails"
        :style="{ '--thumb-length': dataSource.photos.length }"
        class="gallery__thumbs"
        @scroll="onThumbnailScroll"
      >
        <VImg
          v-for="(photo, index) in dataSource.photos"
          :key="index"
          :aspect-ratio="1.408"
          :class="['gallery__thumbs__thumbnail', index === photoIndex && 'gallery__thumbs__thumbnail--active']"
          :src="photo.thumb"
          :lazy-src="photo.lazy"
          @click="displayMainPhoto(index)"
        />
      </div>
      <div
        v-if="dataSource.photos.length > 1"
        class="bullets__block hidden-sm-and-up"
      >
        <div class="gallery__bullets">
          <div
            class="gallery__bullet bullet__offset bullet__offset-left"
            :style="leftBulletOffsetStyle"
          />
          <div class="bullets__container">
            <div
              class="bullets__slider"
              :style="{ transform: 'translateX(' + bulletSlideOffset + ')' }"
            >
              <button
                v-for="(photo, index) in dataSource.photos"
                :key="index"
                class="gallery__bullet"
                :class="{'gallery__bullet--active': photoIndex === index}"
                @click="displayMainPhoto(index)"
              />
            </div>
          </div>
          <div
            class="gallery__bullet bullet__offset bullet__offset-right"
            :style="rightBulletOffsetStyle"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.gallery {
  & .v-image {
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &--fullscreen .gallery__nav {
    &--prev,
    &--next {
      position: fixed !important;
    }
  }

  & .gallery__nav {
    &--prev,
    &--next {
      background: rgba(0, 0, 0, 0.5);
      display: inline-block;
      height: 72px;
      position: absolute;
      top: calc(50% - 36px);
      width: 36px;
    }

    &--prev {
      border-bottom-right-radius: 72px;
      border-top-right-radius: 72px;
      left: 0;
      text-align: left;
    }

    &--next {
      border-bottom-left-radius: 72px;
      border-top-left-radius: 72px;
      right: 0;
      text-align: right;
    }

    &--prev:focus,
    &--next:focus {
      outline: none;
    }
  }

  .gallery__wrapper {
    position: relative;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .gallery__switch {
    bottom: 12px;
    position: absolute;
    right: 12px;
    z-index: 1;

    & button {
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 4px;
      box-sizing: border-box;
      float: left;
      height: 26px;
      padding: 5px 8px;

      &:focus {
        outline: none;
      }

      & .v-icon {
        color: #ACB9BD;
        vertical-align: text-top;
      }
    }
  }

  .switch__label {
    color: #ACB9BD;
    font-size: 12px;
    line-height: 14px;
    vertical-align: text-top;
  }

  &__thumbs {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(var(--thumb-length), calc(20% - 6px));
    grid-template-rows: minmax(50px, 1fr);
    margin-top: 8px;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__thumbnail {
      opacity: 0.5;

      &--active {
        opacity: 1;
      }
    }
  }

  &--fullscreen {
    &__close {
      right: 12px;
      top: 12px;
    }

    & .gallery__wrapper {
      background: #000;
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 99;
    }

    & .gallery__main {
      height: calc(100vh - 156px);
      margin-top: 70px;
      width: 100vw;
    }

    & .gallery__thumbs {
      bottom: 8px;
      grid-template-columns: repeat(var(--thumb-length), 100px);
      grid-template-rows: 70px;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
    }
  }

  @include breakpoint('xs-only') {
    &__main {
      display: none;
    }

    & .v-image {
      cursor: auto;
    }

    &__thumbs {
      grid-template-columns: repeat(var(--thumb-length), 100%);
      margin-top: 0;
      scroll-snap-type: x mandatory;

      &__thumbnail {
        opacity: 1;
        scroll-snap-align: start;
      }
    }
  }

  .bullets__block {
    position: relative;
    top: -26px;
  }

  .gallery__bullets {
    left: calc(50% - 27px);
    position: absolute;
    z-index: 1;
  }

  .bullets__container {
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    width: 45px;
  }

  .bullets__slider {
    transition: transform 0.5s ease;
  }

  .gallery__bullet {
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    margin-right: 3px;
    opacity: 0.5;
    width: 6px;
  }

  .bullet__offset {
    height: 4px;
    position: absolute;
    top: 10px;
    transition:
      left 0.5s ease,
      right 0.5s ease,
      opacity 0.5s ease;
    width: 4px;
  }

  .gallery__bullet--active {
    opacity: 1;
  }
}

:-ms-fullscreen,
:root .gallery__thumbs {
  display: inline-block;
  height: 70px;
  -ms-overflow-style: none;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;

  .gallery__thumbs__thumbnail {
    display: inline-block;
    height: 70px;
    width: 105px;
  }
}

@media print {
  .gallery__thumbs__thumbnail {
    opacity: 1;
  }

  .gallery__bullets,
  .gallery__switch {
    display: none;
  }
}
</style>

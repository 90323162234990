<script>
import { mapState } from 'vuex'

import geo from '@maxsystems/ui/api/geo'
import { Surface } from '@maxsystems/ui/elements'
import pluggable from '@maxsystems/ui/mixins/pluggable'
import { atob as decode, btoa as encode } from '@maxsystems/ui/vue/filters'

import '../plugins'

import RouteParameters from '../mixins/RouteParameters'
import Context from './Context'
import Filters from './Filters'
import FiltersMobile from './FiltersMobile'
import Freeform from './Freeform'
import Results from './Results'

export default {
  components: {
    Context,
    Filters,
    Freeform,
    Results,
    Surface,
    FiltersMobile
  },
  mixins: [
    RouteParameters,
    pluggable('srp')
  ],
  props: {
    condition: {
      type: [Array, String],
      default: () => []
    },
    instance: {
      type: String,
      default: null
    },
    // Limits search results to be from one or more Dealer or Dealer Group.
    // Without a `scope` set, the SRP will display all results in Algolia.
    scope: {
      type: [Array, String],
      default: null
    },
    // Link each result card to a named route within the application. Typically
    // this is the VDP, but the named route must support an `inventory` prop.
    to: {
      type: String,
      default: null
    }
  },
  data: () => ({ showFilters: false }),
  computed: {
    ...mapState('search', ['resultCount']),
    filterWrapper () {
      return this.$options.components.NoSsr ? 'no-ssr' : 'div'
    },
    queryFilters () {
      const { filters, locationFilters, meta, numericFilters, query } = this.$store.state.search
      return { filters, locationFilters, meta, numericFilters, query }
    }
  },
  watch: {
    scope (newValue) {
      this.setScope()
      this.$store.dispatch('search/search')
    },
    queryFilters (data) {
      const searchParams = this.$route.query
      // remove q from query
      delete searchParams.q
      // set q query param if there are active filters
      if (data.filters.length || data.locationFilters.distance > 0 || data.numericFilters.length || data.query) {
        searchParams.q = encode(JSON.stringify(data))
      }
      // build new path
      let newRelativePathQuery = this.$route.path
      // only add query params if they exist
      // this will run for any query param, not just q, preserving any other query params
      if (Object.keys(searchParams).length) {
        newRelativePathQuery += '?' +
          Object.keys(searchParams)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(searchParams[key]))
            .join('&')
      }
      // update URL without adding to history
      history.replaceState(null, '', newRelativePathQuery)
    }
  },
  async beforeMount () {
    await this.applyFilters()
    if (this.filtersPending) {
      this.$store.commit('search/clearResults')
    }

    if (this.resultCount && !this.filtersPending) return
    await this.$store.dispatch('search/search')
    this.filtersPending = false
  },
  mounted () {
    this.getLocation()
  },
  /**
   * This function is only called when the SRP is used in an SSR environment.
   * It allows for the search results to be loaded before the request is fulfilled
   * to ensure that metadata is available for scraping without requiring JS.
   */
  async serverPrefetch () {
    await this.applyFilters()
    await this.$store.dispatch('search/search')
  },
  methods: {
    isOwnerHandle (string) {
      // Owner Handle (ex. 0C1B63CA-F1A7-E011-BBB1-001B219B7C2C) is a legacy construct
      // that we are phasing out to exclusively use Business Unit ID / BUIDs (ex. 105239)
      return string && string.length === 36
    },
    setScope () {
      let ownerHandles = []
      let BUIDs = []

      if (this.scope) {
        [BUIDs, ownerHandles] = [].concat(this.scope).reduce((result, id) => {
          result[Number(this.isOwnerHandle(id))].push(id)
          return result
        }, [[], []])
      }

      if (BUIDs.length && BUIDs.toString() !== this.$store.state.search.baseFilters.dealerId?.value.toString()) {
        this.filtersPending = true
      }

      this.$store.commit('search/setBaseFilter', { name: 'dealer', value: ownerHandles })
      this.$store.commit('search/setBaseFilter', { name: 'dealerId', value: BUIDs, orWith: 'dealer' })
    },
    async applyFilters () {
      const { q } = this.$route.query

      if (this.instance) this.$store.dispatch('search/setInstance', this.instance)
      this.setScope()
      this.$store.commit('search/setBaseFilter', { name: 'condition.lvl0', value: this.condition })
      if (!(await this.decodeFilters(q))) await this.parseAndApplyRouteParameters()
    },
    async decodeFilters (q) {
      if (!q) return
      try {
        const filters = JSON.parse(decode(q))
        await this.$store.dispatch('search/getFacets')
        this.$store.dispatch('search/setAllFilters', filters)
        return true
      } catch (e) {
        // pass -- atob or JSON.parse failed cause q is invalid
      }
      return false
    },
    async getLocation () {
      const { latlng } = this.$store.state.search.locationFilters
      const result = await geo.get(latlng)
      if (result.error) {
        return
      }

      this.$store.commit('search/setMeta', {
        plugin: 'location',
        value: {
          city: result.city,
          zip: result.zip
        }
      })

      // If the user location has already been entered, we're just updating the
      // city and zip code, there's no reason to trigger another search.
      if (latlng === result.latlng) return
      this.$store.commit('search/setLocationFilter', ['latlng', result.latlng])
      this.$store.dispatch('search/search')
    },
    toggleVisible () {
      this.showFilters = !this.showFilters
    }
  }
}
</script>

<template>
  <section>
    <Surface
      flat
      sharp
      class="srp__header px-6 py-4"
    >
      <Freeform />
      <Context
        class="mt-4 hidden-sm-and-down"
      />
    </Surface>
    <VLayout column>
      <FiltersMobile
        v-model="showFilters"
        class="hidden-md-and-up"
      />
      <VLayout>
        <VFlex class="srp__filters xs4 align-start">
          <component :is="filterWrapper">
            <Filters v-model="showFilters" />
          </component>
        </VFlex>
        <VFlex
          class="mb-6 srp__results"
        >
          <Results :to="to" />
        </VFlex>
      </VLayout>
    </VLayout>
  </section>
</template>

<style lang="scss">
.srp {
  &__header {
    overflow: visible !important;
  }

  &__filters {
    background: #FFF;

    @include breakpoint('md-and-up') {
      max-width: 375px !important;
    }

    @include breakpoint('sm-and-down') {
      position: absolute;
    }
  }

  &__results {
    flex-basis: 66.66%;
  }
}
</style>

<script>
import { Icon } from '@maxsystems/ui/elements'
import { getSegmentIcon } from '@maxsystems/ui/icons/segment'

import { SelectListSorted } from '../patterns'

export default {
  config: {
    title: 'Body Style',
    weight: 1,
    facet: 'segment'
  },
  components: {
    SelectListSorted,
    Icon
  },
  methods: {
    getSegmentIcon
  }
}
</script>

<template>
  <SelectListSorted
    :facet="$options.config.facet"
    order="popularity"
  >
    <template #icon="{ value }">
      <Icon :src="getSegmentIcon(value)" />
    </template>
  </SelectListSorted>
</template>

<style lang="scss">
.srp__filter--segment {
  .select-list-row svg:first-of-type {
    height: 60px;
    width: 60px;
  }
}
</style>

import dot from 'dot-prop'
import Vue from 'vue'
import Vuex from 'vuex'

import { algolia } from '../api'

Vue.use(Vuex)

const algoliaIndex = sort => [process.env.ALGOLIA_INVENTORY, sort].filter(Boolean).join('_')

/**
 * @see https://vuex.vuejs.org/guide/state.html
 */
const state = () => ({
  $loading: false,
  baseFilters: {},
  facets: {},
  facetsStats: {},
  filters: [],
  index: algoliaIndex(),
  instance: null,
  locationFilters: {
    distance: 0,
    latlng: ''
  },
  meta: {},
  numericFilters: [],
  pendingRequests: 0,
  pagination: {
    hitsPerPage: 20,
    page: 0,
    pageTotal: 1
  },
  pathParametersApplied: '',
  query: '',
  resultCount: 0,
  results: [],
  staticFacets: {}
})

/**
 * @see https://vuex.vuejs.org/guide/getters.html
 */
const getters = {
  allFilters: (state, getters) =>
    [].concat(getters.filters, state.numericFilters),
  facet: (state, getters) => facetName => ({
    available: Object.keys(state.staticFacets[facetName] || []),
    selected: (getters.allFilters.find(x => x.name === facetName) || {}).value || [],
    stats: state.facetsStats[facetName] || {}
  }),
  filter: (state, getters) => filterName => (getters.allFilters.find(x => x.name === filterName) || {}).value,
  filters: (state, getters) => {
    const userFilters = state.filters.map(y => y.name)
    return [].concat(
      Object.keys(state.baseFilters).filter(x => !userFilters.includes(x)).map(name => ({
        name,
        ...state.baseFilters[name]
      })),
      state.filters
    )
  },
  /**
   * Return a hierarchical facet in structured form. This augments the standard
   * `facet` getter by including the parent/child relationship as well as hints
   * to category selection.
   * @param {String} facetName - The hierarchical facet to return. This will
   * always start at `lvl0` regardless of whether a suffix is passed.
   */
  hierarchicalFacet: (state, getters) => facetName => {
    const [LVL0, LVL1] = ['lvl0', 'lvl1'].map(lvl => [facetName, lvl].join('.'))

    if (facetName.includes('.lvl')) facetName = facetName.split('.')[0]
    const categories = getters.facet(LVL0)
    if (!categories) return {}

    const items = getters.facet(LVL1)
    categories.selected = categories.selected.concat(items.selected).filter(Boolean)
    categories.available = categories.available.reduce((result, name) => {
      const selected = categories.selected.filter(v => v.includes(name))
      result[name] = { name }
      result[name].count = state.staticFacets[LVL0][name]
      result[name].items = items.available.filter(lvl1 => lvl1.includes(name)).map(name => ({
        count: state.staticFacets[LVL1][name],
        name,
        label: name.split(' > ').pop()
      }))

      // The category is selected if all of it's children are selected or if the
      // parent `lvl0` filter has been selected.
      result[name].selected = selected.includes(name) ||
        (result[name].items.length > 0 && selected.length === result[name].items.length)

      // If some but not all of the children are selected, the category can be
      // represented in an "indeterminate" state.
      result[name].indeterminate = !result[name].selected && selected.length > 0 && selected.length < result[name].items.length
      return result
    }, {})
    return categories
  },
  userFilters: state => [].concat(state.filters, state.numericFilters).filter(({ name }) => name !== 'dealer'),
  userFilter: (state, getters) => filterName => (getters.userFilters.find(x => x.name === filterName) || {}).value,
  filterCount: (state, getters) => facetName => {
    if (facetName && facetName.includes('.lvl')) {
      const filters = getters.userFilter(facetName)
      return filters ? filters.length || 1 : 0
    }
    // deal with heirarchy parents
    return getters.userFilters.reduce((result, { name, value }) => {
      const count = Array.isArray(value) ? value.length : 1
      return name.includes(facetName) ? result + count : result
    }, 0)
  },
  filterCountTotal: (state, getters) => // Excludes baseFilters from the count
    getters.userFilters.reduce((total, { value, name }) =>
      Array.isArray(value) ? total + value.length : total + 1, 0),
  locationFiltersDistance: state =>
    state.locationFilters ? state.locationFilters.distance : 0,
  meta: state => plugin => state.meta[plugin] || {}
}

/**
 * @see https://vuex.vuejs.org/guide/actions.html
 */
const actions = {
  addFilter: ({ commit, dispatch }, data) => {
    const name = data[0]
    const value = data[1]
    commit('addFilter', [name, value])
    dispatch('search', [name])
  },

  /**
   * Get all facets with the specified parameters. Performs a search but does
   * not populate the results. This can be used to populate an initial list of
   * facets for the UI before filtering and showing results.
   */
  async getFacets ({ commit, dispatch, state }, params = {}) {
    const results = await dispatch('getResults', params)
    dispatch('setFacets', results)
  },

  /**
   * Takes facet name, query and filters in an object
   *
   * Returns promise which resolves to array of facet values
   */
  searchFacet ({ state }, { name, query, filters }) {
    return algolia.searchFacet({ index: state.index, name, query, filters })
  },

  /**
   * Wrapper for the initial Algolia query. You should probably not call this
   * directly unless you know what you are doing. Use `dispatch('getFacets')` or
   * `dispatch('search')` to both fire the query and update state.
   */
  getResults ({ getters, state }, params = {}) {
    const page = params.page || 0
    const hitsPerPage = params.hitsPerPage || 20
    const { locationFilters, numericFilters, query, index, instance } = state
    return algolia.vehicle({
      params: { locationFilters, filters: getters.filters, numericFilters, index, page, hitsPerPage, instance },
      query
    })
  },
  /**
   * Remove one or many filters from either state.filters, state.query, state.locationFilters
   * or state.numericFilters
   * @param {Object} data - object containing data about the filter to remove
   * @param {String} data.type - type of filter to remove
   * @param {Array} [data.value] - for state.filters only. The current value
   *  in this filter
   * @param {String} [data.filterValue] - for state.filters only since it can have
   *  multiple values. value to remove in the named filters
   * @param {Array} [data.removeAll] - for state.filters only. when true: remove all filters
   * matching filterValue
   */
  removeFilter: ({ commit, dispatch }, { type, name, value, filterValue, removeAll }) => {
    if (type === 'query') {
      commit('setQuery', '')
      dispatch('search')
      return
    }

    if (type === 'location') {
      commit('setLocationFilter', ['distance', 0])
      dispatch('search')
      return
    }

    const remaining = removeAll
      ? value.filter(x => !x.startsWith(filterValue))
      : value.filter(x => x !== filterValue)

    // If this is the last filter, use the `removeFilter` mutation which will
    // clean up any orphaned heirarchical parents.
    if (type === 'numeric' || remaining.length === 0) {
      commit('removeFilter', name)
      dispatch('search')
      return
    }

    // If more than one value is selected, keep all other filter values.
    dispatch('replaceFilter', {
      name,
      value: remaining
    })
  },
  replaceFilter: ({ commit, dispatch }, params) => {
    const name = params.name
    const value = params.value
    commit('replaceFilter', [name, value])
    dispatch('search')
  },
  reset: ({ commit, dispatch }) => {
    commit('clearFilters')
    dispatch('search')
  },
  search: async ({ commit, dispatch, state, getters }, params = {}) => {
    commit('setLoading', true)
    const results = await dispatch('getResults', params)

    commit('setResults', results.hits)
    commit('setResultCount', results.nbHits)
    commit('setPagination', [results.page, results.hitsPerPage, results.nbPages])

    if (!results.facets) {
      commit('setLoading', false)
      return
    }

    dispatch('setFacets', results)
    commit('setLoading', false)
  },
  setAllFilters: ({ commit, dispatch, state }, allFilters) => {
    const { filters, locationFilters, meta, numericFilters, query } = allFilters
    // handle filters (make, model, etc.)
    filters.forEach(({ name, value }) => commit('replaceFilter', [name, value]))
    // handle numeric filters (mileage, price, etc.)
    numericFilters.forEach(({ name, value }) => commit('replaceNumericFilter', [name, value]))
    // handle location
    commit('setLocationFilter', ['distance', locationFilters.distance])
    commit('setLocationFilter', ['latlng', locationFilters.latlng])
    // handle query
    commit('setQuery', query)
    // handle meta
    Object.keys(meta).forEach(plugin => commit('setMeta', { plugin, value: meta[plugin] }))
  },

  /**
   * Update the facets based on the latest search results returned.
   * @param {Object} results - Response from Algolia.
   */
  setFacets ({ commit, state }, results) {
    let facets = Object.entries(results.facets).map(x => ({
      name: x[0],
      value: Object.entries(x[1]).map(y => ({
        name: y[0],
        count: y[1]
      }))
    }))

    // Only load facetStats once (per page load) to set the range limit of slider
    // (Do not change the range when the data updates)
    if (Object.keys(state.facetsStats).length === 0 && results.facets_stats) {
      commit('setFacetsStats', results.facets_stats)
      commit('setStaticFacets', results.facets)
    }

    // If any facet names (its related hierarchy) are active with defined values,
    // do not update the facet values for that field.
    const activeFilters = state.filters.filter(f => {
      if (f.name.indexOf('.lvl')) {
        const base = f.name.substr(0, f.name.indexOf('.lvl'))
        const siblings = state.filters.filter(l => l.name.indexOf(base) > -1 && l.value && l.value.length)
        return siblings.length
      }
      return (f.value && f.value.length)
    }).map(({ name }) => name)

    facets = facets.filter(x => !activeFilters.includes(x.name))
    commit('setFacets', facets)
  },

  setInstance ({ commit }, value) {
    commit('setInstance', value)
  }
}

/**
 * @see https://vuex.vuejs.org/guide/mutations.html
 */
const mutations = {
  setLoading (state, intention) {
    if (intention) {
      state.pendingRequests++
      state.$loading = true
      return
    }

    state.pendingRequests--
    if (state.pendingRequests >= 1) return
    state.$loading = false
  },
  addFilter (state, data) {
    const name = data[0]
    const value = data[1]
    const filter = state.filters.find(x => x.name === name)
    if (filter) {
      filter.value.push(value)
      return
    }
    state.filters.push({
      name: name,
      value: Array.isArray(value) ? value : [value]
    })
  },
  setQuery (state, data) {
    state.query = data || ''
  },
  replaceFilter (state, [name, value, { orWith = null, operator = null } = {}]) {
    state.filters = state.filters.filter(x => x.name !== name)

    if (!value || !(value.length || state.filters.some(({ orWith }) => orWith === name))) return

    state.filters.push({
      name: name,
      value: Array.isArray(value) ? value : [value],
      orWith,
      operator
    })
  },
  replaceNumericFilter (state, [name, value]) {
    state.numericFilters = state.numericFilters.filter(x => x.name !== name)
    state.numericFilters.push({ name, value })
  },

  /**
   * Remove a filter entirely. This applies to both standard `filters` and the
   * special `numericFilters` that may be set on the Algolia query.
   * @param {String} filterName - The name of the filter to remove.
   */
  removeFilter (state, filterName) {
    state.filters = state.filters.filter(({ name, value, orWith }) =>
      name !== filterName && (value.length && orWith !== filterName))
    state.numericFilters = state.numericFilters.filter(x => x.name !== filterName)
  },

  /**
   * Clear all filters from state. This does not re-fire the search call. You
   * will need to either fire this yourself if calling this directly, or just
   * use `dispatch('reset')` instead.
   */
  clearFilters (state) {
    // remove all filters except 'dealer' because it is for the dealer scope
    // that should not mutate as a result of user's action.
    // Users filter dealer using 'dealerName' rather than 'dealer'
    const dealerFilter = state.filters.find(x => x.name === 'dealer')
    state.filters = dealerFilter ? [dealerFilter] : []
    state.meta = {}
    state.locationFilters.distance = 0
    state.numericFilters = []
    state.query = ''
  },

  /**
   * Clear all search results and number of results. This is expected to be
   * called before a `search` is dispatched in situations like scope changing
   * where you don't want to display previous results while a search is pending.
   */
  clearResults (state) {
    state.resultCount = 0
    state.results = []
  },

  /**
   * Base filters do not show up in the UI and cannot be changed by a user. They
   * are used to scope the SRP to a subset of results without affecting filter
   * behaviour. Common examples are limiting the results to inventory within a
   * specific group or a certain OEM.
   * @param {Object} filter
   */
  setBaseFilter (state, filter) {
    const { name, value, orWith, operator } = filter

    if (!value || !value.length) {
      return Vue.delete(state.baseFilters, name)
    }

    Vue.set(state.baseFilters, name, {
      value: Array.isArray(value) ? value : [value],
      orWith,
      operator
    })
  },

  /**
   * Plugins may need to store additional metadata outside of filters that can
   * affect the UI state. This should only be used for information that will be
   * encoded into a saved search. Other state should be kept in the component.
   * @param {Object} meta
   * @param {String} meta.plugin - The plugin namespace where this data can be retrieved.
   * @param {Object|Any} meta.value - Values to be stored, typically an Object.
   */
  setMeta (state, { plugin, value }) {
    Vue.set(state.meta, plugin, value)
  },

  setInstance (state, data) {
    state.instance = data
  },
  setFacets (state, data) {
    // replace existing facets with facets from Algolia response
    const existingFacets = {}
    Object.keys(state.facets).forEach((currentFacet, index) => {
      const facet = data.find(x => x.name === currentFacet)
      existingFacets[currentFacet] = facet ? facet.value : state.facets[currentFacet]
    })

    // concat new facets from Algolia response with existing facets
    const newFacets = data.filter(x => !Object.keys(state.facets).map(y => y).includes(x.name)).reduce(
      (accumulator, obj) => {
        accumulator[obj.name] = obj.value
        return accumulator
      }, {}
    )

    state.facets = { ...existingFacets, ...newFacets }
  },
  setFacetsStats (state, data) {
    state.facetsStats = data
  },
  setStaticFacets (state, data) {
    state.staticFacets = data
  },
  setState (state, data) {
    const { name, value } = data
    dot.set(state, name, value)
  },
  setLocationFilter (state, data) {
    const [filter, value] = data
    dot.set(state.locationFilters, filter, value)
  },
  setPagination (state, [page, hitsPerPage, pageTotal]) {
    state.pagination = {
      hitsPerPage,
      page,
      pageTotal
    }
  },
  setResults (state, data) {
    state.results = data
  },
  setResultCount (state, data) {
    state.resultCount = data
  },
  setIndex (state, value) {
    state.index = algoliaIndex(value)
  }
}

/**
 * @see https://vuex.vuejs.org/guide/modules.html
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<script>
import { Surface, Triptych } from '@maxsystems/ui/elements'

import PopularEquipment from '~/components/VehicleCard/PopularEquipment'

export default {
  name: 'VehicleEquipment',
  components: {
    PopularEquipment,
    Surface,
    Triptych
  },
  inject: ['dataSource'],
  data: () => ({
    expanded: false
  }),
  computed: {
    equipment: vm => vm.dataSource.vehicle.equipment,
    /**
     * Display the popular equipment names. All names that have isPopular set the true.
     * @return {Array}
     */
    popularEquipment () {
      return this.equipment.filter(({ isPopular }) => isPopular).map(({ name }) => name)
    },
    /**
     * Display all equipment in simple bulleted form. By default, only the first
     * 13 items are shown. By setting the `expanded` property to `true`, all the
     * equipment will be displayed.
     * @return {Array}
     */
    allEquipment () {
      if (this.expanded) return this.equipment
      return this.equipment.slice(0, 13)
    }
  }
}
</script>
<template>
  <Surface
    v-if="equipment.length"
    class="surface--equipment"
  >
    <VLayout
      class="surface--equipment__header align-center px-6 py-4"
    >
      <VFlex>
        <h5 class="text-subtitle-1 font-weight-bold mr-4">
          Equipment
        </h5>
      </VFlex>
    </VLayout>
    <VLayout
      v-if="popularEquipment.length"
      column
      class="px-6 py-4"
    >
      <h6 class="text-body-2 pb-2">
        Popular Equipment
      </h6>
      <PopularEquipment
        :popular-equipment="popularEquipment"
        class="text-caption text--darken-3"
      />
    </VLayout>
    <VDivider class="mx-6" />
    <VLayout
      v-if="allEquipment.length"
      column
      class="px-6 pt-4 pb-6"
    >
      <h6 class="text-body-2 pb-2">
        All Equipment
      </h6>
      <Triptych
        :columns="2"
        :value="allEquipment"
        tag="ul"
        class="surface--equipment__all"
      >
        <template #default="{ _uid, item }">
          <li
            :key="_uid"
            v-bind="item"
            class="text-caption grey--text text--darken-3"
          >
            {{ item.name }}
          </li>
        </template>

        <button
          v-if="!expanded"
          slot="append"
          class="info--text text-caption font-weight-medium d-print-none"
          @click="expanded = true"
        >
          plus more...
        </button>
      </Triptych>
    </VLayout>
  </Surface>
</template>

<style lang="scss">
.surface--equipment {
  &__header {
    border-bottom: 1px solid var(--surface-shadow);

    & h5 {
      text-transform: uppercase;
    }
  }

  &__match {
    & small {
      font-weight: normal;
      opacity: 0.8;
    }
  }

  &__all {
    & ul {
      list-style: none;
      padding-right: 8px;
      position: relative;

      &:last-child {
        padding-right: 0;
      }
    }

    & li {
      line-height: 21px;

      &::before {
        color: var(--v-grey-base);
        content: "\2022";
        display: inline-block;
        left: 4px;
        position: absolute;
      }
    }
  }

  & hr.v-divider {
    border-color: var(--surface-shadow);
  }
}
</style>

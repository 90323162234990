<script>
import { Icon, Surface } from '@maxsystems/ui/elements'
import { currency } from '@maxsystems/ui/vue/filters'

import {
  CarCheck
} from './icons'
import ServiceUpgrade from './ServiceUpgrade'

export const DEFAULT_VISIBLE_UPGRADES = 5

export default {
  name: 'ServiceUpgrades',
  components: {
    Icon,
    Surface,
    ServiceUpgrade
  },
  filters: {
    currency
  },
  inject: ['dataSource'],
  data: () => ({
    CarCheck,
    DEFAULT_VISIBLE_UPGRADES,
    showAll: false
  }),
  computed: {
    repairOrders: vm => vm.dataSource.inventory.repairOrders
  }
}
</script>

<template>
  <Surface
    v-if="repairOrders"
    :class="['surface--service-upgrades', {'surface--service-upgrades--expanded': showAll}]"
  >
    <div class="primary white--text px-6 py-4 d-flex align-center">
      <Icon
        :src="CarCheck"
        width="31"
      />
      <div class="pl-5">
        <div class="text-h6">
          {{ repairOrders.name || 'Service Completed' }}
        </div>
        <div class="body-2">
          <strong>+ {{ repairOrders.value.amount | currency }}</strong>
          total added value
        </div>
      </div>
    </div>
    <section>
      <ServiceUpgrade
        v-for="upgrade in repairOrders.items.slice(0, DEFAULT_VISIBLE_UPGRADES)"
        :key="upgrade.name"
        v-bind="upgrade"
      />
    </section>
    <VExpandTransition>
      <section v-if="showAll">
        <ServiceUpgrade
          v-for="upgrade in repairOrders.items.slice(DEFAULT_VISIBLE_UPGRADES)"
          :key="upgrade.name"
          v-bind="upgrade"
        />
      </section>
    </VExpandTransition>
    <div class="d-flex justify-center pa-4">
      <VBtn
        v-if="repairOrders.items.length > DEFAULT_VISIBLE_UPGRADES"
        class="body-1 font-weight-medium text--darken-2"
        text
        color="secondary"
        @click="showAll = !showAll"
      >
        {{ showAll ? 'Show Less' : `Show All (${repairOrders.items.length})` }}
      </VBtn>
    </div>
  </Surface>
</template>

<style lang="scss">
.surface--service-upgrades {
  .surface--service-upgrades__upgrade__content {
    border-bottom: 1px solid var(--v-grey-lighten2);
  }

  section:last-of-type > div:last-of-type {
    .surface--service-upgrades__upgrade__content {
      border-bottom: none;
    }
  }
}
</style>

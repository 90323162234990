<script>
import { mapGetters } from 'vuex'

import { mapFlags } from '@maxsystems/ui/utils/features'

import EmptyState from '../../components/EmptyState'
import ReservationDialog from '../../components/Reservation/ReservationDialog'
import ShareButton from '../../components/ShareButton'
import icon from './icon.svg'
import VehicleCard from '~/components/VehicleCard'

export default {
  name: 'BoardFavorites',
  components: {
    EmptyState,
    ReservationDialog,
    ShareButton,
    VehicleCard
  },
  data () {
    return {
      icon,
      isLoading: true
    }
  },
  computed: {
    ...mapFlags(['appointments']),
    ...mapGetters('opportunity', ['pending', 'board/favorites']),
    favorites: ({ isLoading: loading, 'board/favorites': vehicles }) => loading
      ? [{ loading }]
      : vehicles
  },
  async created () {
    this.isLoading = await this.pending.then(() => false)
  }
}
</script>

<template>
  <section class="customer-board__content--favorites">
    <slot name="title" />
    <EmptyState
      v-if="!favorites.length"
      :icon="icon"
      title="No Cars Saved"
      description="Search our inventory and build a list of vehicles you're interested in test driving"
    />
    <div class="text-center mt-2 hidden-sm-and-up">
      <ShareButton />
    </div>

    <VehicleCard
      v-for="vehicle in favorites"
      :key="vehicle.inventory"
      :to="!$store.state.opportunity.viewOnly ? { name: 'vdp', params: { inventory: vehicle.inventory } } : null"
      class="mt-4"
      v-bind="vehicle"
    >
      <template #actions>
        <ReservationDialog
          v-if="!$store.state.opportunity.viewOnly && appointments && !isLoading"
          :inventory="vehicle.inventory"
        />
      </template>
    </VehicleCard>
  </section>
</template>

<style lang="scss">
.customer-board__content--favorites {
  & .surface--vehicle__actions {
    display: flex;

    & .v-btn--block {
      flex: none;
      margin-left: auto;
      width: auto;
    }
  }
}
</style>

import { RangeDropdown } from '../../patterns'

export default {
  config: {
    title: 'Year',
    weight: 13,
    facet: 'year'
  },
  render (h) {
    return h(RangeDropdown, { props: { facet: this.$options.config.facet } })
  }
}

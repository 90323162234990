import { SelectListSorted } from '../patterns'

export default {
  config: {
    title: 'Dealership',
    facet: 'dealerName',
    weight: 70,
    require: {
      baseFilters: {
        // Show Location filter if: 1) the SRP is not scoped, or 2) the SRP is scoped to more than 1 dealer
        dealerId: (f, baseFilters) => (f.value?.length || 0) + (baseFilters?.dealer?.value?.length || 0) !== 1
      }
    }
  },
  render (h) {
    return h(SelectListSorted, { props: { facet: this.$options.config.facet } })
  }
}

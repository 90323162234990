import { btoa as encode } from '@maxsystems/ui/vue/filters'

export default {
  props: {
    hostname: {
      type: String,
      default: undefined
    }
  },
  computed: {
    context: vm => vm.$store.getters['opportunity/context'],
    encoded: vm => encode(vm.context.opportunity),
    host: vm => vm.hostname || vm.context.platform || window.location.hostname
  }
}

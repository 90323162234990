<script>
import { Icon } from '@maxsystems/ui/elements'
import { getEquipmentIcon } from '@maxsystems/ui/icons/equipment'

import { SelectListSorted } from '../patterns'

export default {
  config: {
    title: 'Popular Features',
    weight: 20,
    facet: 'popularEquipment'
  },
  components: {
    SelectListSorted,
    Icon
  },
  methods: {
    getEquipmentIcon
  }
}
</script>

<template>
  <SelectListSorted
    :facet="$options.config.facet"
    order="popularity"
    operator="AND"
  >
    <template #icon="{ value }">
      <Icon :src="getEquipmentIcon(value)" />
    </template>
  </SelectListSorted>
</template>

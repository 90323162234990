<script>
import { mapGetters } from 'vuex'

import { currency } from '@maxsystems/ui/vue/filters'

import states from '~/plugins/payments/store/states'

export default {
  name: 'EstimatedFees',
  filters: {
    currency
  },
  data: () => ({
    states: Object.keys(states)
  }),
  computed: {
    state: {
      get () {
        return this.$store.state.opportunity.payments.fees.selectedState
      },
      set (selectedState) {
        this.$store.commit('opportunity/payments/setFees', { selectedState })
      }
    },
    included: {
      get () {
        return this.$store.state.opportunity.payments.fees.includeInPrice
      },
      set (includeInPrice) {
        this.$store.commit('opportunity/payments/setFees', { includeInPrice })
      }
    },
    ...mapGetters('opportunity/payments', ['assumptions', 'fees'])
  }
}
</script>

<template>
  <VLayout align-center>
    <VFlex xs6>
      <VCheckbox
        v-model="included"
        class="justify-end pr-4"
        label="Add estimated tax, title, tags, and fees:"
      />
    </VFlex>
    <VFlex class="pb-1 xs3">
      <VSelect
        v-model="state"
        flat
        hide-details
        solo
        :items="states"
      />
    </VFlex>
    <VFlex
      v-if="fees.total"
      class="pb-1 xs3"
    >
      {{ fees.estimated(assumptions.vehiclePrice) | currency }}
    </VFlex>
  </VLayout>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    facet: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('search', ['filterCountTotal']),
    count () {
      if (!this.facet) return 0
      if (this.facet === 'all') return this.filterCountTotal
      return this.$store.getters['search/filterCount'](this.facet)
    }
  }
}
</script>

<template>
  <VBadge
    v-if="count > 0"
    class="srp__filter-count font-weight-regular"
    color="primary"
    inline
  >
    <template #badge>
      {{ count }}
    </template>
  </VBadge>
</template>

<style lang="scss">
$srp__filter-count-size: 22px;

.v-badge.srp__filter-count {
  display: block;

  .v-badge__badge {
    border-radius: 50%;
    font-size: 14px;
    height: $srp__filter-count-size;
    line-height: $srp__filter-count-size;
    padding: 0;
    width: $srp__filter-count-size;
  }
}
</style>

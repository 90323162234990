export default {
  inject: ['dataSource'],
  render (h) {
    if (!this.dataSource.inventory.disclaimer) return null
    return h('section', {
      class: 'py-3 font-size-small grey--text text--darken-2',
      domProps: { innerHTML: this.dataSource.inventory.disclaimer }
    })
  }
}

import debounce from 'lodash.debounce'

import { paymentToPrice, priceToPayment } from '../utils/formulas'
import { deserialize, serialize } from './mapping'
import states from './states'

// The default assumptions are only used in the situation where this is a new
// Opportunity and the parent Instance has not been configured to provide defaults.
// As soon as an Opportunity interacts with the Payment Calculator, their last
// settings will always take precendence over these values until they reset.
const DEFAULT_ASSUMPTIONS = Object.freeze({
  apr: 3.9,
  downPayment: 0,
  monthlyPayment: 300,
  termLength: 60,
  tradeEquity: 0
})

const state = () => ({
  fees: {
    includeInPrice: false,
    selectedState: 'Texas'
  }
})

const getters = {
  /**
   * Assumptions that will be used in the calculation. When possible this will
   * use the values present on the current Opportunity, otherwise uses defaults.
   * @return {Object}
   */
  assumptions: (state, getters, rootState) => {
    const opportunity = deserialize(rootState.opportunity.data.creditTerms || {})
    return {
      ...DEFAULT_ASSUMPTIONS,
      ...opportunity
    }
  },

  creditDescription: (state, { assumptions: { apr } }) =>
    apr <= 3.9
      ? 'Very Good'
      : apr <= 7.9
        ? 'Average'
        : 'Bad',

  /**
   * @return {Number} fees.total - Estimated total of all registration fees.
   * @return {Function} fees.estimated - Calculates fees for a vehicle price.
   */
  fees: (state, { assumptions }) => {
    const fees = { ...states[state.fees.selectedState] }

    // If the user has not elected to include fee estimates in the price, or if
    // they live in a state where estimates are not available, we zero out the
    // fees. This allows all calculations to always factor in fees at the
    // appropriate place rather than checking in each one.
    if (!state.fees.includeInPrice || !fees) {
      fees.tax = fees.title = fees.registration = fees.plates = fees.documents = 0
    }

    fees.total = fees.title + fees.registration + fees.plates + fees.documents
    return {
      ...fees,
      estimated: vehiclePrice => ((vehiclePrice - assumptions.downPayment - assumptions.tradeEquity) * fees.tax / 100) + fees.total
    }
  },

  /**
   * Calculates the max affordable price for the payment calculator. Since APR
   * is estimated anyway from 3 credit rating buckets, doesn't make much
   * difference if we use APR vs interest rate in the calculation.
   * @return {Number}
   */
  maxPrice: (state, { assumptions, paymentToPrice }) =>
    paymentToPrice(assumptions.monthlyPayment),

  /**
   * Calculates a monthly payment based on current active assumptions.
   * @param {Number} vehiclePrice
   * @return {Number}
   */
  monthlyPayment: (state, { assumptions, fees }) => vehiclePrice => {
    let finalPrice = (vehiclePrice * (fees.tax || 0) / 100) + vehiclePrice + (fees.total || 0)
    finalPrice -= assumptions.downPayment + assumptions.tradeEquity
    const { apr, termLength } = assumptions
    return Math.round(priceToPayment(finalPrice, apr, termLength))
  },

  /**
   * Convert a monthly payment to an estimated max price. All assumptions that
   * have been set in the payment calculator are factored in. This can be used
   * to see how changing your monthly payment affects your budget without
   * persisting this change to your Opportunity.
   * @param {Number} monthlyPayment
   * @return {Number}
   */
  paymentToPrice: (state, { assumptions, fees }) => monthlyPayment => {
    const salesTax = 1 + (fees.tax || 0) / 100

    return Math.round((
      paymentToPrice(monthlyPayment, assumptions.apr, assumptions.termLength) +
      assumptions.downPayment + assumptions.tradeEquity
    ) / salesTax - (fees.total || 0))
  }
}

const actions = {
  /**
   * Dragging the sliders can cause a large number of events to fire. We want to
   * allow the user to see their changes in "real time" but don't need to send
   * updates to the API quite so often.
   */
  persistChanges: debounce(({ dispatch, getters }, creditTerms) => {
    dispatch('opportunity/mutate', {
      mutation: `mutation UpdateCreditTerms($input: CreditTermsInput!, $context: OpportunityContextInput!) {
        updateCreditTerms(input: $input, context: $context) {
          opportunity {
            id
          }
        }
      }`,
      input: getters.assumptions
    }, { root: true })
  }, 1000),

  /**
   * Reset the assumptions back to their defaults. This will also persist these
   * default valuess to the current Opportunity record and as such should only
   * be invoked in response to a user action.
   */
  resetAssumptions: ({ dispatch }) => {
    dispatch('updateAssumptions', { ...DEFAULT_ASSUMPTIONS })
  },

  /**
   * Update the current assumption values and persist to the current Opportunity.
   */
  updateAssumptions: async ({ commit, dispatch, getters }, creditTerms) => {
    creditTerms = serialize({ ...getters.assumptions, ...creditTerms })
    dispatch('opportunity/update', { creditTerms }, { root: true })
    dispatch('persistChanges')
  }
}

const mutations = {
  setFees (state, fees) {
    state.fees = { ...state.fees, ...fees }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

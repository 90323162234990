import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueProgressBar from 'vue-progressbar'
import VueRouter from 'vue-router'

import routes from '../routes'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueProgressBar, {
  color: 'var(--v-info-base)'
})

export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  Vue.prototype.$Progress.start()
  next()
})

router.afterEach((to, from) => {
  Vue.prototype.$Progress.finish()
})

export default VueRouter

<script>
import { Surface } from '@maxsystems/ui/elements'

export default {
  name: 'CertificationCallout',
  components: {
    Surface
  },
  inject: ['dataSource'],
  data: () => ({
    logoError: false
  }),
  computed: {
    certified: vm => vm.dataSource.inventory.certified || {},
    logo: vm => vm.certified.logo?.url,
    showLogo: vm => Boolean(vm.logo && !vm.logoError),
    showCertification: ({ certified }) =>
      certified.isCertified ||
      certified.isDealerCertified ||
      certified.isManufacturerCertified
  }
}
</script>
<template>
  <Surface
    v-if="showCertification"
    class="surface--certification-callout px-6 py-4 align-center"
  >
    <div
      class="text-uppercase text-center mb-2"
    >
      <img
        v-if="showLogo"
        :alt="certified.name"
        :src="logo"
        class="certification-callout__logo"
        @error="logoError = true"
      >
      <h5
        v-if="!showLogo"
        class="font-weight-medium my-4"
      >
        {{ certified.name }}
      </h5>
    </div>
    <ul
      class="certification-callout__benefits"
    >
      <li
        v-for="benefit in certified.benefits"
        :key="benefit.key"
        class="pl-4 my-2"
      >
        {{ benefit.description }}
      </li>
    </ul>
  </Surface>
</template>

<style lang="scss">
  .certification-callout__logo {
    max-width: 150px;
  }

  ul.certification-callout__benefits {
    padding-left: 0;

    li {
      list-style: none;
      position: relative;

      &::before {
        color: var(--v-grey-base);
        content: "\2022";
        left: 0;
        position: absolute;
      }
    }
  }
</style>

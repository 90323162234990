<script>
import { capitalize, currency } from '@maxsystems/ui/vue/filters'

import { rounded } from '../utils/filters'

const toNearestThousand = n => rounded(n, 1000, 'k')

export default {
  name: 'SRPContent',
  data () {
    return {
      showAllFilters: false
    }
  },
  computed: {
    filterGroups () {
      const search = this.$store.state.search
      let result = search.filters.reduce((filters, vuexFilter) => {
        if (vuexFilter.value.length < 1) return filters

        // collapase chips if all children of a hierarchical parent are selected
        const filter = { ...vuexFilter, value: [...vuexFilter.value] }
        if (filter.name.startsWith('makeModelTrim') ||
            filter.name.endsWith('.lvl0') ||
            filter.name === 'condition.lvl1') {
          // those are not meant to collapse
          return filters.concat(filter)
        }

        const hFacet = this.$store.getters['search/hierarchicalFacet'](filter.name.split('.')[0])
        const toCollapse = Object.values(hFacet.available).filter(v => v.selected && v.items.length > 1)
        filter.allValues = filter.value
        toCollapse.forEach(filterToCollapse => {
          filters.push({
            name: filter.name,
            value: [filterToCollapse.name],
            type: 'collapsed',
            allValues: filter.allValues
          })
          // filter out collapsed values
          filter.value = filter.value.filter(f => !f.startsWith(filterToCollapse.name))
        })
        if (filter.value.length) filters.push(filter)
        return filters
      }, [])

      if (search.numericFilters.length) {
        result = result.concat(
          search.numericFilters.map(({ name, value: { min, max } }) => {
            const numberFormatter = name === 'price'
              ? currency
              : name === 'mileage'
                ? toNearestThousand
                : x => x

            if (name === 'price' && search.meta.budget?.shopByPayment) {
              return {
                name,
                value: [`Up to ${currency(this.$store.getters['opportunity/payments/monthlyPayment'](max))}/mo`],
                type: 'numeric'
              }
            }

            return {
              name,
              value: [[capitalize(name), min === max
                ? numberFormatter(max)
                : `${numberFormatter(min)} - ${numberFormatter(max)}`
              ].join(': ')],
              type: 'numeric'
            }
          }).filter(Boolean)
        )
      }

      if (search.locationFilters.distance > 0) {
        const distance = search.locationFilters.distance.toString().length > 3
          ? toNearestThousand(search.locationFilters.distance)
          : search.locationFilters.distance
        const location = search.meta.location?.city || search.meta.location?.zip || 'location'
        result.push({
          name: 'location',
          value: [`Within ${distance} miles from ${location}`],
          type: 'location'
        })
      }

      return result
    },
    hasQuery () {
      return !!this.$store.state.search.query
    },
    totalFilters () {
      return this.filterGroups
        .map(({ value }) => value.length)
        .reduce((x, y) => x + y, 0)
    }
  },
  methods: {
    remove (group, filterValue) {
      this.$store.dispatch('search/removeFilter', {
        type: group.type,
        name: group.name,
        value: group.allValues || group.value,
        removeAll: group.type === 'collapsed',
        filterValue
      })
    },
    removeAll () {
      this.$store.dispatch('search/reset')
    }
  }
}
</script>

<template>
  <section class="srp__context">
    <div :class="['srp__context__chips', {'srp__context__chips--show-all': showAllFilters}]">
      <template
        v-for="group in filterGroups"
      >
        <VChip
          v-for="filter in group.value"
          :key="`index-${filter}`"
          color="grey darken-4"
          text-color="white"
          class="srp__context__chip my-1 mx-0 mr-2"
        >
          {{ filter }}
          <VIcon
            small
            color="grey darken-1"
            class="srp__context-icon"
            @click="remove(group, filter)"
          >
            $close
          </VIcon>
        </VChip>
      </template>
      <VChip
        v-if="totalFilters > 3"
        color="primary"
        text-color="white"
        class="srp__context__chip srp__context__chip--extra my-1 mx-0 mr-2"
        @click="showAllFilters = true"
      >
        + {{ totalFilters - 3 }} More
      </VChip>
    </div>
    <div class="mt-2">
      <a
        v-if="filterGroups.length || hasQuery"
        class="primary--text text-body-1 font-weight-bold"
        @click="removeAll"
      >
        Clear all
      </a>
    </div>
  </section>
</template>

<style lang="scss">
  .srp__context {
    &__chip {
      &.v-size--default {
        border-radius: 2px;
        font-size: 14px;
        max-height: 25px;
        padding: 0;

        @include breakpoint('xs-only') {
          &:nth-child(n+4):not(.srp__context__chip--extra) {
            display: none;
          }
        }
      }

      &--extra {
        @include breakpoint('sm-and-up') {
          display: none;
        }

        .v-chip__content {
          cursor: pointer;
        }
      }
    }

    &__chips--show-all {
      .srp__context__chip {
        &:nth-child(n):not(.srp__context__chip--extra) {
          display: inline-flex;
        }

        &--extra {
          display: none;
        }
      }
    }

    .v-chip__content {
      height: inherit;
      padding: 0 8px;
    }

    &:first-child {
      margin-left: 0;
    }

    &-icon {
      margin-left: 6px;
    }
  }
</style>

import { mapFlags } from '@maxsystems/ui/utils/features'

import SRP from './components/SRP.vue'

export default {
  components: { SRP },
  computed: {
    scope () {
      if (this.$route.params?.buid) return this.$route.params.buid
      return this.$store.state.instance.config.srp
    },
    ...mapFlags({
      hideNew: { flag: 'srp.hideNewInventory', default: false }
    })
  },
  render (h) {
    return h('SRP', {
      props: {
        condition: this.hideNew ? 'Used' : null,
        instance: this.$store.state.instance.id,
        scope: this.scope,
        to: 'vdp'
      }
    })
  }
}

<script>
import { Icon } from '@maxsystems/ui/elements'

export default {
  name: 'EmptyState',
  components: {
    Icon
  },
  props: {
    action: {
      type: String,
      default: 'Search for Cars'
    },
    description: {
      type: String,
      default: undefined
    },
    icon: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    to: {
      type: [String, Object],
      default: () => ({ name: 'srp' })
    }
  }
}
</script>

<template>
  <div class="customer-board__empty-state">
    <div class="grey--text text--darken-2">
      <div class="customer-board__empty-state__icon">
        <Icon
          class="primary--text"
          :src="icon"
          width="72"
        />
      </div>
      <h2 class="text-h5 mt-6 mb-4">
        {{ title }}
      </h2>
      <p
        v-if="description"
        class="text-body-1"
      >
        {{ description }}
      </p>
      <VBtn
        v-if="action && !$store.state.opportunity.viewOnly"
        class="mt-4 text-none text-body-1 font-weight-regular"
        color="primary"
        depressed
        large
        :to="to"
      >
        {{ action }}
      </VBtn>
    </div>
  </div>
</template>

<style lang="scss">
.customer-board__empty-state {
  text-align: center;

  &__icon {
    align-items: center;
    background-color: var(--v-grey-lighten3);
    border-radius: 100%;
    display: flex;
    height: 120px;
    justify-content: center;
    margin: 0 auto;
    opacity: 0.4;
    width: 120px;
  }

  h2 {
    text-transform: uppercase;
  }

  p {
    margin: 0 auto;
    max-width: 25rem;
  }
}
</style>

<script>
import { mapState } from 'vuex'

import Distance from './Distance'

export default {
  name: 'FreeForm',
  components: {
    Distance
  },
  data: () => ({
    isDirty: false,
    queryText: ''
  }),
  computed: {
    query: {
      get () {
        return this.$store.state.search.query
      },
      set (value) {
        this.isDirty = true
        this.queryText = value
      }

    },
    ...mapState('search', ['$loading'])
  },
  methods: {
    reset () {
      this.query = ''
      this.updateQuery()
    },
    updateQuery () {
      if (!this.isDirty || this.query === this.queryText) return
      this.$store.commit('search/setQuery', this.queryText)
      this.$store.dispatch('search/search')
    }
  }
}
</script>

<template>
  <v-form
    ref="srpQueryForm"
  >
    <VLayout
      class="srp__freeform"
      wrap
    >
      <VTextField
        v-model.trim="query"
        single-line
        outlined
        class="srp__freeform__input"
        clearable
        hide-details
        placeholder="Search by make, model, or keywords"
        prepend-inner-icon="$search"
        @focus="$event.target.select()"
        @click:clear="reset"
        @keydown.enter="updateQuery"
      />
      <Distance />
      <VBtn
        class="text-capitalize"
        color="primary"
        depressed
        :loading="$loading"
        :aria-busy="$loading"
        @click="updateQuery"
      >
        Search
      </VBtn>
    </VLayout>
  </v-form>
</template>

<style lang="scss">
.srp__freeform {
  & .v-btn.primary {
    height: 46px;
    margin: 0 0 0 24px;

    @include breakpoint('sm-and-down') {
      display: flex;
      flex: 1;
      margin-left: 10px;
    }
  }

  .srp__distance .v-input__slot,
  .srp__distance .v-input__slot:hover {
    @media only screen and (min-width: 650px) {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
  }

  .v-text-field--outlined {
    /* stylelint-disable-next-line no-descending-specificity */
    & .v-input__slot,
    & .v-input__slot:hover {
      background: var(--v-grey-lighten5) !important;
      min-height: 0 !important;
      padding: 0 20px !important;

      .v-input__prepend-inner {
        margin: 10px 10px 0 0;
        padding-right: 0;
      }

      input {
        margin: 0;
        max-height: none;
        padding: 12px 0;
      }
    }

    .v-input__control > .v-input__slot {
      fieldset {
        border: none;
      }

      border: 1px solid var(--v-grey-lighten1);
      height: 46px;
    }

    &.v-input--is-focused > .v-input__control > .v-input__slot {
      background: #FFF !important;
      border: 1px solid var(--v-primary-base);
      z-index: 1;
    }

    &:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
      border: 1px solid var(--v-grey-base);
      z-index: 1;
    }
  }

  &__input {
    margin-bottom: 8px !important;
    width: 100%;

    @media only screen and (min-width: 650px) {
      width: auto;

      & .v-input__slot,
      & .v-input__slot:hover {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }

    @include breakpoint('md-and-up') {
      margin-bottom: 0;
    }

    .v-input__append-inner {
      margin-top: 10px !important;
    }
  }
}
</style>

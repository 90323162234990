<script>
import PaymentCalculator from './PaymentCalculator'

export default {
  name: 'PaymentDialog',
  components: {
    PaymentCalculator
  },
  data: () => ({
    dialog: false
  })
}
</script>

<template>
  <VDialog
    v-model="dialog"
    max-width="90vw"
  >
    <template #activator="{ on }">
      <VBtn
        class="payment-calculator__open-dialog"
        color="primary"
        dark
        v-on="on"
      >
        Open Payment Calculator
      </VBtn>
    </template>
    <VCardTitle>
      <VFlex xs7>
        <VCardText
          class="text-h5"
          data-test="dialog-title"
        >
          Vehicle Payment Calculator
        </VCardText>
        <VCardText class="caption">
          Estimate your car payment or finance terms by adjusting the values below including vehicle price, credit rating, APR, down payment amount, and term length.
        </VCardText>
      </VFlex>
      <VSpacer />
      <VBtn
        icon
      >
        <VIcon x-large>
          $close
        </VIcon>
      </VBtn>
    </VCardTitle>
    <PaymentCalculator />
  </VDialog>
</template>

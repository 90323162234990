<script>
export default {
  name: 'NavigationDrawer',
  props: {
    value: { type: Boolean, required: true }
  },
  computed: {
    toggleClass: vm => {
      const baseClass = 'navigation-drawer navigation-drawer__wrapper'
      const open = `${baseClass} navigation-drawer--open`
      return vm.value ? open : baseClass
    }
  },
  render (h) {
    return h('div', {
      class: this.toggleClass
    }, this.$scopedSlots.default())
  }
}
</script>

<style lang="scss">
@include breakpoint('sm-and-down') {
  .navigation-drawer {
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &__wrapper {
      background-color: #FFF;
      height: 100%;
      left: -100%;
      overflow-y: scroll;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 5;
    }

    &--open {
      left: 0;
    }
  }
}
</style>

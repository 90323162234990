import { VBtn } from 'vuetify/lib'
import { mapState } from 'vuex'

import { Icon } from '@maxsystems/ui/elements'

import share from '../icons/share.svg'
import ShareDialog from './ShareDialog'

export default {
  name: 'ShareButton',
  components: {
    VBtn,
    Icon
  },
  props: {
    inventoryId: {
      type: [Number, String],
      default: null
    }
  },
  computed: {
    canShare () {
      return this.opportunityId && !this.viewOnly
    },
    ...mapState('opportunity', {
      viewOnly: state => state.viewOnly,
      opportunityId: state => state.data.id
    })
  },
  methods: {
    share () {
      if (this.inventoryId) {
        this.$store.dispatch('opportunity/board/addFavorite', this.inventoryId)
      }
      return ShareDialog.open.call(this)
    }
  },
  render (h) {
    if (!this.canShare) return

    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({
        share: this.share
      })
    }

    return h('VBtn', {
      props: {
        color: 'primary',
        depressed: true,
        outlined: true
      },
      on: { click: this.share }
    }, [
      h('Icon', {
        class: 'mr-1',
        props: {
          src: share
        }
      }),
      'Share'
    ])
  }
}

<script>
import Surface from '@maxsystems/ui/elements/Surface'

import DealerAutocomplete from './DealerAutocomplete'
import DealerRecentSearches, { addRecentSearch } from './DealerRecentSearches'
import { setHost } from '~/utils/host.js'

export default {
  components: {
    DealerAutocomplete,
    DealerRecentSearches,
    Surface
  },
  methods: {
    goToDealership (dealer) {
      addRecentSearch(dealer)
      setHost(new URL(dealer.url).hostname)
    }
  }
}
</script>

<template>
  <section class="store-select">
    <Surface
      v-slot="{ contrasting }"
      class="py-12 px-12"
      color="primary"
      flat
      sharp
      :style="{'overflow' : 'visible'}"
    >
      <h1 :class="[contrasting + '--text', 'pb-4']">
        Find your Dealership
      </h1>
      <DealerAutocomplete @selected="goToDealership" />
    </Surface>
    <DealerRecentSearches v-slot="{ recentSearches }">
      <Surface
        class="py-12 px-12"
        flat
        sharp
      >
        <h3 class="primary--text mb-4">
          Previous Dealership Searches
        </h3>
        <div class="store-select__recent font-size-xxs grey--text text--darken-3 pl-0 mt-2">
          <a
            v-for="dealer in recentSearches"
            :key="dealer.id"
            :href="dealer.url"
            class="find-your-dealer__results-list-item"
            @click.prevent="goToDealership(dealer)"
          >
            {{ dealer.name }}
          </a>
        </div>
      </Surface>
    </DealerRecentSearches>
  </section>
</template>

<style lang="scss">
.store-select {
  h1 {
    font-size: typography('h3');

    @include breakpoint('sm-and-up') {
      font-size: typography('h4');
    }
  }

  .dealer-autocomplete .v-input__control {
    max-width: 56rem;
    min-height: 44px;
  }

  &__recent {
    list-style: none;
    max-width: 56rem;

    a {
      border-bottom: 1px solid #EAEAEA;
      display: list-item;
      padding: 16px 20px;
      transition: all 0.4s;
      text-decoration: none;

      &:hover {
        background: #EAEAEA;
        cursor: pointer;
      }
    }
  }
}

.store-select > div {
  @include breakpoint('xs-only') {
    padding: 42px 20px !important;
  }
}

</style>

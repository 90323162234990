<script>
import { VTextFieldMasked } from 'v-text-field-masked'

import fetch from '@maxsystems/ui/api'
import { capitalize, normalize } from '@maxsystems/ui/vue/filters'
import { email as validateEmail, phone as validatePhone } from '@maxsystems/ui/vue/validation'

import mixin from './mixin'
import query from './query.gql'

export default {
  name: 'SendLink',
  filters: {
    capitalize,
    normalize
  },
  components: {
    VTextFieldMasked
  },
  mixins: [mixin],
  data: () => ({
    errors: {},
    consumer: {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    },
    loading: false,
    sendToCrm: true,
    finished: false
  }),
  methods: {
    clearErrors (key) {
      this.errors[key] = null
    },
    async submit () {
      if (!this.validate()) return
      this.loading = true
      const { opportunity, ...context } = this.context
      let { firstName, lastName, email, phone } = this.consumer

      // Strip out phone or email if they aren't provided by the user.
      // GraphQL will complain if we send an empty string.
      email = email || null

      phone = (phone && '+1' + phone) || null

      // Currently, sending both of these values will trigger a CRM lead.
      // We should still persist this on the Consumer in the future.
      if (!this.sendToCrm) {
        firstName = null
        lastName = null
      }

      const { errors } = await fetch(query, { opportunity, context, consumer: { firstName, lastName, email, phone } })

      if (errors?.[0]) {
        const messages = {
          email: ['Invalid email address'],
          phone: ['Invalid phone number.'],
          general: ['An error occured. Please try again.']
        }
        const what = errors[0].message.match(/(email|phone)/i)?.[0].toLowerCase() || 'general'
        this.errors[what] = messages[what]
        this.loading = false
        return
      }

      this.finished = true
      setTimeout(() => {
        this.finished = false
      }, 2500)
      this.loading = false
    },
    validate () {
      this.errors = {}
      if (this.sendToCrm && (!this.consumer.firstName || !this.consumer.lastName)) {
        this.errors.firstName = !this.consumer.firstName && ['Required']
        this.errors.lastName = !this.consumer.lastName && ['Required']
      }

      if (this.consumer.email && validateEmail(this.consumer.email) !== true) {
        this.errors.email = ['Invalid email address']
      }

      if (this.consumer.phone && validatePhone(this.consumer.phone) !== true) {
        this.errors.phone = ['Invalid phone number']
      }

      if (!this.consumer.email && !this.consumer.phone) {
        this.errors.email = ['Please provide either an email or phone number']
        this.errors.phone = ['']
      }

      return Object.keys(this.errors).length < 1
    }
  }
}
</script>

<template>
  <div class="board__share--dialog__msg">
    <VAlert
      v-if="Boolean(errors['general'])"
      type="error"
    >
      {{ errors['general'][0] }}
    </VAlert>
    <VTextFieldMasked
      v-for="(value, key) in consumer"
      :key="key"
      v-model="consumer[key]"
      :error="Boolean(errors[key])"
      :error-messages="errors[key]"
      :label="key | normalize | capitalize"
      :mask="key === 'phone' ? 'phone' : null"
      outlined
      @input="clearErrors(key)"
    />
    <VLayout>
      <VFlex sm6>
        <VCheckbox
          v-model="sendToCrm"
          class="mt-2"
          color="primary"
          label="Share with dealership"
        />
      </VFlex>
      <VFlex class="sm6 text-right">
        <VBtn
          class="text-capitalize"
          :color="finished ? 'success' : 'primary'"
          :loading="loading"
          :disabled="loading"
          depressed
          large
          @click="submit"
        >
          {{ finished ? 'Link Sent!' : 'Send Link' }}
        </VBtn>
      </VFlex>
    </VLayout>
  </div>
</template>

<style lang="scss">
.board__share--dialog__msg {
  & .v-btn {
    margin-right: 0;
  }
}
</style>

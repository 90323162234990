import { SelectListSorted } from '../../patterns'

export default {
  config: {
    title: 'Trim',
    weight: 12,
    facet: 'makeModelTrim.lvl2',
    require: {
      filters: {
        'makeModelTrim.lvl1': val => val?.length
      }
    }
  },
  render (h) {
    return h(SelectListSorted, { props: { facet: this.$options.config.facet } })
  }
}

<script>

export default {
  name: 'SavingsBanner',
  props: {
    savings: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    greatestSavings () {
      if (!this.savings || this.savings.length < 1) return
      return this.savings.reduce((prev, current) => (prev.savings > current.savings) ? prev : current)
    }
  }
}
</script>

<template>
  <VLayout class="surface--vehicle__savings-banner white--text pl-4 pr-2 py-1 wrap">
    <VFlex>
      <div class="text-body-1 font-weight-bold">
        {{ greatestSavings.savings | currency }}
      </div>
      <div class="surface--vehicle__savings-banner__description font-weight-regular">
        Below {{ greatestSavings.description }}
      </div>
    </VFlex>
  </VLayout>
</template>

<style lang="scss">
.surface--vehicle__savings-banner {
  background-image: linear-gradient(-90deg, var(--v-success-base) 2%, var(--v-success-lighten1) 100%);
  display: inline-flex;
  position: relative;
  user-select: none;
  z-index: 1;

  &__description {
    font-size:14px;
    line-height: 16px;
  }

  &:after {
    background: var(--v-success-base);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -12px;
    width: 20px;
    transform: skew(-15deg);
    z-index: -1;
  }
}
</style>

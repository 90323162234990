<script>
import { Icon } from '@maxsystems/ui/elements'

import {
  piggyBank,
  carOnHand,
  smilingPerson
} from '../icons'

export default {
  name: 'BuyExplainer',
  components: { Icon },
  data: () => ({
    content: [
      {
        icon: carOnHand,
        title: 'The fastest & easiest way to buy a car',
        subtitle: 'Complete the process at your pace, not ours'
      },
      {
        icon: piggyBank,
        title: 'Get the best deal',
        subtitle: 'Compare payment options to find the best one'
      },
      {
        icon: smilingPerson,
        title: 'Our staff is here to help with any questions'
      }
    ]
  })
}
</script>

<template>
  <article class="text-center primary--text text--darken-3 my-9 px-10 px-sm-0">
    <section
      v-for="{icon, title, subtitle} in content"
      :key="title"
      class="mb-5 mb-sm-8"
    >
      <Icon
        class="mb-2"
        :src="icon"
      />
      <h2 class="text-h6 font-weight-bold">
        {{ title }}
      </h2>
      <!-- eslint-disable vue/no-v-html - HTML from component data -->
      <p
        v-if="subtitle"
        class="grey--text text--darken-3"
        v-html="subtitle"
      />
      <!-- eslint-enable vue/no-v-html -->
    </section>
    <VBtn
      class="mt-sm-8 text-capitalize text-body-1"
      color="secondary"
      depressed
      large
      max-width="327"
      width="100%"
      @click="$emit('continue')"
    >
      Continue
    </VBtn>
  </article>
</template>

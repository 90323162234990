export const mapping = {
  apr: 'value',
  downPayment: 'amount',
  termLength: 'value',
  tradeEquity: 'amount',
  monthlyPayment: 'amount'
}

/**
 * @param {Object} terms - Structured object coming from GraphQL
 * @return {Object} - Flat object for the UI
 */
export function deserialize (terms) {
  return Object.entries(mapping).reduce((result, [key, value]) => {
    if (
      typeof terms[key] === 'undefined' ||
      terms[key] === null ||
      typeof terms[key][value] === 'undefined'
    ) return result
    result[key] = terms[key][value]
    return result
  }, {})
}

/**
 * @param {Object} terms - Flat object coming from the UI
 * @return {Object} - Structured object matching GraphQL
 */
export function serialize (terms) {
  return Object.entries(terms).reduce((result, [key, value]) => {
    result[key] = { [mapping[key]]: value }
    return result
  }, {})
}

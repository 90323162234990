import { SelectListSorted } from '../../patterns'

export default {
  config: {
    title: 'Model',
    weight: 11,
    facet: 'makeModelTrim.lvl1',
    require: {
      filters: {
        'makeModelTrim.lvl0': val => val?.length
      }
    }
  },
  render (h) {
    return h(SelectListSorted, {
      props: {
        facet: this.$options.config.facet,
        order: 'alphabetical'
      }
    })
  }
}

import { SelectListSorted } from '../patterns'

export default {
  config: {
    title: 'New / Used',
    weight: -3,
    facet: 'condition.lvl0',
    require: {
      // show Condition if its live facet includes multiple values
      multipleFacetsAvailable: 'condition.lvl0',
      baseFilters: {
        'condition.lvl0': ({ value }) => !value || value.length > 1
      }
    }
  },
  render (h) {
    return h(SelectListSorted, { props: { facet: this.$options.config.facet, static: true } })
  }
}

const NavigationLink = withPlugin => ({
  name: 'NavigationLink',
  mixins: [withPlugin],
  render () {
    if (this.visible === false) return
    const { icon, title, subtitle } = this
    return this.$scopedSlots.default({ icon, title, subtitle })
  }
})

export default {
  name: 'NavigationLinks',
  inject: ['plugins'],
  computed: {
    links: vm => vm.plugins.filter(({ nav }) => !!nav).sort((a, b) => (a.weight || 0) - (b.weight || 0))
  },
  render (h, vm) {
    return h('section', this.links.map(link =>
      h(NavigationLink(link.nav), {
        key: link.path,
        scopedSlots: {
          default: props => this.$scopedSlots.default({ ...link, ...props })
        }
      })
    ))
  }
}

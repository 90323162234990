<script>
import {
  CreditRating,
  CurrencySlider,
  TermLength
} from '../inputs'
import BtnShopCars from './BtnShopCars'
import Summary from './Summary'
import VehiclePrice from './VehiclePrice'

export default {
  name: 'PaymentCalculator',
  components: {
    BtnShopCars,
    CreditRating,
    CurrencySlider,
    Summary,
    TermLength,
    VehiclePrice
  },
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<template>
  <div class="payment-calculator">
    <VLayout>
      <VFlex
        xs12
        md6
      >
        <VFlex class="payment-calculator__tile">
          <VehiclePrice />
          <div class="payment-calculator__tile-body">
            <CurrencySlider
              assumption="monthlyPayment"
              :disabled="$store.state.opportunity.viewOnly"
              :max="2000"
              :min="50"
              :step="50"
              title="Monthly Payment"
            />
          </div>
        </VFlex>

        <VFlex class="payment-calculator__tile">
          <div class="payment-calculator__tile-body">
            <CurrencySlider
              assumption="downPayment"
              :disabled="$store.state.opportunity.viewOnly"
              title="Down Payment"
            />
            <VDivider class="mb-1 mt-2" />
            <TermLength v-bind="config" />
            <CreditRating v-bind="config" />
          </div>
        </VFlex>

        <VFlex class="payment-calculator__tile">
          <div class="payment-calculator__tile-body">
            <CurrencySlider
              assumption="tradeEquity"
              :disabled="$store.state.opportunity.viewOnly"
              :max="50000"
              :min="0"
              :step="100"
              title="Trade-In Value"
            />
          </div>
        </VFlex>

        <BtnShopCars class="mt-12 mb-6 hidden-md-and-up" />
      </VFlex>

      <VFlex
        hidden-sm-and-down
        md6
        xs12
        ml-6
      >
        <Summary />
        <BtnShopCars class="mt-12" />
      </VFlex>
    </VLayout>
    <div class="text-caption grey--text text--darken-2 mt-4">
      *The values provided are estimations for illustration purposes only. Tax, title, and tags vary by state and will be calculated at the time of purchase. The values provided are estimates for illustration purposes only and are based, where applicable, on the information you enter. APRs and terms used in estimates may not be applicable based on vehicle and state of purchase. These estimates do not constitute an advertisement or offer of specific credit terms. Your ability to obtain financing, as well as the APR, term, and other finance offer specifics, can only be determined based on your creditworthiness after submitting a credit application.
    </div>
  </div>
</template>

<style lang="scss">
  .payment-calculator {
    &__tile {
      background: #FFF;
      border-radius: 3px;
      margin-bottom: spacer(3);

      @include elevation(1);

      &-header {
        background: var(--v-grey-lighten3);
        color: var(--v-primary-base);
        display: flex;
        justify-content: space-between;
        padding: 20px 16px;
      }

      &-body {
        padding: spacer(3);
      }
    }

    .v-select {
      .v-input__slot {
        border-radius: 2px;
        height: 42px;
        min-height: initial;
      }

      .v-input__append-inner {
        align-self: center;
        margin: 0;
      }
    }
  }
</style>

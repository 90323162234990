<script>
import { Icon } from '@maxsystems/ui/elements'
import highlightsIcons from '@maxsystems/ui/patterns/VehicleHighlight/icons'

import { SelectListSorted } from '../patterns'
import { getIconName } from '../utils/highlights'

export default {
  name: 'SRPHighligts',
  config: {
    title: 'Vehicle Highlights',
    facet: 'highlights',
    weight: 14
  },
  components: {
    SelectListSorted,
    Icon
  },
  methods: {
    getIcon (value) {
      return highlightsIcons[getIconName(value)]
    }
  }
}
</script>

<template>
  <SelectListSorted
    facet="highlights"
    operator="AND"
    order="popularity"
  >
    <template #icon="{ value }">
      <Icon
        v-if="getIcon(value)"
        class="highlight--icon"
        :src="getIcon(value)"
      />
    </template>
  </SelectListSorted>
</template>

<style lang="scss">
.highlight--icon {
  height: 24px;
  width: 24px;
}
</style>

<script>
import { mapState } from 'vuex'

import pluggable from '@maxsystems/ui/mixins/pluggable'

import LeftNavigation from './LeftNavigation'

import '../plugins'

export default {
  name: 'CustomerBoard',
  components: {
    LeftNavigation
  },
  mixins: [
    pluggable('board')
  ],
  computed: {
    route () {
      return this._provided.plugins.find(route => route.path === this.current)
    },
    current: vm => vm.$route.hash.slice(1) || '/',
    ...mapState('opportunity', {
      opportunityId: state => state.data.id
    })
  },
  watch: {
    current: {
      async handler () {
        await this.$nextTick()
        if (this.route) return
        this.$router.replace({ hash: '/' })
      },
      immediate: true
    }
  },
  mounted () {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode
    if (!isIE11) return
    // For IE11 only
    // vm.$route.hash does not update correctly when location.hash changes
    // use $router.replace to update vm.$route.hash to trigger page change
    window.onhashchange = () => {
      if (this.$route.hash !== location.hash) {
        this.$router.replace(location.hash)
      }
    }
  },
  render (h) {
    return h('section', { class: 'customer-board', key: this.opportunityId }, [
      h('LeftNavigation', { props: { active: this.current } }),
      this.route && h(this.route.component, { class: 'customer-board__content py-6' }, [
        h('h1', { class: 'customer-board__content-title text-h4', slot: 'title' }, this.route.title)
      ])
    ])
  }
}
</script>

<style lang="scss">
.customer-board {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;

  @include breakpoint('sm-and-up') {
    flex-direction: row;
  }

  &__content {
    flex: 1;
    padding: 16px;
    padding-bottom: spacer(4);

    @include breakpoint('sm-and-up') {
      padding: 32px; // overriden by py-6
    }

    &-title {
      padding-bottom: spacer(3);

      @include breakpoint('sm-and-up') {
        font-size: 32px;
        padding-bottom: 24px !important;
      }
    }
  }
}
</style>

import { store as opportunity } from '@maxsystems/opportunity'

import './plugins/board'
import PaymentCalculator from './components/PaymentCalculator'
import PaymentDialog from './components/PaymentDialog'
import payments from './store'

export {
  PaymentCalculator,
  PaymentDialog,
  payments as store
}

export default {
  install (Vue, { store } = {}) {
    // Register the components on the current Vue instance. This allows us to
    // use directly in a template without needing to import them manually.
    Vue.component('PaymentCalculator', PaymentCalculator)
    Vue.component('PaymentDialog', PaymentDialog)

    // The Vuex store must be provided to the install hook for automatic registration.
    if (!store) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('`Vue.use` was called without a Vuex store; need to call `.register` manually.')
      }
      return
    }

    this.register(store)
  },

  register (store) {
    // The shared Opportunity module will be registered dynamically if it
    // has not already been installed by another product.
    if (!store._modules.root._children.opportunity) {
      store.registerModule('opportunity', opportunity, { preserveState: false })
    }

    // Once the base Opportunity module exists, we augment with the functionality
    // which is specific to the Payment Calculator.
    if (!store.getters['opportunity/modules'].has('payments')) {
      store.registerModule(['opportunity', 'payments'], payments, { preserveState: false })
    }
  },

  unregister (store) {
    if (!store.getters['opportunity/modules'].has('payments')) return
    store.unregisterModule(['opportunity', 'payments'], payments)
  }
}

import algoliasearch from 'algoliasearch'

const client = algoliasearch(process.env.ALGOLIA_ID, process.env.ALGOLIA_KEY)

const DRIVEWAY_INSTANCE_ID = '1J1LEQOfeYkBn4S3i6mosp'

// sets a limit on the number of facets (e.g. dealerNames) that Algolia returns
// in a search query. Increasing the default limit of 100 to handle groups with many dealers.
// https://www.algolia.com/doc/api-reference/api-parameters/maxValuesPerFacet/
// Our largest groups has about 400 dealers (so we want more than that)
const MAX_VALUE_PER_FACET = 500

export default {
  /**
   * Search in vehicle index.
   */
  client,

  indexes: {},

  getIndex (index) {
    if (!this.indexes[index]) this.indexes[index] = this.client.initIndex(index)
    return this.indexes[index]
  },

  /**
   * Search for a set of values within a given facet attribute
   *
   * Attribute must be declared in the attributesForFaceting index setting with the searchable() modifier
   * @param {String} index - Algolia index
   * @param {String} name - Facet attribute name
   * @param {String} query - Facet query
   * @param {String} filters - Facet filters
   *
   * @return {Object{ value: String, highlighted: String, count: Number }[]}
   */
  searchFacet ({ index, name: facetName, query: facetQuery, filters }) {
    const algoliaIndex = this.getIndex(index)

    return algoliaIndex
      .searchForFacetValues(facetName, facetQuery, {
        filters,
        typoTolerance: 'strict'
      }).catch(err => {
        console.error('Error searching Algolia for facet values', err)
        return err
      })
  },

  vehicle ({ params, query }) {
    const algoliaIndex = this.getIndex(params.index)

    const searchOptions = {
      aroundLatLng: params.locationFilters.latlng,
      aroundRadius: params.locationFilters.distance
        ? Math.floor(params.locationFilters.distance / 0.00062137)
        : 'all', // convert mile to m
      getRankingInfo: true,
      facets: '*',
      facetFilters: this.processFilters(params.filters),
      numericFilters: this.processNumericFilters(params.numericFilters),
      page: params.page,
      hitsPerPage: params.hitsPerPage,
      maxValuesPerFacet: MAX_VALUE_PER_FACET
    }
    // if there is an Instance, send the tag to Algolia
    if (params.instance && params.instance.length) {
      searchOptions.analyticsTags = [params.instance]
      searchOptions.ruleContexts = [params.instance]
    }

    // driveway.max.auto instance requires an additional filter to match inventory from driveway.com
    if (params?.instance === DRIVEWAY_INSTANCE_ID) {
      searchOptions.facetFilters.push(['isDriveway:1'])
    }

    return algoliaIndex
      .search(query, searchOptions)
      .catch(err => {
        console.error('Error searching with Algolia', err)
        return err
      })
  },

  /**
   * Converts Vuex filters facet object structure into facetFilters structure
   * that is compatible with Algolia's filter query.
   * If a filter facet has 'orWith', group the facet with OR condition in Algolia
   * Example input filters using orWith:
   *  [
   *    {
   *     name:"conditionHierarchy.lvl0",
   *     value:["New"],
   *     orWith:null
   *    },
   *    {
   *     name:"conditionHierarchy.lvl1",
   *     value:["Pre-owned > Non Certified"],
   *     orWith:"conditionHierarchy.lvl0"
   *    }
   *  ]
   * Example output facetFilters after orWith
   *  [
   *    "conditionHierarchy.lvl0:New",
   *    "conditionHierarchy.lvl1:Pre-owned > Non Certified"
   *  ]
   * @param {Object} filters - The filters object as stored in Vuex state
   * @return {Object} facetFilters - The facetFilters object that can be used in Algolia
   */
  processFilters: filters => {
    // AND filters must go after non-AND filters (i.e. OR filters)
    // to keep the `filters` indices matching the `facetFilters` indices
    // used in processing hierarchical filters.
    filters.sort((a, b) =>
      a.operator === 'AND' && b.operator !== 'AND'
        ? 1
        : a.operator !== 'AND' && b.operator === 'AND'
          ? -1
          : 0)

    let facetFilters = filters.map(x => x.value.map(y => `${x.name}:${y}`))
    function findFilter (facetFilters, name) {
      let result = -1
      facetFilters.forEach((f, index) => {
        if (f.filter && f.filter(v => v.split(':')[0] === name).length >= 1) {
          result = index
        }
      })
      return result
    }

    filters.forEach(facet => {
      const moveFromIndex = findFilter(facetFilters, facet.name)
      const moveToIndex = findFilter(facetFilters, facet.orWith)

      if (facet.orWith && moveToIndex > -1 && moveFromIndex > -1) {
        facetFilters[moveToIndex].push(...facetFilters[moveFromIndex])
        facetFilters.splice(moveFromIndex, 1)
      }

      // by default the operator is 'OR' if not specified
      if (facet.operator === 'AND') {
        const index = findFilter(facetFilters, facet.name)
        const newFacetValues = facet.value.map(f => `${facet.name}:${f} `)
        facetFilters.push(...newFacetValues)
        facetFilters.splice(index, 1)
      }
    })

    /* hierarchical filters
      1. group the same hierarchical filters but different lvl into one array to search with proper operator
    */
    /*
    Before
    [
      [
        'makeModelTrim.lvl1:Toyota > C-HR'
      ],
      [
        'makeModelTrim.lvl0:Toyota',
        'makeModelTrim.lvl0:Honda'
      ],
      'condition.lvl0:Used'
    ]
    After
    [
      [
        'makeModelTrim.lvl0:Honda',
        'makeModelTrim.lvl1:Toyota > C-HR'
      ],
      'condition.lvl0:Used'
    ]
    */
    const filterGroupNames = []
    const filterGroups = []
    const filterIndexToRemove = []
    filters.forEach((f, index) => {
      if (f.name.indexOf('.lvl') > -1) {
        const base = f.name.substr(0, f.name.indexOf('.lvl'))
        if (filterGroupNames.indexOf(base) === -1) {
          filterGroupNames.push(base)
          filterGroups.push([])
        }
        const groupIndex = filterGroupNames.indexOf(base)
        filterGroups[groupIndex].push(...(facetFilters[index] || []))

        // remove the original filters and add the transformed filter later at the final step
        filterIndexToRemove.push(index)
      }
    })

    /*
    2. when there are multiple hierarchical facets at different level (such as lvl0 and lvl1),
      discard the lower (lvl0) level and retain only the highest level (lvl1). This is safe because the higher level
      facet always contain the data of its hierarchichy
    */
    /*
    Example
    Before
    [
      [
        'makeModelTrim.lvl0:Bentley',
        'makeModelTrim.lvl0:Chevrolet',
        'makeModelTrim.lvl0:Mercury',
        'makeModelTrim.lvl0:Suzuki',
        'makeModelTrim.lvl1:Chevrolet > Camaro',
        'makeModelTrim.lvl1:Chevrolet > Cobalt',
        'makeModelTrim.lvl2:Chevrolet > Camaro > 2SS'
      ]
    ]
    After
    [
      [
        'makeModelTrim.lvl0:Bentley',
        'makeModelTrim.lvl0:Mercury',
        'makeModelTrim.lvl0:Suzuki',
        'makeModelTrim.lvl1:Chevrolet > Cobalt',
        'makeModelTrim.lvl2:Chevrolet > Camaro > 2SS'
      ]
    ]
    */
    filterGroups.forEach((filters, filterGroupIndex) => {
      // each filterGroup only has one hierarchy
      let simplified = [...filters]
      let highestLvl = 0
      for (let lvl = 2; lvl > 0; lvl--) {
        const hasLvl = filters.find(s => s.indexOf(`lvl${lvl}`) > -1)
        if (hasLvl) {
          highestLvl = lvl
          break
        }
      }

      filters.forEach((filter, filterIndex) => {
        for (let lvl = highestLvl; lvl >= 0; lvl--) {
          const filterValue = filter.substr(filter.indexOf(':') + 1, filter.length)
          const filterLvl = Number(filter.substr(filter.indexOf('lvl') + 3, 1))

          // ignore the check if the current lvl is the same as the one being checked
          if (lvl === filterLvl) {
            // not checking this level because of the same level
            break
          }
          const hasMatchingLowerLvl = filters.filter(t => t.indexOf(filterValue) > -1 && t.indexOf(lvl) > -1)

          if (hasMatchingLowerLvl.length) {
            simplified[filterIndex] = null
          }
        }
      })
      simplified = simplified.filter(f => f !== null)

      filterGroups[filterGroupIndex] = simplified
    })

    // remove original filters that have been transformed
    if (filterIndexToRemove.length) {
      filterIndexToRemove.forEach(removeIndex => {
        facetFilters[removeIndex] = null
      })
      facetFilters = facetFilters.filter(f => f !== null)
    }
    // add newly transformed filters
    if (filterGroups.length) {
      facetFilters.push(...filterGroups)
    }

    return facetFilters
  },
  processNumericFilters: filters => {
    let searchFilters = filters.filter(x => !Array.isArray(x.value) && (x.value.min > 0 || x.value.max > 0))

    searchFilters = searchFilters.map(x => {
      if (x.value.min > 0 && x.value.max > 0) {
        return `${x.name}:${x.value.min} TO ${x.value.max}`
      } else if (x.value.min > 0) {
        return `${x.name} >= ${x.value.min}`
      } else {
        return `${x.name} <= ${x.value.max}`
      }
    })
    return searchFilters
  }
}

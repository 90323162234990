import { RangeSlider } from '../patterns'

export default {
  config: {
    title: 'Mileage',
    weight: 30,
    facet: 'mileage'
  },
  render (h) {
    return h(RangeSlider, {
      props: {
        facet: this.$options.config.facet,
        sliderStep: 5000
      }
    })
  }
}

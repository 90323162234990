<script>

export default {
  inject: {
    plugins: {
      default: []
    }
  },
  props: {
    inventory: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    buttons: ({ plugins }) => plugins.find(
      plugin => plugin?.name === 'ActionButtonGroup'
    )?.buttons
  }
}
</script>

<template>
  <section
    v-if="buttons"
    class="vdp__actions my-6 d-print-none"
  >
    <component
      :is="button"
      v-for="(button, index) in buttons"
      :key="`button-${index}`"
      v-bind="$props"
    />
  </section>
</template>

<style lang="scss">
.vdp__actions {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;

  @include breakpoint('xs-only') {
    display: block;

    .v-btn {
      margin: 8px 0;
    }
  }

  & .v-btn {
    font-size: 16px;
    font-weight: normal;
    // Make 3rd+ button full width
    &:nth-of-type(n + 3) {
      grid-column: 1 / 3;
    }
  }
}
</style>

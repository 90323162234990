import debounce from 'lodash.debounce'

export default {
  props: {
    facet: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    }
  },
  computed: {
    staticFacet () {
      return this.$store.state.search.staticFacets[this.facet]
    },
    maxValue: {
      get () {
        const { max } = this.values.selected
        if (max || !this.values.available.length) return max
        return this.values.max
      },
      set (value) {
        this.setFacet({ max: parseInt(value), min: this.minValue })
      }
    },
    minValue: {
      get () {
        const { min } = this.values.selected
        // allow min of 0 to differenciate between undefined and 0
        if (min || min === 0 || !this.values.available.length) return min
        return this.values.min < 1 ? 0 : this.values.min
      },
      set (value) {
        this.setFacet({ max: this.maxValue, min: parseInt(value) })
      }
    },
    values () {
      const { available, selected, stats } = this.$store.getters['search/facet'](this.facet)
      return {
        available,
        selected: selected || {},
        min: this.min !== null ? this.min : stats.min,
        max: this.max !== null ? this.max : stats.max
      }
    }
  },
  methods: {
    search: debounce(function (config = {}) {
      this.$store.dispatch('search/search', config)
    }, 400),
    setFacet (value) {
      const { max, min } = this.range || this.values
      if (value.min === min && value.max === max) {
        this.$store.commit('search/removeFilter', this.facet)
        this.search()
        return
      }

      this.$store.commit('search/replaceNumericFilter', [this.facet, value])
      this.search()
    }
  }
}

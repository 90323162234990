<script>
export default {
  name: 'TabContent'
}
</script>
<template>
  <section>
    <slot name="title" />
    <PaymentCalculator />
  </section>
</template>

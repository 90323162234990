<script>
import { Surface } from '@maxsystems/ui/elements'
import { currency, number } from '@maxsystems/ui/vue/filters'

export default {
  components: {
    Surface
  },
  filters: {
    currency,
    number
  },
  inject: ['dataSource'],
  computed: {
    msrpTotal: vm => vm.options.msrp?.amount || 0,
    options: vm => vm.dataSource.vehicle.options || {},
    highlightOptions () {
      if (!this.options.all) return []
      return this.options.all.slice().sort((a, b) => b.msrp.amount - a.msrp.amount)
    }
  }
}
</script>

<template>
  <Surface
    v-if="options.all.length"
    class="surface--premium"
  >
    <VFlex>
      <div class="surface--premium__header px-6">
        <h3
          v-if="msrpTotal"
          class="text-h6 font-weight-medium"
        >
          Includes $<span class="text-h4 font-weight-medium">{{ msrpTotal | number }}</span> in Premium Upgrades
          <br>
          <small class="text-body-2 font-weight-medium">
            original MSRP
          </small>
        </h3>
        <h4 v-else>
          Included Premium Upgrades
        </h4>
      </div>
    </VFlex>
    <VLayout
      class="premium"
      column
    >
      <VFlex
        v-for="(option, index) in highlightOptions"
        :key="index"
      >
        <VDivider v-if="index > 0" />
        <div class="px-6 py-4">
          <h4 class="font-weight-bold">
            {{ option.name }}
          </h4>
          <div
            v-if="option.msrp.amount && option.msrp.amount > 0"
            class="mt-1"
          >
            <span class="premium__msrp text-body-1 font-weight-bold mr-1">{{ option.msrp.amount | currency }}</span>
            <span class="text-body-2 grey--text text--darken-3">Original MSRP</span>
          </div>
          <ul
            v-if="option.features"
            class="premium__all text-body-2 grey--text text--darken-3 pl-0 mt-2"
          >
            <li
              v-for="(details, i) in option.features"
              :key="i"
            >
              {{ details }}
            </li>
          </ul>
        </div>
      </VFlex>
    </VLayout>
  </Surface>
</template>

<style lang="scss">
  .surface--premium {
    &__header {
      align-items: center;
      background: linear-gradient(-45deg, #1883B5 0%, #2BA4DD 100%);
      color: #FFF;
      display: flex;
      min-height: 6rem;

      & span {
        vertical-align: -15%;
      }
    }

    .premium {
      &__msrp {
        color: var(--v-info-base);
      }

      &__all {
        list-style: none;

        & :last-child {
          padding-right: 0;
        }

        & li {
          line-height: 21px;

          &::before {
            color: var(--v-grey-base);
            content: "\2022";
            padding-right: 8px;
            position: relative;
          }
        }
      }

      & hr.v-divider {
        border-color: var(--surface-shadow);
      }
    }
  }
</style>

/**
 * The effect of an APR expressed on a monthly basis. These values can be used
 * both to calculate the monthly payment with interest and to calculate the
 * total amount of interest paid over the life of the loan.
 * @param {Number} apr - The annual percentage rate expressed as a whole number (ex. 4.9)
 * @param {Number} termLength - The length of the loan expressed in months (ex. 72)
 * @return {Object} rate
 * @return {Number} rate.apr - The APR expressed as a monthly amount.
 * @return {Number} rate.pow - The monthly APR raised to the power of the term length.
 */
function monthlyRate (apr, termLength) {
  apr = (!isNaN(apr) ? apr : 0) / 1200
  return {
    apr,
    pow: 1 - Math.pow(1 + apr, -1 * termLength)
  }
}

exports.monthlyRate = monthlyRate

/**
 * Calculates the max affordable price based on a monthly payment amount.
 * @return {Number}
 */
exports.paymentToPrice = function paymentToPrice (monthlyPayment, apr, termLength) {
  const rate = monthlyRate(apr, termLength)
  return apr
    ? monthlyPayment * rate.pow / rate.apr
    : monthlyPayment * termLength
}

/**
 * Calculates a monthly payment based on current active assumptions.
 * @param {Number} finalPrice - The final purchase price of the vehicle less any
 * downpayment, trade equity or other incentives.
 * @param {Number} apr - The annual percentage rate expressed as a whole number (ex. 4.9)
 * @param {Number} termLength - The length of the loan expressed in months (ex. 72)
 * @return {Number}
 */
exports.priceToPayment = function priceToPayment (finalPrice, apr, termLength) {
  const rate = monthlyRate(apr, termLength)
  const price = apr ? finalPrice * rate.apr / rate.pow : finalPrice / termLength
  return Math.max(price, 0)
}

<script>
import { InputSelect } from '@maxsystems/ui/elements'

import numeric from '../mixins/numeric'

export default {
  components: {
    InputSelect
  },
  mixins: [numeric],
  computed: {
    minOptions () {
      if (!this.staticFacet) return []
      const available = Object.keys(this.staticFacet).map(y => Number(y))
      if (!available || !available.length) return []
      return available.slice(0, available.indexOf(this.maxValue) + 1)
    },
    maxOptions () {
      if (!this.staticFacet) return []
      const available = Object.keys(this.staticFacet).map(y => Number(y))
      if (!available || !available.length) return []
      return available.slice(available.indexOf(this.minValue), available.length)
    }
  }
}
</script>

<template>
  <VLayout
    wrap
  >
    <VFlex xs12>
      <VLayout
        wrap
      >
        <VFlex xs5>
          <InputSelect
            v-model="minValue"
            :items="minOptions"
            outlined
            single-line
          />
        </VFlex>
        <VFlex class="xs2 text-center">
          <VIcon class="py-8">
            $minus
          </VIcon>
        </VFlex>
        <VFlex xs5>
          <InputSelect
            v-model="maxValue"
            :items="maxOptions"
            outlined
            single-line
          />
        </VFlex>
      </VLayout>
    </VFlex>
  </VLayout>
</template>

<style lang="scss">
  .v-messages__message {
    margin-bottom: 12px;
  }
</style>

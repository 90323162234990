export const dictionary = {
  'CARFAX 1-Owner': 'CARFAX_ONE_OWNER',
  'CARFAX Accident-free': 'CARFAX_NO_ACCIDENTS',
  'High Demand': 'HIGH_DEMAND',
  'Loaded with Upgrades': 'HIGH_VALUE_OPTIONS',
  'Low Miles': 'LOW_MILEAGE',
  'Rare Vehicle': 'RARE_FIND',
  'Green Vehicle': 'GREEN_VEHICLE'
}

export const getIconName = value => {
  if (!value) return

  return dictionary[value]
}

export default function (highlights) {
  if (!highlights) return

  return highlights.map(highlight => dictionary[highlight])
}

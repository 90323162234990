import { SelectListSorted } from '../patterns'

export default {
  config: {
    title: 'Certification',
    facet: 'condition.lvl1',
    weight: -2,
    require: {
      baseFilters: {
        // Certification should not show if the SRP has been scoped to show
        // only new vehicle
        'condition.lvl0': ({ value }) => !(value?.length === 1 && value.includes('New'))
      }
    }
  },
  render (h) {
    return h(SelectListSorted, { props: { facet: this.$options.config.facet, radio: true } })
  }
}

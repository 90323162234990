<script>
import debounce from 'lodash.debounce'

import { InputNumeric, Slider } from '@maxsystems/ui/elements'
import { currency } from '@maxsystems/ui/vue/filters'

import { priceToPayment, paymentToPrice } from '~/plugins/payments/utils/formulas'

export const PAYMENT_MAX = 1000
export const PAYMENT_STEP = 50

export default {
  name: 'ShopByPayment',
  components: {
    InputNumeric,
    Slider
  },
  title: 'Monthly Payment',
  visible () {
    return this.$store.getters['opportunity/modules']?.has('payments')
  },
  filters: { currency },
  inject: ['meta'],
  onSelected () {
    this.meta = { ...this.meta, shopByPayment: true }
  },
  data: () => ({
    range: { min: 0, max: PAYMENT_MAX, step: PAYMENT_STEP }
  }),
  computed: {
    active () {
      return this.$store.getters['opportunity/modules']?.has('payments') &&
        this.meta.shopByPayment
    },
    assumptions () {
      return this.$store.getters['opportunity/payments/assumptions']
    },
    creditDescription: vm => vm.$store.getters['opportunity/payments/creditDescription'],
    maxPrice () {
      const price = this.$store.getters['search/facet']('price')
      return price.selected?.max || price.stats?.max
    },
    maxPayment () {
      const { apr, termLength, downPayment, tradeEquity } = this.assumptions
      return priceToPayment(this.maxPrice - (downPayment + tradeEquity), apr, termLength)
    },
    value: {
      get () {
        const payment = PAYMENT_STEP * Math.round(this.maxPayment / PAYMENT_STEP) || PAYMENT_STEP
        return Math.min(payment, PAYMENT_MAX)
      },
      set (value) {
        if (value >= PAYMENT_MAX) {
          this.$store.commit('search/removeFilter', 'price')
          return this.search()
        }

        const { apr, termLength, downPayment, tradeEquity } = this.assumptions
        const min = this.$store.getters['search/facet']('price').selected?.min || 0
        const max = Math.round(
          paymentToPrice(value, apr, termLength) +
          downPayment + tradeEquity
        )

        this.$store.commit('search/replaceNumericFilter', ['price', { min, max }])
        this.search()
      }
    }
  },
  methods: {
    search: debounce(function () {
      this.$store.dispatch('search/search')
    }, 400),
    focusPaymentInput ($event) {
      this.$nextTick(() => $event.target.select())
    }
  }
}
</script>

<template>
  <VLayout
    wrap
    class="srp__filter--budget__payment"
  >
    <VFlex xs12>
      <InputNumeric
        v-model="value"
        :max="range.max"
        :min="range.min + range.step"
        outlined
        prefix="$"
        :suffix="value >= 1000 ? '+' : ''"
        single-line
        width="100px"
        @focus="focusPaymentInput"
      />
    </VFlex>
    <VFlex
      xs12
    >
      <Slider
        v-model="value"
        class="mb-2 mt-4"
        hide-details
        :min="range.min + range.step"
        :max="range.max"
        :step="range.step"
        thumb-label="always"
        ticks
      />
    </VFlex>
    <VFlex
      class="text-caption grey--text text--darken-1 mb-2"
      xs12
    >
      Estimate based on <strong>{{ creditDescription }}</strong> credit,
      <strong>{{ assumptions.termLength }}</strong> month term,
      <strong>{{ assumptions.downPayment === 0 ? 'no' : assumptions.downPayment | currency }}</strong> cash down and
      <strong>{{ assumptions.tradeEquity === 0 ? 'no' : assumptions.tradeEquity | currency }}</strong> Trade Value.
      Taxes & fees not included.
      <router-link :to="{ name: 'board', hash: '#/payments'}">
        edit this estimate
      </router-link>
    </VFlex>
  </VLayout>
</template>

<style lang="scss">
.srp__filter--budget__payment {
  .v-text-field__prefix {
    margin-top: 4px !important;
  }
}
</style>

<script>
export default {
  name: 'CreditRating',
  props: {
    ratings: {
      type: Array,
      default: () => ([
        { apr: 9.9, title: 'Rebuilding', range: 'Less than 600' },
        { apr: 7.9, title: 'Average', range: '600-750' },
        { apr: 3.9, title: 'Above Average', range: 'Over 750' }
      ])
    }
  },
  computed: {
    rating: {
      get () {
        return this.$store.getters['opportunity/payments/assumptions'].apr
      },
      set (apr) {
        this.$store.dispatch('opportunity/payments/updateAssumptions', { apr })
      }
    }
  }
}
</script>

<template>
  <section class="credit-rating">
    <h4 class="text-subtitle-1 mb-4">
      Credit Rating
    </h4>
    <VSelect
      v-model="rating"
      eager
      :disabled="$store.state.opportunity.viewOnly"
      :items="ratings"
      item-text="title"
      item-value="apr"
      class="creditRating"
      outlined
      single-line
    >
      <template
        slot="selection"
        slot-scope="data"
      >
        {{ data.item.title }} ({{ data.item.range }})
      </template>
      <template
        slot="item"
        slot-scope="data"
      >
        <VListItemContent>
          <VListItemTitle>
            {{ data.item.title }} ({{ data.item.range }})
          </VListItemTitle>
        </VListItemContent>
      </template>
    </VSelect>
  </section>
</template>

<style lang="scss">
  .credit-rating {
    .v-text-field__details {
      display: none;
    }
  }
</style>

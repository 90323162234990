import geo from '@maxsystems/ui/api/geo'
import { capitalize } from '@maxsystems/ui/vue/filters'

/**
 * Find a path segment using regex and return the match.
 * @param {Array} params - All path segments as an array.
 * @param {RegExp} criteria - The regex to match.
 * @return {Object} result
 * @return {Boolean} result.hasSegment - Whether the segment was found.
 * @return {String} result.match - Result of the regex on the matching segment.
 * @return {Array} result.remaining - All parameters minus the matching one.
 * This can be passed to the next parsing method to reduce the scope and prevent
 * segments from being evaluated more than once.
 */
function findPathSegment (params, criteria) {
  const segment = params.find(param => param.match(criteria))
  if (!segment) return { hasSegment: false }

  return {
    hasSegment: true,
    match: segment.match(criteria),
    remaining: params.filter(candidate => candidate !== segment)
  }
}

/**
 * Returns a list of facets by makeModelTrim level
 * @param {Number} level - makeModelTrim level
 * @returns {{name: String, count: Number}[]} facets - array of facets with name and count
 */
function mmtFacetLevel (level) {
  return this.$store.state.search.facets[`makeModelTrim.lvl${level}`]
}

/**
 * Replace filter when passed filter and name
 * @param {String} filter - filter name
 * @param {String} name - name to add to filter
 * @param {Object} booleanParams - object with 'operator' or 'orWith' key
 */
function replaceFilter (filter, name, booleanParams = { operator: 'OR' }) {
  this.$store.commit(
    'search/replaceFilter',
    [filter, [name], booleanParams]
  )
}

/**
 * Replace makeModelTrim.lvl filter when passed name and level
 * @param {String} name - makeModelTrim name
 * @param {Number} level - makeModelTrim level
 */
function mmtCommitByLevel (name, level) {
  replaceFilter.call(this, `makeModelTrim.lvl${level}`, name)
}

/**
 * Returns function which returns false if string is notEqualString
 *
 * Use with Array.filter to filter out the string notEqualString from an array
 * @param {String} notEqualString - string to not equal against
 * @param {String} string - string that might not be notEqualString
 * @returns {(string: String) => Boolean} boolean - If string was not notEqualString
 */
const out = notEqualString => string => string !== notEqualString

/**
 *
 * @param {String} propName - property that you want to access
 * @param {Object} object - object that has the property
 * @returns {(object: Object) => any} value - returns object property value or object if not found
 */

const getProp = propName => object =>
  object && object[propName]
    ? object[propName]
    : object

/** Returns the value of a `name` key from an object */
const getName = getProp('name')
/** Returns the value of a `value` key from an object */
const getValue = getProp('value')

/**
 * Get make name from approxMake
 * @param {String} approxMake - e.g. mercedes
 * @return {String} make - e.g. Mercedes-Benz
 */
// could be modified to fetch from algolia facet
function findMake (approxMake) {
  return getName(
    mmtFacetLevel.call(this, 0)
      .find(
        ({ name }) => name.toLowerCase().includes(approxMake)
      )
  )
}

/**
 * Get all body styles from segment facet
 * @returns {String[]}
 */
function getBodyStyles () {
  return this.$store.state.search.facets.segment
    .map(getName)
}

/**
 * Get an array containing current make and model filter
 *
 * Returns [make, model] - both of which could be undefined
 * @return {Array} makeModel - [make, model]
 */
function getMakeModelFilter () {
  return Array(2)
    .fill()
    .map((_, i) => this.$store.getters['search/filter'](`makeModelTrim.lvl${i}`))
    .map(x => Array.isArray(x) ? x[0] : x)
}

/**
 * Get all colors from the exteriorColor.lvl1 facet
 *
 * @returns {String[]} colors - e.g. [Light > Orange...]
 */
function getColors () {
  return this.$store.state.search.facets['exteriorColor.lvl1']
    .map(getName)
}

/**
 * Finds facet color name based on color
 * @param {String} color - color name - e.g. orange
 * @return {String} facetColor - facet color name - e.g. Light > Orange
 */
function findColor (color) {
  return getColors.call(this)
    .find(exteriorColor => exteriorColor.toLowerCase().includes(color))
}

/**
 * Gets the string with shortest length
 *
 * Use with Array reduce
 *
 * @param {String} a - a string
 * @param {String} b - another string
 * @return {String} the shortest string
 */
const toShortestValue = (a, b) => a.length < b.length
  ? a
  : b

/**
 * Replaces ` > `, `.` and `-` with a space in a string and then lowerCases it
 *
 * e.g. 'BMW > X3 > 3.0si' becomes 'bmw x3 3 0si'
 * @param {String} string - String to strip symbols from
 * @returns {String} stripped
 */
const stripSymbols = string => string
  .toLowerCase()
  .trim()
  .replace(/( > |\.|-)/g, ' ')

/**
 * Get model name from query
 * @param {String} query e.g. mercedes-benz-c-class
 * @return {String} model - e.g. Mercedes-Benz > C-Class
 */
function findModel (query) {
  return this.$store.dispatch('search/searchFacet', {
    name: 'makeModelTrim.lvl1',
    query
  }).then(models => {
    if (models.length > 0) {
      return models
        .map(getValue)
        .reduce(toShortestValue)
    }
    return null
  })
}

/**
 * Finds trim name that matches query
 * @param {String} query - e.g. bmw x3 sdrive28i
 * @returns {String} trim - e.g. BMW > X3 > sDrive28i
 */
function findTrim (query) {
  const outNonMatches = trim => stripSymbols(trim) === stripSymbols(query)
  return this.$store.dispatch('search/searchFacet', {
    name: 'makeModelTrim.lvl2',
    query
  }).then(trims => {
    if (trims.length > 0) {
      return trims
        .map(getValue)
        .filter(outNonMatches)[0]
    }
    return null
  })
}

/**
 * Parsing methods which will be run on the path segments in the URL. Each will
 * be executed in the order they are defined here using a middleware approach.
 * Inexpensive matching should be done first, and more expensive operations last
 * since the number of parameters will be reduced with every match.
 */
const parse = {
  /**
   * Condition will be either `used` or `new`
   * For now all we have is `used` so we will filter it out
   */
  condition: next => async function (params) {
    const {
      hasSegment,
      match,
      remaining
    } = findPathSegment(params, /^(new|used)$/)
    if (!hasSegment) return next(params)

    const condition = capitalize(match[0])
    replaceFilter.call(this, 'condition.lvl0', condition)

    this.filtersPending = true
    next(remaining)
  },
  /**
   * The payment segment in the URL is prefixed by 'under-'. Setting a monthly
   * payment via the route parameters does not change the monthly payment the
   * user set in the payment calculator but uses all other assumptions to
   * filter the SRP by vehicles that are in budget.
   */
  payment: next => async function (params) {
    if (!this.$store.state.opportunity || !this.$store.getters['opportunity/modules'].has('payments')) {
      return next(params)
    }
    const {
      hasSegment,
      match,
      remaining
    } = findPathSegment(params, /^under-(.*)-mo/i)
    if (!hasSegment) return next(params)

    const monthlyPayment = Number(match.pop())
    if (isNaN(monthlyPayment) || monthlyPayment >= 1000) return next([...remaining, match.input])
    const maxPrice = this.$store.getters['opportunity/payments/paymentToPrice'](monthlyPayment)
    this.$store.commit('search/replaceNumericFilter', ['price', { min: 0, max: maxPrice }])
    this.$store.commit('search/setState', { name: 'monthlyPayment', value: monthlyPayment })
    this.$store.commit('search/setState', { name: 'monthlyPaymentActive', value: true })
    this.filtersPending = true
    return next(remaining)
  },
  /**
   * The location segment in the URL is prefixed by `near-`. Any value inside
   * is passed to Google's geocoding API as-is. When this location segment is
   * provided, no geocoding should take place against the user session.
   */
  location: next => async function (params) {
    const {
      hasSegment,
      match,
      remaining
    } = findPathSegment(params, /^near-(.*)/i)
    if (!hasSegment) return next(params)

    const address = match.pop().replace(/-/g, ' ')
    const locationFilter = await geo.addressToGeo(address)
    if (!locationFilter.latlng) return next(remaining)

    this.$store.commit('search/setLocationFilter', ['latlng', locationFilter.latlng])
    const location = await geo.get(locationFilter.latlng)
    if (location.error) return next(remaining)

    this.$store.commit('search/setLocationFilter', ['distance', 250])

    this.$store.commit('search/setMeta', {
      plugin: 'location',
      value: {
        city: location.city,
        zip: location.zip
      }
    })

    this.filtersPending = true
    next(remaining)
  },
  /**
   * Popular equipment in the URL is prefixed by `with-`. Only two potential
   * matches are supported. Other parameters will be hashed in the catch-all
   * fragment.
   */
  equipment: next => async function (params) {
    const {
      hasSegment,
      match,
      remaining
    } = findPathSegment(params, /^with-(.*)/i)
    if (!hasSegment) return next(params)

    const selectedEquipment = match.pop().split('-and-').reduce((result, equipment) => {
      equipment = new RegExp(equipment.replace(/-/gi, '.{1}'), 'i')
      const selected = this.$store.state.search.facets.popularEquipment.find(x => equipment.test(x.name))
      if (selected) {
        result.push(selected.name)
      }

      return result
    }, [])

    if (!selectedEquipment.length) return next(remaining)
    this.$store.commit('search/replaceFilter', ['popularEquipment', selectedEquipment, { operator: 'AND' }])
    this.filtersPending = true
    next(remaining)
  },
  year: next => async function (params) {
    const yearRegexp = /^(\d{4}-\d{4}|\d{4})/m
    const {
      hasSegment,
      match,
      remaining
    } = findPathSegment(params, yearRegexp)
    if (!hasSegment) return next(params)
    const [min, max = min] = match[1].split('-').map(Number)
    this.$store.commit('search/replaceNumericFilter', ['year', { min, max }])
    // remainingSegment is everything after year and trailing dash e.g. 2001-2002-(make-model)
    const remainingSegment = match.input.replace(`${match[0]}-`, '')
    this.filtersPending = true
    next(
      remainingSegment === ''
        ? remaining
        : params.map(param => yearRegexp.test(param)
          ? remainingSegment
          : param
        )
    )
  },
  makeModel: next => async function (params) {
    const segment = params[0]
    if (!segment) return next(params)
    const make = findMake.call(this, segment.split('-')[0])
    if (!make) return next(params)
    mmtCommitByLevel.call(this, make, 0)
    // check if only make is present
    if (make.length !== segment.length) {
      const model = await findModel.call(this, segment)
      if (model) mmtCommitByLevel.call(this, model, 1)
    }
    this.filtersPending = true
    next(params.filter(out(segment)))
  },
  body: next => async function (params) {
    const bodyStyleRegexp = new RegExp(
      getBodyStyles.call(this).join('|'), 'i'
    )
    const {
      hasSegment,
      match,
      remaining
    } = findPathSegment(params, bodyStyleRegexp)
    if (!hasSegment) return next(params)

    const bodyStyle = capitalize(match[0])
    replaceFilter.call(this, 'segment', bodyStyle === 'Suv'
      ? 'SUV'
      : bodyStyle
    )
    this.filtersPending = true
    return next([...remaining, match.input.replace(match[0], '')])
  },
  color: next => async function (params) {
    const colorRegexp = new RegExp(
      getColors.call(this)
        .map(colorFacet => colorFacet.replace(/(.*> )/, ''))
        .join('|'),
      'i'
    )
    const {
      hasSegment,
      match,
      remaining
    } = findPathSegment(params, colorRegexp)
    if (!hasSegment) return next(params)

    const colorMatch = match[0]

    const color = findColor.call(this, colorMatch)
    if (!color) return next(params)

    replaceFilter.call(this, 'exteriorColor.lvl1', color, { orWith: 'exteriorColor.lvl0' })
    this.filtersPending = true
    next([...remaining, match.input.replace(match[0], '')])
  },
  trim: next => async function (params) {
    const segment = params[0]
    if (!segment) return next(params)
    const [make, model] = getMakeModelFilter.call(this)
    if (make && model) {
      const strippedSegment = segment.replace(/(^-|-$)/g, '')
      const trim = await findTrim.call(this, stripSymbols(`${model} ${strippedSegment}`))
      if (trim) mmtCommitByLevel.call(this, trim, 2)
    }
    this.filtersPending = true
    next(params.filter(out(segment)))
  }
}

export default {
  data: () => ({
    filtersPending: false
  }),
  methods: {
    parsePath (params) {
      return new Promise(resolve => Object.keys(parse).reverse().reduce((stack, hook) => parse[hook](stack).bind(this), resolve)(params))
    },
    async parseAndApplyRouteParameters () {
      if (!this.$route.params.pathMatch ||
        this.$store.state.search.pathParametersApplied === this.$route.params.pathMatch) return

      this.$store.commit('search/clearFilters')

      if (Object.keys(this.$store.state.search.facets).length === 0) {
        await this.$store.dispatch('search/getFacets')
      }

      const parameters = this.$route.params.pathMatch.replace(/^\//, '').split('/')
      await this.parsePath(parameters)

      this.$store.commit('search/setState', { name: 'pathParametersApplied', value: this.$route.params.pathMatch })
    }
  }
}

import StoreSelect from '../components/StoreSelect'

export default {
  components: {
    StoreSelect
  },
  computed: {
    landing () {
      return (this.$store.state.instance.cms?.homepage || '').replace(' ', '')
    }
  },
  render (h) {
    if (!this.landing || this.landing === 'SRP') {
      this.$router.replace('/search')
      return
    }

    return h(this.landing)
  }
}

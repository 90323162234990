<script>
import { Surface } from '@maxsystems/ui/elements'

import Context from '../Context'
import FilterCount from '../FilterCount'
import RefinementMenu from '../RefinementMenu'
import NavigationDrawer from './NavigationDrawer'

export default {
  name: 'SRPFilters',
  components: {
    NavigationDrawer,
    Surface,
    FilterCount,
    Context,
    RefinementMenu
  },
  model: {
    prop: 'showFilters',
    event: 'input'
  },
  props: {
    showFilters: { type: Boolean, default: false }
  },
  methods: {
    toggleVisible () {
      this.$emit('input', !this.showFilters)
    }
  }
}
</script>

<template>
  <section
    class="srp__filters"
  >
    <NavigationDrawer :value="showFilters">
      <Surface sharp>
        <VLayout
          class="nav__header justify-space-between hidden-md-and-up"
        >
          <h6 class="text-body-1 pl-6 font-weight-medium align-center d-flex">
            Filters
            <FilterCount
              facet="all"
              class="ml-2"
            />
          </h6>
          <VBtn
            class="ml-4"
            small
            fab
            text
            @click.stop="toggleVisible"
          >
            <VIcon>$close</VIcon>
          </vbtn>
        </VLayout>
      </Surface>

      <Context
        v-if="showFilters"
        class="mt-0 ma-4 hidden-md-and-up"
      />

      <RefinementMenu />
    </NavigationDrawer>
    <div
      :class="['show-cars__wrapper align-center hidden-md-and-up', {
        'show-cars__wrapper--open': showFilters,
      }]"
    >
      <VBtn
        class="show-cars--button ma-0"
        color="primary"
        block
        depressed
        @click.stop="toggleVisible"
      >
        Show Cars
      </VBtn>
    </div>
  </section>
</template>

<style lang="scss">
$shop-cars-wrapper-height: 72px;

.srp__filters {
  .nav__header {
    align-items: center;
    height: 48px;
  }

  .v-expansion-panel {
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 0 0 1px 0;

    &::before,
    &::after {
      border: none;
      box-shadow: none;
    }

    &-content__wrap {
      padding: 0 spacer(6) spacer(3);
    }
  }

  .navigation-drawer {
    padding-bottom: $shop-cars-wrapper-height;
  }

  .show-cars {
    &__wrapper {
      background: var(--v-grey-lighten3);
      bottom: 0;
      display: flex;
      height: $shop-cars-wrapper-height;
      padding: 0 spacer(8);
      position: fixed;
      transform: translateX(-100%) !important;
      transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      width: 100%;
      z-index: 6;

      &--open {
        transform: translateX(0%) !important;
      }
    }

    &--button {
      height: 42px;
      text-transform: capitalize;
    }
  }
}
</style>

<script>
import { VBtn, VCard } from 'vuetify/lib'

import { Icon } from '@maxsystems/ui/elements'
import { date } from '@maxsystems/ui/vue/filters'

import successIcon from './success.svg'

export default {
  name: 'ReservationDialogSuccess',
  components: {
    Icon,
    VBtn,
    VCard
  },
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    date: {
      type: [Number, Date],
      required: true
    }
  },
  data: () => ({
    successIcon
  }),
  computed: {
    formatted: vm => ({
      date: date(vm.date, 'YYYY-MM-DD'),
      time: date(vm.date, 'h:mm A')
    }),
    fullName () {
      return [this.firstName, this.lastName].join(' ')
    }
  }
}
</script>

<template>
  <VCard>
    <VBtn
      v-if="$vuetify.breakpoint.mdAndUp"
      icon
      class="rsvp-result-dialog__close-button"
      @click="$emit('close-dialog')"
    >
      <VIcon>$close</VIcon>
    </VBtn>
    <VLayout
      wrap
    >
      <VFlex
        xs12
        class="text-center ma-4"
      >
        <Icon
          class="rsvp-result-dialog__success-icon"
          :src="successIcon"
        />
      </VFlex>
      <VFlex
        xs12
        class="text-center"
      >
        <span
          class="mt-6 mb-4 text-h4 rsvp-result-dialog__tile"
        >
          Appointment Booked
        </span>
      </VFlex>
    </VLayout>
    <VLayout
      class="pa-4"
      wrap
    >
      <div class="py-1 px-2 rsvp-result-dialog__content">
        <VLayout
          wrap
        >
          <VFlex
            xs12
            md6
            class="pa-2 text-body-1"
          >
            <div class="rsvp-result-dialog__label">
              Date
            </div>
            <div class="rsvp-result-dialog__value">
              {{ formatted.date }}
            </div>
          </VFlex>
          <VFlex
            xs12
            md6
            class="pa-2 text-body-1"
          >
            <div class="rsvp-result-dialog__label">
              Name
            </div>
            <div class="rsvp-result-dialog__value">
              {{ fullName }}
            </div>
          </VFlex>
          <VFlex
            xs12
            md6
            class="pa-2 text-body-1"
          >
            <div class="rsvp-result-dialog__label">
              Time
            </div>
            <div class="rsvp-result-dialog__value">
              {{ formatted.time }}
            </div>
          </VFlex>
          <VFlex
            xs12
            md6
            class="pa-2 text-body-1"
          >
            <div class="rsvp-result-dialog__label">
              Email
            </div>
            <div class="rsvp-result-dialog__value">
              {{ email }}
            </div>
          </VFlex>
        </VLayout>
      </div>
    </VLayout>
    <VLayout
      class="pa-4"
      wrap
    >
      <VFlex
        xs12
        class="text-center"
      >
        <VBtn
          class="text-capitalize font-weight-bold"
          color="primary"
          depressed
          :ripple="false"
          @click="$emit('close-dialog'); $emit('content-change', 'form')"
        >
          Got It
        </VBtn>
      </VFlex>
    </VLayout>
  </VCard>
</template>

<style lang="scss">
  .rsvp-result-dialog {
    position: relative;
  }

  .rsvp-result-dialog__close-button {
    position: absolute;
    right: 0;
  }

  .rsvp-result-dialog__success-icon {
    color: var(--v-success-base);
    height: 70px;
    text-align: center;
    width: 70px;
  }

  .rsvp-result-dialog__tile {
    text-align: center;
    width: 100%;
  }

  .rsvp-result-dialog__content {
    background-color: #EFF1F3;
    border-radius: 4px;
    width: 100%;
  }

  .rsvp-result-dialog__label {
    display: inline-block;
    width: 50px;
  }

  .rsvp-result-dialog__value {
    display: inline-block;
    font-weight: 700;
  }

  @include breakpoint('md-and-up') {
    .rsvp-result-dialog__content {
      background-color: #EFF1F3;
      border-radius: 4px;
      margin-left: 15%;
      margin-right: 15%;
      width: 70%;
    }
  }
</style>

<script>
import { mapGetters } from 'vuex'

import { currency } from '@maxsystems/ui/vue/filters'

export default {
  name: 'PaymentsSummary',
  filters: {
    currency
  },
  computed: mapGetters('opportunity/payments', ['assumptions', 'maxPrice'])
}
</script>

<template>
  <div class="payment-calculator__summary">
    <div class="payment-calculator__tile">
      <div class="payment-calculator__tile-header">
        <h4 class="text-h6">
          Summary*
        </h4>
      </div>
      <VLayout
        class="payment-calculator__tile-body text-body-1"
        wrap
      >
        <VFlex class="xs8 my-2">
          Down Payment
        </VFlex>
        <VFlex class="xs4 my-2 text-right font-weight-bold">
          {{ assumptions.downPayment | currency }}
        </VFlex>
        <VFlex class="xs8 my-2">
          Trade-in Value
        </VFlex>
        <VFlex class="xs4 my-2 text-right font-weight-bold">
          {{ assumptions.tradeEquity | currency }}
        </VFlex>
        <VFlex class="xs8 my-2">
          Total Down Payment
        </VFlex>
        <VFlex class="xs4 my-2 text-right font-weight-bold">
          {{ assumptions.downPayment + assumptions.tradeEquity | currency }}
        </VFlex>
        <VFlex class="xs12 my-2">
          <VDivider />
        </VFlex>
        <VFlex class="xs8 my-2">
          APR
        </VFlex>
        <VFlex class="xs4 my-2 text-right font-weight-bold">
          {{ assumptions.apr }}%
        </VFlex>
        <VFlex class="xs8 my-2">
          Term Length
        </VFlex>
        <VFlex class="xs4 my-2 text-right font-weight-bold">
          {{ assumptions.termLength }} Months
        </VFlex>
        <VFlex class="xs12 my-2">
          <VDivider />
        </VFlex>
        <VFlex class="xs8 my-2 primary--text">
          Estimated Vehicle Price
        </VFlex>
        <VFlex class="xs4 my-2 text-right font-weight-bold primary--text">
          {{ maxPrice | currency }}
        </VFlex>
        <VFlex class="xs8 my-2 primary--text">
          Estimated Monthly Payment
        </VFlex>
        <VFlex class="xs4 my-2 text-right font-weight-bold primary--text">
          {{ assumptions.monthlyPayment | currency }}
        </VFlex>
      </VLayout>
    </div>
  </div>
</template>

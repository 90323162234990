<script>
import { ColorWell } from '@maxsystems/ui/elements'

import SelectListRow from '../patterns/SelectListRow'

export default {
  components: {
    SelectListRow
  },
  config: {
    title: 'Exterior Color',
    weight: 50,
    facet: 'exteriorColor',
    require: {
      multipleFacetsAvailable: 'exteriorColor.lvl2'
    }
  },
  computed: {
    hierarchicalFacet: vm => vm.$store.getters['search/hierarchicalFacet'](vm.$options.config.facet),
    values () {
      const values = Object.values(this.hierarchicalFacet.available).reduce((filters, facet) => {
        if (facet.items.length) {
          filters.push(...facet.items.map(f => {
            f.lvlName = `1.${f.name}`
            return f
          }))
        } else {
          facet.lvlName = `0.${facet.name}`
          filters.push(facet)
        }
        return filters
      }, [])

      return values.slice().sort((a, b) => b.count - a.count)
    },
    value: {
      get: vm => vm.hierarchicalFacet.selected.map(filter => vm.values.find(v => filter === v.name).lvlName),
      set (value) {
        const levels = value.reduce((result, filter) => {
          const [lvl, name] = filter.split('.')
          result[Number(lvl)].push(name)
          return result
        }, [[], []])
        levels.forEach((lvl, i) => {
          this.$store.commit('search/replaceFilter', [`${this.$options.config.facet}.lvl${i}`, lvl, { operator: this.operator }])
        })
        this.$store.dispatch('search/search')
      }
    }
  },
  methods: {
    onChange (value) {
      this.value = value
    }
  },
  render (h) {
    return h('section', this.values.map(option =>
      h(SelectListRow, {
        key: `index-${option.name.replace(/\s/g, '')}`,
        on: {
          change: this.onChange
        },
        props: {
          modelArray: this.value,
          value: option.lvlName,
          count: option.count,
          label: option.name.split(' > ').pop()
        },
        scopedSlots: {
          ...this.$vnode.data.scopedSlots,
          icon: props => h(ColorWell, { props: { color: props.label } })
        }
      })
    ))
  }
}
</script>

<script>
import {
  VApp,
  VMain
} from 'vuetify/lib'

import opportunity from './mixins/opportunity'

export default {
  mixins: [
    opportunity
  ],
  metaInfo: {
    titleTemplate (title) {
      return `${title ? title + ' | ' : ''}${this.$store.state.instance.name}`
    }
  },
  computed: {
    /**
     * Buttons like "Change Dealership" and "Start Over" should be
     * visible only when accessing https://showroom.max.auto/ directly
     * and then accessing SRP and VDP pages.
     * Localhost is also considered for development purposes.
     */
    showroomNavigation: vm => vm.$route.path !== '/' &&
      /showroom\.max\.auto|localhost/.test(document.referrer)
  },
  render (h) {
    const Main = h(VMain, [
      h('router-view', { key: this.$route.fullPath })
    ])

    if (this.$route.meta.layout === 'embed') {
      return h(VApp, [
        Main
      ])
    }

    return h(VApp, [
      h('PrimaryNav', {
        props: {
          showroomNavigation: this.showroomNavigation
        }
      }),
      Main,
      h('FooterNav', {
        props: {
          showroomNavigation: this.showroomNavigation
        }
      }),
      h('vue-progress-bar')
    ])
  }
}
</script>

<style lang="scss">
@import '@maxsystems/ui/styles/variables';
@import '@maxsystems/ui/styles/app';

.v-application.theme--light {
  background: var(--v-grey-lighten5);
}

@media print {
  @page {
    margin: 0.2in;
  }

  .v-application.theme--light {
    background: #FFF !important;
  }
}

@include breakpoint('lg-and-up') {
  .v-application--wrap {
    margin: 0 auto;
    max-width: 1400px;
  }
}
</style>

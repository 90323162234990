import {
  mapSavedVehicle
} from './mapping'
import {
  addFavoriteMutation,
  removeFavoriteMutation,
  savedVehicleFragment
} from './queries'

const state = () => ({

})

const getters = {
  /**
   * Access to the raw GQL structure for vehicles on the board. You should
   * probably be using the `favorites` getter below unless you know what to do.
   * @return {Array}
   */
  _saved: (state, getters, rootState) => rootState.opportunity.data.savedInventory?.edges?.filter(Boolean) || [],

  /**
   * All vehicles the user has added to their favorites.
   * @return {Array}
   */
  favorites: (state, getters) => getters._saved.map(x => x && mapSavedVehicle(x.node)),

  /**
   * Whether or not the vehicle has been added as a favorite by the user.
   * @param {String} inventory
   * @return {Boolean}
   */
  isFavorite: (state, getters) => inventory => getters._saved.some(v => parseInt(v.node.id) === parseInt(inventory))
}

const actions = {
  async addFavorite ({ dispatch, getters }, id) {
    if (getters.isFavorite(id)) return
    const edges = getters._saved.slice()
    edges.push({ node: { id } })
    dispatch('opportunity/update', { savedInventory: { edges } }, { root: true })

    const { data: gql } = await dispatch('opportunity/mutate', {
      mutation: addFavoriteMutation + savedVehicleFragment,
      id
    }, { root: true })
    if (!gql) return

    return dispatch('opportunity/update', { savedInventory: gql.addFavorite.opportunity.savedInventory }, { root: true })
  },
  async removeFavorite ({ dispatch, getters }, id) {
    const edges = getters._saved.filter(x => parseInt(x.node.id) !== parseInt(id))
    dispatch('opportunity/update', { savedInventory: { edges } }, { root: true })

    return dispatch('opportunity/mutate', {
      mutation: removeFavoriteMutation,
      id
    }, { root: true })
  },
  async toggleFavorite ({ dispatch, getters, rootGetters }, id) {
    // make sure we have a valid opportinity first
    await rootGetters['opportunity/pending']
    if (getters.isFavorite(id)) {
      return dispatch('removeFavorite', id)
    }

    dispatch('addFavorite', id)
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

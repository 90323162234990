<script>
import { Icon } from '@maxsystems/ui/elements'
import { number } from '@maxsystems/ui/vue/filters'

import checkIcon from '../assets/icons/check.svg'

export default {
  name: 'SelectListRow',
  filters: { number },
  components: {
    Icon
  },
  model: {
    prop: 'modelArray',
    event: 'change'
  },
  props: {
    modelArray: {
      type: Array,
      default: null
    },
    value: {
      type: [Boolean, String],
      default: '',
      required: true
    },
    count: {
      type: Number,
      default: null
    },
    icon: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    subcategory: {
      type: Boolean,
      default: false
    },
    radio: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    checkIcon
  }),
  computed: {
    isActive: vm => typeof vm.value === 'boolean' ? vm.value : vm.modelArray.includes(vm.value)
  },
  methods: {
    select () {
      if (!this.modelArray) return this.$emit('click')

      if (this.radio) {
        this.$emit('change', this.isActive ? [] : [this.value])
        return
      }
      this.$emit('change', this.isActive
        ? [...this.modelArray].filter(x => x !== this.value)
        : [...this.modelArray, this.value]
      )
    }
  }
}
</script>

<template>
  <button
    v-ripple="{ class: 'grey--text text--lighten-1' }"
    :aria-label="label || value"
    :aria-checked="isActive"
    :class="{
      'select-list-row': true,
      'select-list-row--active': isActive,
      'select-list-row--subcategory': subcategory
    }"
    role="checkbox"
    :title="label || value"
    @click.stop="select"
  >
    <slot
      name="icon"
      :label="label"
      :value="value"
    />
    <div class="select-list-row__label text-body-1">
      <span>{{ label || value }}</span>
    </div>
    <div
      v-if="count"
      class="select-list-row__count"
    >
      ({{ count | number }})
    </div>
    <Icon
      v-if="isActive"
      class="select-list-row__check"
      :src="checkIcon"
      width="18"
    />
  </button>
</template>

<style lang="scss">
.select-list-row {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: spacer(6) * -1;
  padding: spacer(2) spacer(6);
  position: relative;
  width: calc(100% + #{spacer(12)});

  &__count {
    color: var(--v-grey-base);
    margin-left: spacer(4);
  }

  &--active {
    & svg {
      color: var(--v-primary-base);
    }

    .select-list-row {
      &__label {
        color: var(--v-primary-base);
        font-weight: 700;
      }

      &__count {
        color: inherit;
      }
    }
  }

  & svg:first-of-type:not(.select-list-row__check),
  & img {
    margin-right: spacer(4);
    min-width: spacer(6);
  }

  &--subcategory {
    padding-left: spacer(12);
  }

  &__label span {
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__check {
    color: var(--v-primary-base);
    position: absolute;
    right: spacer(6);
  }

  &:hover,
  &:focus {
    background: var(--v-grey-lighten3);
    outline: none;
  }
}
</style>

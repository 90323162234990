import { init } from '@sentry/vue'
import Vue from 'vue'

init({
  denyUrls: [/localhost/, /githubpreview/],
  dsn: process.env.SENTRY,
  environment: process.env.DEPLOY_ENV || 'dev',
  logErrors: (process.env.NODE_ENV !== 'production'),
  release: process.env.SHA,
  Vue
})

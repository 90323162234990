import icon from './icon.svg'

export default {
  data: () => ({
    icon,
    title: 'Payments',
    subtitle: 'Find out how much you can afford'
  }),
  computed: {
    visible () {
      return this.$store.getters['opportunity/modules'].has('payments')
    }
  }
}

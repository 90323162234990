import component from './Content'
import nav from './Navigation'

export {
  component,
  nav
}

export default {
  component,
  nav,
  path: '/payments',
  title: 'Estimate Your Monthly Payment',
  weight: 20
}

<script>
export default {
  name: 'TermLength',
  props: {
    terms: {
      type: Array,
      default: () => ([36, 48, 60, 72])
    }
  },
  computed: {
    term: {
      get () {
        return this.$store.getters['opportunity/payments/assumptions'].termLength
      },
      set (termLength) {
        this.$store.dispatch('opportunity/payments/updateAssumptions', { termLength })
      }
    }
  }
}
</script>

<template>
  <VLayout
    class="term-length"
    wrap
  >
    <VFlex xs10>
      <h4 class="text-subtitle-1 my-4">
        Term Length
      </h4>
    </VFlex>
    <VFlex xs12>
      <VSelect
        v-model="term"
        eager
        class="termLength"
        :disabled="$store.state.opportunity.viewOnly"
        :items="terms"
        item-text="termDisplay"
        item-value="months"
        outlined
        single-line
      >
        <template
          slot="selection"
          slot-scope="data"
        >
          {{ data.item }} Months
        </template>
        <template
          slot="item"
          slot-scope="data"
        >
          <VListItemContent>
            <VListItemTitle>
              {{ data.item }} Months
            </VListItemTitle>
          </VListItemContent>
        </template>
      </VSelect>
    </VFlex>
  </VLayout>
</template>

<style lang="scss">
  .term-length {
    .v-select__selections {
      padding-top: 0 !important;
    }
  }
</style>

<script>
import { CreditApplication, OneTimePassword } from '@maxsystems/opportunity'
import fetch, { configureClient } from '@maxsystems/ui/api'

import BuyExplainer from './components/BuyExplainer'
import createApplication from './queries/createApplication.gql'

export default {
  name: 'BuyVDP',
  components: {
    BuyExplainer,
    CreditApplication,
    OneTimePassword
  },
  props: {
    inventory: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    error: null,
    loading: false,
    showExplainer: true,
    token: null
  }),
  methods: {
    async createApplication () {
      this.loading = true
      configureClient({ token: this.$store.state.opportunity.accessToken })
      const { data, errors } = await fetch(createApplication, {
        opportunity: this.$store.state.opportunity.data.id,
        inventory: this.inventory
      })

      this.token = data?.createApplication?.application?.token

      // we make sure current car is in favorites
      await this.$store.dispatch('opportunity/board/addFavorite', this.inventory)

      if (errors || !this.token) {
        this.error = errors?.[0]?.message || 'No token returned by credit application.'
      }

      this.loading = false
    }
  }
}
</script>

<template>
  <section class="vdp--buy-now">
    <BuyExplainer
      v-if="showExplainer"
      @continue="showExplainer = false"
    />
    <OneTimePassword
      v-if="!showExplainer && !token"
      :opportunity-id="$store.state.opportunity.data.id"
      @success="createApplication"
    />
    <CreditApplication
      v-if="!loading && token"
      class="vdp--buy-now__app"
      :token="token"
    />
    <VProgressCircular
      v-if="loading"
      :size="50"
      color="primary"
      indeterminate
    />
    <VAlert
      v-if="!token && error"
      type="error"
      text
    >
      Error: {{ error }}
    </VAlert>
  </section>
</template>

<style lang="scss">
section.vdp--buy-now {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  .vdp--buy-now__app {
    width: 100%;
  }
}
</style>

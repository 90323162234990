import { store as opportunity } from '@maxsystems/opportunity'

import CustomerBoard from './components/CustomerBoard'
import ReservationDialog from './components/Reservation/ReservationDialog'
import board from './store'

export default {
  install (Vue, { store } = {}) {
    Vue.component('ReservationDialog', ReservationDialog)
    // Create an Event Bus for the board
    Vue.prototype.$board = Vue.$board = new Vue()
    // The Vuex store must be provided to the install hook for automatic registration.
    if (!store) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('`Vue.use` was called without a Vuex store; need to call `.register` manually.')
      }
      return
    }
    this.register(store)
  },

  register (store) {
    // The shared Opportunity module will be registered dynamically if it
    // has not already been installed by another product.
    if (!store._modules.root._children.opportunity) {
      store.registerModule('opportunity', opportunity, { preserveState: false })
    }

    // Once the base Opportunity module exists, we augment with the functionality
    // which is specific to the Customer Board.
    if (!store.getters['opportunity/modules'].has('board')) {
      store.registerModule(['opportunity', 'board'], board, { preserveState: false })
    }
  }
}

export {
  CustomerBoard,
  board as store
}

<script>
export default {
  name: 'BuyNowButton',
  props: {
    inventory: {
      type: [Number, String],
      required: true
    }
  },
  methods: {
    onClick () {
      this.$store.dispatch('opportunity/board/addFavorite', this.inventory)
      this.$router.push({ name: 'vdp-buy' })
    }
  }
}
</script>

<template>
  <VBtn
    block
    color="secondary"
    class="darken-2"
    depressed
    @click="onClick"
  >
    Buy Now
  </VBtn>
</template>

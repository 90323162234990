import { Surface } from '@maxsystems/ui/elements'

export default {
  components: {
    Surface
  },
  inject: ['dataSource'],
  computed: {
    description: vm =>
      (vm.dataSource.inventory.description || '').replace(/={6}:/g, '').replace(/={6}([^:]+)/g, '<br><br><b>$1</b>'),
    title () {
      const { year, make, model, trim } = this.dataSource.vehicle.ymmt
      return ['Vehicle Details -', year, make, model, trim].join(' ')
    }
  },
  render (h) {
    if (!this.description) return
    return h(Surface, { staticClass: 'pa-6 text-caption' }, [
      h('h5', { staticClass: 'text-subtitle-1 font-weight-medium mb-4' }, this.title),
      h('span', { staticClass: 'grey--text text--darken-2', domProps: { innerHTML: this.description } })
    ])
  }
}

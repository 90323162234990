import { register } from '@maxsystems/ui/mixins/pluggable'

import BodyStyle from './BodyStyle'
import Budget from './Budget'
import Certification from './Certification'
import Condition from './Condition'
import Dealership from './Dealership'
import Drivetrain from './Drivetrain'
import ExteriorColor from './ExteriorColor'
import ExteriorColorWithLvl2 from './ExteriorColorWithLvl2'
import Highlights from './Highlights'
import Mileage from './Mileage'
import PopularFeatures from './PopularFeatures'
import './YMMT'

register('srp', BodyStyle)
register('srp', Budget)
register('srp', Certification)
register('srp', Condition)
register('srp', Dealership)
register('srp', Drivetrain)
register('srp', PopularFeatures)
register('srp', ExteriorColor)
register('srp', ExteriorColorWithLvl2)
register('srp', Highlights)
register('srp', Mileage)

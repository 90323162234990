<script>
import { Icon } from '@maxsystems/ui/elements'
import { currency } from '@maxsystems/ui/vue/filters'

import {
  Battery,
  Generic,
  Maintenance,
  Mechanical,
  Oil,
  Paint,
  Wheels
} from './icons'

export const iconMap = new Map([
  [Battery, ['4A9BE04']],
  [Mechanical, ['C1F65DD']],
  [Wheels, ['DFD2868', '71436E6', 'CD44332', '8D30FC3', '6D77694', '8491385', '26988EF', 'A6255D2']],
  [Maintenance, ['D3CE461']],
  [Oil, ['F43978B', 'C48264F', 'B7BD33F', '7C0FD49']],
  [Paint, ['1D0C830']]
])

const iconById = {}

iconMap.forEach((ids, icon) => {
  ids.forEach(id => {
    iconById[id] = icon
  })
})

export default {
  filters: {
    currency
  },
  components: {
    Icon
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: null
    }
  },
  computed: {
    icon: vm => iconById[vm.id] || Generic
  }
}
</script>

<template>
  <div :class="['surface--service-upgrades__upgrade px-4', {'align-center': !description}]">
    <span :class="['surface--service-upgrades__upgrade__icon white--text grey', {'mt-4': description}]">
      <Icon
        height="28"
        :src="icon"
      />
    </span>
    <div class="surface--service-upgrades__upgrade__content ml-4 pb-4 pt-4">
      <div class="body-1">
        {{ name }}
      </div>
      <div
        v-if="description"
        class="body-2 grey--text text--darken-1"
      >
        {{ description }}
      </div>
      <div
        v-if="value"
        class="body-2"
      >
        <strong>+ {{ value.amount | currency }}</strong>
        added value
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.surface--service-upgrades__upgrade {
  display: flex;

  &__icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    min-width: 40px;
  }

  &__content {
    width: 100%;
  }
}
</style>

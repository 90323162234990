export default (inventory = {}) => ({
  certified: inventory.certified?.isCertified,
  color: {
    description: inventory.vehicle?.color?.description,
    generic: inventory.vehicle?.color?.generic,
    name: inventory.vehicle?.color?.name
  },
  interiorColor: {
    description: inventory.vehicle?.interior?.color?.description,
    generic: inventory.vehicle?.interior?.color?.generic,
    name: inventory.vehicle?.interior?.color?.name
  },
  condition: inventory.condition,
  dealerName: inventory.dealer?.name,
  drivetrain: inventory.vehicle?.ymmt?.drivetrain,
  engine: inventory.vehicle?.ymmt?.engine?.type,
  inventory: inventory.id,
  marketAverage: inventory.marketAverage?.average?.amount,
  mileage: inventory.vehicle?.odometer?.value,
  make: inventory.vehicle?.ymmt?.make,
  model: inventory.vehicle?.ymmt?.model,
  msrp: inventory.price?.msrp?.amount,
  photo: inventory.photos?.edges?.[0]?.node?.url,
  popularEquipment: inventory.vehicle?.equipment?.map(({ name }) => name) || [],
  price: inventory.price?.internetPrice?.amount,
  stock: inventory.stockNumber,
  summary: inventory.description,
  transmission: inventory.vehicle?.ymmt?.transmission,
  trim: inventory.vehicle?.ymmt?.trim,
  upgrade: inventory.vehicle?.options?.msrp?.amount,
  vin: inventory.vehicle?.vin,
  year: inventory.vehicle?.ymmt?.year,
  highlights: inventory.highlights
})

import cookieManager from './cookieManager'

/**
 * Checks if the current environment is a production environment.
 * It considers 'localhost' and '.vercel.app' as non-production environments.
 *
 * @returns {boolean} true if the environment is production, false otherwise.
 */
export function isProduction () {
  const host = window.location.hostname

  return !(host === 'localhost' || host.includes('.vercel.app'))
}

/**
 * Gets the host name of the current dealership or the dealership selection screen
 *
 * @returns {string} The host name
 */
export function getHost () {
  let host = window.location.hostname

  // Drop any `stage.` prefix from the host to resolve the Instance correctly.
  host = host.replace('stage.', '')

  if (isProduction()) return host

  // We rely on the domain do define the instance.
  // In non-production environments the app lives on a single domain so it points to a single instance.
  // During testing you can change the instance dynamically without re-deploying by setting the
  // `com.max.path.instance` cookie in your browser locally.
  host = cookieManager.get('com.max.path.instance') || 'windy.max.auto'

  return host
}

/**
 * Navigate to the dealership hostname or to the dealership selection screen.
 * In production, it sets the window location to the hostname.
 * In development, it sets a cookie with the hostname and redirects to home.
 *
 * @param {string} dealershipHostname - The hostname of the dealership
 */
export function setHost (dealershipHostname) {
  if (isProduction()) {
    window.location = `https://${dealershipHostname}`
    return
  }

  cookieManager.set('com.max.path.instance', dealershipHostname)
  window.location.pathname = '/'
}

<script>
import Payment from './Payment'
import Price from './Price'

export default {
  name: 'SRPBudget',
  config: {
    title () {
      const hasPaymentPlugin = this.$store.getters['opportunity/modules']?.has('payments')
      return hasPaymentPlugin ? 'Price & Payment' : 'Price'
    },
    weight: -1,
    facet: 'price'
  },
  components: {
    ShopByPayment: Payment,
    ShopByPrice: Price
  },
  provide () {
    return {
      meta: this.meta
    }
  },
  data: () => ({
    allTabs: [Price, Payment]
  }),
  computed: {
    activeTab: vm => (vm.tabs.find(tab => tab.computed.active.call(vm)) || vm.tabs[0]).name,
    meta: {
      get: vm => vm.$store.getters['search/meta']('budget'),
      set (value) {
        this.$store.commit('search/setMeta', {
          plugin: 'budget',
          value
        })
      }
    },
    tabs: vm => vm.allTabs.filter(tab => tab.visible?.call(vm) ?? true)
  },
  render (h) {
    return h('section', [
      this.tabs.length > 1 && h('div', { class: 'pt-4 pb-6' },
        this.tabs.map(tab => h('button', {
          on: {
            click: tab.onSelected.bind(this)
          },
          class: {
            'srp__filter--budget__tab': true,
            'srp__filter--budget__tab--active': tab.name === this.activeTab
          }
        }, tab.title))),
      h(this.activeTab, { staticClass: 'mt-3' })
    ])
  }
}
</script>

<style lang="scss">
  .srp__filter--budget {
    &__tab {
      color: var(--v-grey-lighten1);
      font-size: 14px;
      font-weight: 500;

      &:first-of-type {
        margin-right: 40px;
      }

      &--active {
        color: var(--v-dark-darken1) !important;
        text-decoration: underline;
      }

      &:hover,
      &:focus {
        color: var(--v-grey-base);
        outline: none;
      }
    }

    .v-text-field__slot .v-text-field__prefix {
      margin-top: 4px;
    }
  }
</style>
